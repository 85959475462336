import { FormControl, TextField, FormHelperText, MenuItem, Select, InputLabel } from '@mui/material';
import DynamicTabs from '../../shared/tabs/Tabs';
import PostProductionForm from './PostProductionForm';
import { useCallback } from 'react';
import { NUMBER_10, _days, _editData } from './modal';
import { TABS_NUM } from '../../utils/utils';

interface postProductionProps {
    formikProps: any;
    expanded?: any;
}

function PostProduction(props: postProductionProps) {

    const { formikProps, expanded } = props;
    const { handleChange, post_production, setFieldValue, values, errors, touched } = formikProps;

    const handleTotalEditChange = (e: any) => {
        const edit_counts: number = Number(e?.target?.value);
        let data: any = values?.post_production?.edit;
        const remaining_days = edit_counts - data?.length;
        if (remaining_days > 0) {
            // TABS_NUM(remaining_days || 0)
            //     .forEach(() => data.push(_days))
            TABS_NUM(remaining_days || 0)
                .forEach(() => data.push(_editData))

            setFieldValue('post_production.edit', data);
        };
        if (edit_counts < data?.length && data.length > 0) {
            const updated_day = data.slice(0, edit_counts);
            setFieldValue(`post_production.edit`, updated_day);
        }
        setFieldValue('post_production.no_of_edits', Number(edit_counts))
    }

    return (
        <>
            <div className='row m-0 mb-2 mt-3'>
                <div className='col-6 p-0'>
                    <FormControl variant="outlined" size='small' fullWidth>
                        <InputLabel id="no_of_edits">Total Edits?</InputLabel>
                        <Select
                            labelId="no_of_edits"
                            id="no_of_edits"
                            name={`post_production.no_of_edits`}
                            value={values.post_production.no_of_edits}
                            onChange={handleTotalEditChange}
                            label="Total Edits?"
                        >
                            {
                                NUMBER_10.map((u: number) => <MenuItem value={u} key={u + 'key'}>{u}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText>
                            {(errors?.post_production?.no_of_edits &&
                                touched?.post_production?.no_of_edits) && errors?.post_production?.no_of_edits
                            }
                        </FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className='row m-0 d-tabs'>
                {(values.post_production.no_of_edits && values.post_production.no_of_edits > 0) ?
                    <DynamicTabs 
                        tabNumber={values.post_production.no_of_edits}
                        formikProps={props.formikProps} 
                        label={'edit'}
                        expanded={expanded}
                        components={PostProductionForm}
                        formName={'post_production'}
                    />
                    : null
                }
            </div>
        </>
    );
}

export default PostProduction;