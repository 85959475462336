
import { FormControl, Autocomplete, TextField,  } from "@mui/material";
import GeoLocation from "../../shared/location/GeoLocation";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { _talent_pacakge_data } from "./modal";

interface TalentDetailProps {
    dayIndex: number;
    talentIndex: number;
    formikProps: any;
}

function TalentDetail (props: TalentDetailProps) {
    const { dayIndex, talentIndex, formikProps } = props;
    const { values, handleChange, setFieldValue, errors, touched } = formikProps;

    const talent_value = values.talents?.days[dayIndex]?.packages;
    const error = errors?.talents?.days[dayIndex]?.packages[talentIndex];
    const touch = touched?.talents?.days[dayIndex]?.packages[talentIndex];


    const [selectedArrival, setSelectedArrival] = useState()

    useEffect(() => {
        let data = talent_value;
        if (!!data && !data[talentIndex]) {
            data[talentIndex] = _talent_pacakge_data;
            setFieldValue(`talents.days[${dayIndex}].packages`, data);
        }
        console.log("Talent Value: ", talent_value);
    }, [dayIndex, talentIndex]);

    const dayTimeSlots = () => {
        const timeSlots = [];
        const startTime = moment().startOf('day');
        const endTime = moment().endOf('day');
  

        while (startTime.isBefore(endTime)) {
            timeSlots.push(startTime.format('hh:mm A'));
            startTime.add(15, 'minutes');
        }

        return timeSlots;
    }

    const timeSlots = useMemo(dayTimeSlots, []);

    const departureTimeSlots = () => {
        const deptimeSlots = [];
        const startTime = moment(selectedArrival,'hh:mm A').add(15, 'minutes')
        const endTime = moment().endOf('day').add(10,'hours')
        while (startTime.isBefore(endTime)) {
            deptimeSlots.push(startTime.format('hh:mm A'));
            startTime.add(15, 'minutes');
        }
        
        return deptimeSlots;
    }   

    const deptimeSlots = useMemo(()=>departureTimeSlots(), [selectedArrival]);

    const handleLocationchange = (val:string) => {
        setFieldValue(`talents.days[${dayIndex}].packages[${talentIndex}].location`, val);
    }

    return (
        <>
            {!!talent_value && (
                <div className="row my-4 shadow-sm">
                    <div className="col-12 py-2 px-3" style={{backgroundColor: "#E0FDFF"}}>
                        Talent {talentIndex+1}
                    </div>
                    <div className="row pt-3">
                        <div className="col-3">
                            <FormControl variant="outlined" size="small" fullWidth>
                                <Autocomplete
                                    id={`talent-arrival-time-autocomplete-${dayIndex}`}
                                    onOpen={() => {
                                    setTimeout(() => {
                                        const optionEl = document.querySelector(
                                        `[data-name="10:00 AM"]`
                                        );
                                        optionEl?.scrollIntoView();
                                    }, 1);
                                    }}
                                    options={timeSlots}
                                    getOptionLabel={(option) => option}
                                    value={talent_value[talentIndex]?.talent_arrival_time}
                                    onChange={(_, newValue) => {
                                    setSelectedArrival(newValue);
                                    handleChange({
                                        target: {
                                            name: `talents.days[${dayIndex}].packages[${talentIndex}].talent_arrival_time`,
                                            value: newValue,
                                        },
                                    });
                                    }}
                                    renderOption={(props, option) => {
                                    return (
                                        <li {...props} data-name={option}>
                                            {option}
                                        </li>
                                    );
                                    }}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={`Talent Arrival Time`}
                                        margin="dense"
                                        size="small"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        helperText={
                                        (error?.talent_arrival_time &&
                                            touch?.talent_arrival_time) &&
                                            error?.talent_arrival_time
                                        }
                                    />
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className="col-3">
                            <FormControl variant="outlined" size="small" fullWidth>
                                <Autocomplete
                                    id={`talent-departure-time-autocomplete-${dayIndex}`}
                                    options={deptimeSlots}
                                    getOptionLabel={(option) => option}
                                    value={talent_value[talentIndex]?.talent_departure_time}
                                    onChange={(_, newValue) => {
                                    handleChange({
                                        target: {
                                        name: `talents.days[${dayIndex}].packages[${talentIndex}].talent_departure_time`,
                                        value: newValue,
                                        },
                                    });
                                    }}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={`Talent Departure Time`}
                                        margin="dense"
                                        size="small"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        helperText={
                                        (error?.talent_departure_time ||
                                            touch?.talent_departure_time) &&
                                            error?.talent_departure_time
                                        }
                                    />
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className="col-sm-6">
                            <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                                <GeoLocation
                                    name={`talents.days[${dayIndex}].packages[${talentIndex}].location`}
                                    value={talent_value[talentIndex]?.location}
                                    formikProps={formikProps}
                                    onChange={handleLocationchange}
                                />
                                {/* {errors?.talents && (
                                    <FormHelperText>
                                    {((errors?.talents?.days[tabNumber]?.crew_package &&
                                        errors?.production_details?.day[tabNumber]?.crew_package[
                                        crewIndex
                                        ]?.location) ||
                                        (touched?.production_details?.day[tabNumber]
                                        ?.crew_package &&
                                        touched?.production_details?.day[tabNumber]?.crew_package[
                                            crewIndex
                                        ]?.location)) &&
                                        errors?.production_details?.day[tabNumber]?.crew_package[
                                        crewIndex
                                        ]?.location}
                                    </FormHelperText>
                                )} */}
                            </FormControl>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default TalentDetail;