import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from "@mui/material";
import { _editData, CREW_SIZE } from "../../modal";
import _ from "lodash";
import { useEffect, useState } from "react";
import ModeEditIcon  from "@mui/icons-material/ModeEdit";
import CheckIcon from "@mui/icons-material/Check";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import moment from "moment";
import { useSearchParams } from "react-router-dom";

interface IVideoTimeEstimator {
    index: number;
    expanded?: any;
    formikProps: any;
}

interface ITimeOverviewItem {
    className?: string;
    delimiter?: string;
    title: string;
    value: any;
    canEdit?: boolean;
    canReset?: boolean;
    onSave?: (value:any) => void;
    onReset?: () => void;
}

interface ICrewSizeHoursState {
    crew_size: string;
    hours_on_site: number;
    selected: boolean;
}

function TimeOverviewItem (props: ITimeOverviewItem) {
    const { canEdit = true, canReset = false, className, delimiter = "Hours", title, value, onSave, onReset } = props;

    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setInputValue(value)
    }, [value]);

    const submitEdit = () => {
        if (onSave)
            onSave(inputValue)

        setShowInput(false)
    }

    const handleChange = (evt:any) => {
        setInputValue(evt.target.value)
    }

    const handleEdit = () => {
        setShowInput(true)
    }

    const handleReset = () => {
        console.log("Reset")
        if (onReset)
            onReset()
    }

    return (
        <div className={className ?? "col-3 fs-13"}>
            {!showInput && (
                <>
                    <span className="">{title}:</span>
                    <span className="fw-bold ms-2">{inputValue} {delimiter}</span>
                    {canEdit && (
                        <button 
                            type="button"
                            className="btn btn-sm btn-link fs-12"
                            onClick={handleEdit}
                        >
                            <ModeEditIcon sx={{fontSize: "13px"}} />
                        </button>
                    )}

                    {canReset && (
                        <Tooltip 
                            title={`Reset to be auto-factored by the "Hours on Site" or "The length of Final Video" form field value`}
                            sx={{fontSize: "16px"}}
                        >
                            <button 
                                type="button"
                                className="btn btn-sm btn-link"
                                onClick={handleReset}
                            >
                                <RestartAltIcon sx={{fontSize: "15px"}} />
                            </button>

                        </Tooltip>
                    )}
                </>
            )}

            {showInput && (
                <div className="d-flex">
                    <FormControl variant="outlined" sx={{  }}>
                        <TextField
                            // name={`post_production.edit[${index}].title`}
                            type={'text'}
                            variant="outlined"
                            label={title}
                            focused
                            onChange={handleChange}
                            value={inputValue}
                            margin="dense"
                            size="small"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{delimiter}</InputAdornment>
                            }}
                        />
                    </FormControl>

                    <button 
                        type="button"
                        className="btn btn-sm btn-link"
                        onClick={submitEdit}
                    >
                        <CheckIcon sx={{fontSize: 15}} />
                    </button>
                </div>
            )}
        </div>
    )
}

export default function VideoTimeEstimator(props: IVideoTimeEstimator) {
    const { index, expanded, formikProps } = props;
    const { values, setFieldValue, handleChange, errors, touched } = formikProps;

    const [searchParams, setSearchParams] = useSearchParams()

    const production = values.production_details;
    const timeEstimator = values.post_production?.edit[index]?.video?.time_estimator;
    const timeEstimateSubtotal = timeEstimator?.subtotal;
    const crewSizeValue = timeEstimator?.crew_size;

    const [totalEstimateHours, setTotalEstimateHour] = useState<any>(0);
    const [baseBuffer, setBaseBuffer] = useState(0);
    const [filmingDays, setFilmingDays] = useState<any>([]);
    const [crewSizeHours, setCrewSizeHours] = useState<ICrewSizeHoursState[][]>([]);
    const [selectedCrewSizeHours, setSelectedCrewSizeHours] = useState<any>([]);

    const [estimates, setEstimates] = useState<any>({
        organizing_footage: 0,
        trimming_footage: 0,
        arranging_clips: 0,
        color_grading: 0,
        refining: 0,
        add_music: 0,
        add_text: 0,
    });

    const crewSizeConstantHours:any = {
        organizing_footage: {
            solo: 0.25,
            small: 0.25,
            medium: 0.5,
            large: 0.25,
        },
        trimming_footage: {
            solo: 1.2,
            small: 1.2,
            medium: 1.7,
            large: 1,
        },
        arranging_clips: {
            solo: 1.2,
            small: 1.2,
            medium: 1.2,
            large: 0.7,
        }
    }

    useEffect(() => {
        getProductionPackages();

        if (_.isNil (timeEstimator?.is_b_roll)) {
            loadEstimateOverview()
        } else {
            calcBaseBuffer();
        }

        setFilmingDays(timeEstimator?.filming_days ?? []);
        setSelectedCrewSizeHours(timeEstimator?.crew_size_hours ?? []);
    }, []);

    useEffect(() => {
        loadEstimateOverview();
    }, [
        estimates.organizing_footage,
        estimates.trimming_footage,
        estimates.arranging_clips,
        estimates.color_grading,
        estimates.refining,
        estimates.add_music,
        estimates.add_text,
        timeEstimator?.revision?.percent,
        timeEstimator?.revision?.is_add,
        crewSizeValue
    ]);

    useEffect(() => {
        console.log("Selected Crew Size Hours State: ", selectedCrewSizeHours);
        loadEstimateOverview();
        setFieldValue(`post_production.edit[${index}].video.time_estimator.crew_size_hours`, selectedCrewSizeHours)
    }, [selectedCrewSizeHours]);

    useEffect(() => {
        if (expanded === "panel2") {
            getProductionPackages();
        }
    }, [expanded])

    const getProductionPackages = () => {
        const fDays = production?.day;
        const sType = searchParams.get("type");
        console.log(`Production Day: `, fDays.length, fDays);
        let csHours:any = [];
        if (sType === "all" || sType === "null" || !sType) {
            for(let i=0; i<fDays.length; i++) {
                csHours[i] = [];
                const crewPkgs = fDays[i]?.crew_package;
                console.log(`Crew Package (${i})`, crewPkgs);
                for(let x = 0; x < crewPkgs.length; x++) {
                    const cp = crewPkgs[x];
                    let defaultHoursOnSite = 0;
    
                    const uid = `${i}_${x}`;
                    const scs = _.find(selectedCrewSizeHours, item => item.uid === uid)
                    const isChecked = !_.isNil (scs);
                    console.log(`Is Checked Crew Size Hour (${uid}): `, isChecked)
    
                    console.log(`Day ${i} Crew Hours (${x}): `, cp)
                    if (!_.isNil(cp?.arrival_time) && !_.isNil(cp?.arrival_time)) {
                        const arrivalTime = moment(cp?.arrival_time, "hh:mm A");
                        const departureTime = moment(cp?.departure_time, "hh:mm A");
                        defaultHoursOnSite = departureTime.diff(arrivalTime, "hours", true);
                    }
    
                    csHours[i][x] = {
                        crew_size: cp?.size,
                        hours_on_site: !_.isNil(scs)? scs.hours_on_site : defaultHoursOnSite,
                        selected: isChecked ?? false
                    }
    
                }
            }
        } else if (sType === "post_production") {
            csHours = _.map(CREW_SIZE, (cs: any, cs_index) => {
                const uid = `${cs_index}_`;
                const scs = _.find(selectedCrewSizeHours, item => item.uid === uid)
                const isChecked = !_.isNil (scs);

                return {
                    crew_size: cs?.value,
                    hours_on_site: !_.isNil(scs)? scs.hours_on_site : 0,
                    selected: isChecked ?? false
                }
            });
        }
        console.log(`Crew Package Hours: `, csHours)

        setCrewSizeHours(csHours);
    }

    const handleBRollChange = (evt:any, unselect=false) => {
        const val = evt.target.value

        if (!unselect) {
            setFieldValue(`post_production.edit[${index}].video.time_estimator.is_b_roll`, parseInt(val));
            calcBaseBuffer(undefined, val)
        } else {
            setFieldValue(`post_production.edit[${index}].video.time_estimator.is_b_roll`, null);
            loadEstimateOverview(null, null, true)
        }

    }

    const updateCrewSize = (evt:any) => {
        const crew_size = evt.target.value;
        setFieldValue(`post_production.edit[${index}].video.time_estimator.crew_size`, crew_size);
    }

    const handleIncludeMusicTextChange = (evt:any, field:string) => {
        const checked = evt.target.checked;
        // console.log(`Request Data (${field}): `, checked)
        setFieldValue(`post_production.edit[${index}].video.time_estimator.include_${field}`, checked)
        if (field == "music") {
            setEstimates({
                ...estimates,
                add_music: checked? 0.5 : 0
            });

        } else {
            const textHour = timeEstimator?.final_video_length * 0.4;
            setEstimates({
                ...estimates,
                add_text: checked? textHour.toFixed(1) : 0
            });
        }

        sumEstimateOverview();
    }

    const handleFinalVideoLengthChange = (evt:any) => {
        const val = evt.target.value;
        setFieldValue(`post_production.edit[${index}].video.time_estimator.final_video_length`, val);
        if (!_.isNil(timeEstimator?.is_b_roll)) {
            calcBaseBuffer(val);
        } else {
            loadEstimateOverview(null, val)
        }
    }

    const calcBaseBuffer = (val?:string, is_b_roll?:number) => {
        const isBRoll = is_b_roll ?? timeEstimator?.is_b_roll;
        const lenInMinute = parseFloat(val ?? timeEstimator?.final_video_length) / 60;
        let totalEst = 0;
        if (isBRoll == 1) {
            let additaional_hour = lenInMinute > 1? 5 : 0;
            totalEst = 20+additaional_hour;
            setBaseBuffer(totalEst);
        } else if (isBRoll == 0) {
            let additaional_hour = lenInMinute > 1? 1 : 0;
            totalEst = 5+additaional_hour;
            setBaseBuffer(totalEst);
        }

        setTotalEstimateHour(totalEst);
    }

    const calculateEstimate = _.debounce ((val:any, overview_name:string) => {
        const crewSize = timeEstimator?.crew_size;
        const is_b_roll = timeEstimator?.is_b_roll;
        // const finalVideoLength = timeEstimator?.final_video_length;
        if (_.isNil(timeEstimateSubtotal)) {
            setFieldValue(`post_production.edit[${index}].video.time_estimator.subtotal`, _editData.video.time_estimator.subtotal);
        }

        setFieldValue(`post_production.edit[${index}].video.time_estimator.subtotal.${overview_name}`, val)
        
        if (_.isNil(is_b_roll)) {
            if (["organizing_footage", "trimming_footage", "arranging_clips"].includes(overview_name)) {
                const crewSizeHour = crewSizeConstantHours[overview_name][crewSize];
                const hoursOnSite = parseFloat(val) / crewSizeHour;


                let organizingFootageHour = calcByHoursOnSite({overview_name: "organizing_footage"});
                let trimmingFootageHour = calcByHoursOnSite({overview_name: "trimming_footage"});
                let arrangingClipsHour = calcByHoursOnSite({overview_name: "arranging_clips"});

                console.log("Crew Size Hour: ", crewSizeHour);
                console.log("Hours on Size: ", hoursOnSite);

                // setFieldValue(`post_production.edit[${index}].video.time_estimator.hours_on_site`, hoursOnSite.toFixed(1));

                switch(overview_name) {
                    case "organizing_footage":
                            setEstimates({
                                ...estimates, 
                                organizing_footage: val,
                                // trimming_footage: trimmingFootageHour.toFixed(1),
                                // arranging_clips: arrangingClipsHour.toFixed(1),
                            });
                        break;

                    case "trimming_footage":
                            setEstimates({
                                ...estimates, 
                                trimming_footage: val,
                                // organizing_footage: organizingFootageHour.toFixed(1),
                                // arranging_clips: arrangingClipsHour.toFixed(1),
                            })
                        break;

                    case "arranging_clips":
                            setEstimates({
                                ...estimates, 
                                arranging_clips: val,
                                // trimming_footage: trimmingFootageHour.toFixed(1),
                                // organizing_footage: organizingFootageHour.toFixed(1),
                            })
                        break;
                }

            } else if (["color_grading", "refining"].includes(overview_name)) {
                const finalVideoLength = parseFloat(val) / 0.5;
                console.log(`Estimate Step (${overview_name}): `, val);
                switch(overview_name) {
                    case "color_grading":
                            setEstimates({
                                ...estimates,
                                color_grading: val,
                                // refining: parseFloat(val)
                            });
                        break;

                    case "refining":
                            setEstimates({
                                ...estimates,
                                // color_grading: parseFloat(val),
                                refining: val
                            });
                        break;
                }
                // setFieldValue(`post_production.edit[${index}].video.time_estimator.final_video_length`, finalVideoLength.toFixed(1));
            }
        } else {
            
        }

        // sumEstimateOverview();
    }, 500);

    const loadEstimateOverview = (hours_on_site?:any, final_video_length?:any, reload=false) => {
        const crewSize = timeEstimator?.crew_size;
        const is_b_roll = timeEstimator?.is_b_roll;
        const hoursOnSite = parseFloat (hours_on_site ?? timeEstimator?.hours_on_site);
        const finalVideoLength = parseFloat(final_video_length ?? timeEstimator?.final_video_length);

        if (_.isNil(is_b_roll) || reload === true) {
            let organizing_footage = getTimeEstimateSubtotal("organizing_footage") ?? calcCrewSizeHour("organizing_footage");
            let trimming_footage = getTimeEstimateSubtotal("trimming_footage") ?? calcCrewSizeHour("trimming_footage");
            let arranging_clips = getTimeEstimateSubtotal("arranging_clips") ?? calcCrewSizeHour("arranging_clips");
            let color_grading = getTimeEstimateSubtotal("color_grading") ?? finalVideoLength * 0.5;
            let refining = getTimeEstimateSubtotal("refining") ?? finalVideoLength * 0.5;;
            let add_music = getTimeEstimateSubtotal("include_music") ?? timeEstimator?.include_music? 0.5 : 0;

            let add_text = 0;
            if (timeEstimator?.include_text) {
                if(!!finalVideoLength) {
                    add_text = 0.4 * finalVideoLength;
                } else {
                    add_text = 0.4;
                }
                add_text = getTimeEstimateSubtotal("include_text") ?? add_text;
            }

            console.log("Time Estimate Subtotals: ", timeEstimateSubtotal?.trimming_footage);

            const estimateInit = {
                organizing_footage: !_.isNaN (organizing_footage)? _.round (organizing_footage, 1) : 0,
                trimming_footage: !_.isNaN (trimming_footage)? _.round(trimming_footage, 1) : 0,
                arranging_clips: !_.isNaN (arranging_clips)? _.round (arranging_clips, 1) : 0,
                color_grading: !_.isNaN (color_grading)? _.round (color_grading, 1) : 0,
                refining: !_.isNaN (refining)? _.round (refining, 1) : 0,
                add_music: add_music,
                add_text: !_.isNaN (add_text)? _.round (add_text, 1) : 0
            }
            setEstimates(estimateInit)
            let totalEst = _.sum(Object.values(estimateInit))
            console.log("Estimate Init: ", estimateInit)
            console.log("Total Estimate: ", totalEst)
            if (timeEstimator?.revision?.is_add) {
                let ps = totalEst * (_.round (timeEstimator?.revision?.percent, 2) / 100);
                console.log(`Time Estimate Revision Hour: `, ps)
                totalEst += ps;
            }
            setTotalEstimateHour(!_.isNaN(totalEst) ? _.round (totalEst, 1) : 0)
        } else {

        }
    }

    const sumEstimateOverview = () => {
        const estimateValues:any = Object.values(estimates)
        let totalEst = 0;
        for(let i=0; i<estimateValues.length; i++) {
            let hr = _.round (estimateValues[i], 1);
            totalEst+=hr;
        }
        // console.log("Total Estimate: ", totalEst);
        setTotalEstimateHour(totalEst.toFixed(1));
    }

    const getTimeEstimateSubtotal = (overview_name:string) => {
        if  (!_.isNil (timeEstimateSubtotal) && !_.isNil (timeEstimateSubtotal[overview_name])) {
            return timeEstimateSubtotal[overview_name];
        }
        return null;
    }

    const calcByHoursOnSite = ({overview_name, hours_on_site}: {overview_name:string, hours_on_site?:any}) => {
        const crewSize = timeEstimator?.crew_size
        const hoursOnSite = _.round (hours_on_site ?? timeEstimator?.hours_on_site, 1);
        let baseHour = 0;

        if (crewSize instanceof Object) {
            const baseHourObj = _.filter (crewSizeConstantHours[overview_name], (value, key) => _.includes(crewSize, key));
            baseHour = _.round (_.sum(Object.values(baseHourObj)), 1)
        } else {
            baseHour = crewSizeConstantHours[overview_name][crewSize];
        }
        
        return hoursOnSite * baseHour;
    };

    const calcCrewSizeHour = (overview_name:string) => {
        if (selectedCrewSizeHours.length > 0) {
            const crewSizes = _.map(selectedCrewSizeHours, "crew_size");
            const crewSizeBaseTotalHours = _.sum(_.map(crewSizes, (val:any) => crewSizeConstantHours[overview_name][val]));
    
            const totalHoursOnSite = _.sum(_.map(selectedCrewSizeHours, "hours_on_site"));
    
            const totalHours = _.round(crewSizeBaseTotalHours * totalHoursOnSite, 1);
            // console.log(`Crew Sizes (${overview_name}): `, crewSizes);
            console.log(`Crew Size Base Total Hours (${overview_name}): `, crewSizeBaseTotalHours);
            console.log(`Total Hours On Site (${overview_name}): `, totalHoursOnSite);
            console.log(`Over all Total Hours (${overview_name}): `, totalHours);
            return totalHours;
        }
        return 0;
    }

    const handleCrewSizeHourChange = (evt:any, day_index:number, pkg_index:number | undefined, crew_hour:ICrewSizeHoursState) => {
        const checked = evt.target.checked;
        console.log("Cres Size Hours: ", day_index, pkg_index, crew_hour);
        const uid = `${day_index}_${pkg_index ?? ""}`;

        if (checked) {
            setSelectedCrewSizeHours([...selectedCrewSizeHours, {
                uid,
                ...crew_hour,
                selected: true

            }])
        } else {
            let scshClone = _.cloneDeep(selectedCrewSizeHours);
            _.remove(scshClone, (item:any) => item.uid === uid);
            console.log("Removed Selected Crew Size Hours: ", scshClone);
            setSelectedCrewSizeHours(scshClone);
        }

        setCrewSizeHourValue(day_index, pkg_index, {
            key: "selected",
            value: checked
        })
    }

    const handleHoursOnSiteChange = (evt:any, day_index:number, pkg_index:number | undefined) => {
        const value = evt.target.value;
        const hoursOnSite = parseFloat(value)
        const uid = `${day_index}_${pkg_index ?? ""}`
        console.log("Hours on site value changed: ", value);

        const updatedState = _.cloneDeep(selectedCrewSizeHours);
        updatedState.map((item:any) => {
            if (item.uid === uid) {
                item.hours_on_site = !_.isNaN (hoursOnSite) ? hoursOnSite : 0;
            }
            return item;
        })

        console.log("Updated Selected Crew Size Hours: ", updatedState);
        
        
        setCrewSizeHourValue(day_index, pkg_index, {
            key: "hours_on_site",
            value: !!value? parseFloat(value) : ""
        });

        setSelectedCrewSizeHours(updatedState)
    }

    const setCrewSizeHourValue = (day_index:number, pkg_index:number | undefined, {key, value}: {key:string, value:any}) => {
        setCrewSizeHours(prevData => 
            prevData.map((day:any, i:any) => {
                if (i === day_index) {
                    if (!_.isNil(pkg_index)) {
                        day.map((pkg:any, x:any) => {
                            if (x === pkg_index) {
                                pkg[key] = value;
                            }
                            return pkg;
                        });
                    } else {
                        day[key] = value;
                    }
                }
                return day;
            })
        );
    }

    const handleManualOverviewReset = (overview_name:string) => {
        setFieldValue(`post_production.edit[${index}].video.time_estimator.subtotal.${overview_name}`, null)
    }

    const handleRevisionChange = (evt:any) => {
        setFieldValue(`post_production.edit[${index}].video.time_estimator.revision.is_add`, evt.target.checked);
        setFieldValue(`post_production.edit[${index}].video.time_estimator.revision.percent`, 20); 
    }

    const handleRevisionPercentSave = (val:any) => {
        setFieldValue(`post_production.edit[${index}].video.time_estimator.revision.percent`, val);
    }

    return (
        <div className="row my-4 shadow-sm">
            <div className="col-12 py-2 px-3" style={{backgroundColor: "#E0FDFF"}}>
                Video Editing Time Estimate
            </div>
            <div className="row pt-3">
                <div className='col-sm-12'>
                    <div className="d-flex align-items-center">
                        <div>
                            <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                                <RadioGroup
                                    row
                                    name={`post_production.edit[${index}].video.time_estimator.is_b_roll`}
                                    onChange={(evt) => handleBRollChange(evt)}
                                    value={values.post_production.edit[index]?.video?.time_estimator?.is_b_roll}
                                >
                                    <FormControlLabel value={0} control={<Radio />} label="Long Form Edit WITHOUT B-Roll or Visuals" />
                                    <FormControlLabel value={1} control={<Radio />} label="Long Form Edit WITH B-roll or Visuals" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        {!_.isNil(timeEstimator?.is_b_roll) && (
                            <div>
                                <button 
                                    type="button"
                                    className="btn btn-sm btn-light fs-13"
                                    onClick={(evt) => handleBRollChange(evt, true)}
                                >
                                    Unselect
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {_.isNil(timeEstimator?.is_b_roll) && (
                    <>

                        <div className="col-12 mb-2 p-3 border">
                            <h5 className="fs-14 mb-4">Select the Filming Package(s) that Corrolate to the Edit</h5>

                            {(searchParams.get("type") === "all" || searchParams.get("type") === "null" || !searchParams.get('type')) && crewSizeHours.map((pkgs:any, day_index:any) => {
                                // const crewPackages = day?.crew_package;
                                // console.log(`Filming Day ${day_index} Crew Packages: `, pkgs)

                                return (
                                    <div key={`pkg_day_${day_index}`}>
                                        <h6>Filming Day {day_index+1}</h6>

                                        <div className="col-12">
                                            {pkgs.map((pkg:ICrewSizeHoursState, p_index:any) => {
                                                // console.log(`Filming Day ${day_index} Crew Package ${p_index}: `, pkg)
                                                // pkg.hours_on_site = !_.isNil (pkg.hours_on_site)? parseFloat(pkg.hours_on_site.toString()) : 0;

                                                return (
                                                    <div className="row align-items-center" key={`pkg_${p_index}`}>
                                                        <div className="col-4">
                                                            <FormControlLabel
                                                                // key={`day_${item.value}`}
                                                                // className="mt-4"
                                                                control={
                                                                    <Checkbox
                                                                        // id="new_client"
                                                                        onChange={(evt:any) => handleCrewSizeHourChange(evt, day_index, p_index, pkg)}
                                                                        checked={pkg?.selected ?? false}
                                                                        size="small"
                                                                    />
                                                                }
                                                                label={`${_.capitalize(pkg.crew_size)} Crew (Crew Package ${p_index+1})`}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                                                                <TextField
                                                                    // name={`post_production.edit[${index}].video.time_estimator.hours_on_site`}
                                                                    variant="outlined"
                                                                    label={`Hours on Site`}
                                                                    onChange={(evt) => handleHoursOnSiteChange(evt, day_index, p_index)}
                                                                    value={pkg.hours_on_site}
                                                                    disabled={!(pkg?.selected ?? false)}
                                                                    margin="dense"
                                                                    size="small"
                                                                    helperText={(errors?.post_production?.edit[index]?.video?.estimator?.hours_on_site &&
                                                                        touched?.post_production?.edit[index]?.video?.estimator?.hours_on_site) &&
                                                                        errors?.post_production?.edit[index]?.video?.estimator?.hours_on_site
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}

                            {searchParams.get("type") === "post_production" && (
                                <div className="col-12">
                                    {crewSizeHours.map((pkg:any, p_index:number) => {
                                        return (
                                            <div className="row align-items-center" key={`pkg_${p_index}`}>
                                                <div className="col-4">
                                                    <FormControlLabel
                                                        // key={`day_${item.value}`}
                                                        // className="mt-4"
                                                        control={
                                                            <Checkbox
                                                                // id="new_client"
                                                                onChange={(evt:any) => handleCrewSizeHourChange(evt, p_index, undefined, pkg)}
                                                                checked={pkg?.selected ?? false}
                                                                size="small"
                                                            />
                                                        }
                                                        label={`${_.capitalize(pkg.crew_size)} Crew`}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                                                        <TextField
                                                            // name={`post_production.edit[${index}].video.time_estimator.hours_on_site`}
                                                            variant="outlined"
                                                            label={`Hours on Site`}
                                                            onChange={(evt) => handleHoursOnSiteChange(evt, p_index, undefined)}
                                                            value={pkg.hours_on_site}
                                                            disabled={!(pkg?.selected ?? false)}
                                                            margin="dense"
                                                            size="small"
                                                            helperText={(errors?.post_production?.edit[index]?.video?.estimator?.hours_on_site &&
                                                                touched?.post_production?.edit[index]?.video?.estimator?.hours_on_site) &&
                                                                errors?.post_production?.edit[index]?.video?.estimator?.hours_on_site
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="row">

                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-12">
                            <FormControlLabel
                                // className="mt-4"
                                control={
                                    <Checkbox
                                        // id="new_client"
                                        // name='post_production.edit[index].is_raw_footage_delivery'
                                        onChange={(evt:any) => handleIncludeMusicTextChange(evt, "music")}
                                        checked={values.post_production.edit[index]?.video?.time_estimator?.include_music}
                                        size="small"
                                    />
                                }
                                label="Include Music"
                            />
                        </div>
                        <div className="col-12 pb-1">
                            <FormControlLabel
                                // className="mt-4"
                                control={
                                    <Checkbox
                                        // id="new_client"
                                        // name='post_production.edit[index].is_raw_footage_delivery'
                                        onChange={(evt:any) => handleIncludeMusicTextChange(evt, "text")}
                                        checked={values.post_production.edit[index]?.video?.time_estimator?.include_text}
                                        size="small"
                                    />
                                }
                                label="Include Text"
                            />
                        </div>
                    </>
                )}

                <div className="col-6">
                    <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                        <TextField
                            name={`post_production.edit[${index}].video.time_estimator.final_video_length`}
                            variant="outlined"
                            label={`The Length of Final Video (In Minutes)`}
                            onChange={handleFinalVideoLengthChange}
                            value={values.post_production.edit[index]?.video?.time_estimator?.final_video_length}
                            margin="dense"
                            size="small"
                            helperText={(errors?.post_production?.edit[index]?.video?.time_estimator?.final_video_length &&
                                touched?.post_production?.edit[index]?.video?.time_estimator?.final_video_length) &&
                                errors?.post_production?.edit[index]?.video?.time_estimator?.final_video_length
                            }
                        />
                    </FormControl>
                </div>
            </div>

            <div className="col-12 mt-4 border border-radius">
                <div className="row align-items-center gy-4 gx-3 px-2 py-3">
                    {_.isNil (timeEstimator?.is_b_roll) && (
                        <>
                            <TimeOverviewItem 
                                title="Organizing Footage"
                                value={estimates.organizing_footage}
                                canReset={!_.isNil(timeEstimateSubtotal?.organizing_footage)}
                                onSave={(val) => calculateEstimate(val, "organizing_footage")}
                                onReset={() => handleManualOverviewReset("organizing_footage")}
                            />
                            <TimeOverviewItem 
                                title="Trimming Footage"
                                value={estimates.trimming_footage}
                                canReset={!_.isNil(timeEstimateSubtotal?.trimming_footage)}
                                onSave={(val) => calculateEstimate(val, "trimming_footage")}
                                onReset={() => handleManualOverviewReset("trimming_footage")}
                            />
                            <TimeOverviewItem 
                                title="Arranging Clips into Storyline"
                                value={estimates.arranging_clips}
                                canReset={!_.isNil(timeEstimateSubtotal?.arranging_clips)}
                                onSave={(val) => calculateEstimate(val, "arranging_clips")}
                                onReset={() => handleManualOverviewReset("arranging_clips")}
                            />
                            <TimeOverviewItem 
                                title="Color Grading"
                                value={estimates.color_grading}
                                canReset={!_.isNil(timeEstimateSubtotal?.color_grading)}
                                onSave={(val) => calculateEstimate(val, "color_grading")}
                                onReset={() => handleManualOverviewReset("color_grading")}
                            />
                            <TimeOverviewItem 
                                title="Refining"
                                value={estimates.refining}
                                canReset={!_.isNil(timeEstimateSubtotal?.refining)}
                                onSave={(val) => calculateEstimate(val, "refining")}
                                onReset={() => handleManualOverviewReset("refining")}
                            />
                            <TimeOverviewItem 
                                title="Adding Music to Edit"
                                value={estimates.add_music}
                                canEdit={false}
                            />
                            <TimeOverviewItem 
                                title="Adding Text to Edit"
                                value={estimates.add_text}
                                canEdit={false}
                            />
                        </>
                    )}

                    {!_.isNil(timeEstimator?.is_b_roll) && (
                        <TimeOverviewItem 
                            className = "col-12 fs-13"
                            title="Gathering Asset, Transferring and Delivering Files, Ensuring Requirements are Met"
                            value={baseBuffer}
                            canEdit={false}
                            // onSave={(val) => calculateEstimate(val, "base_buffer")}
                        />
                    )}
                </div>
                <div className="row justify-content-between align-items-center px-2 py-3 border-top">
                    <div className="col-4 fs-14">
                        <span className="fw-bold">Total Estimated Hours:</span>
                        <span className="ms-2">{totalEstimateHours} Hours</span>
                    </div>
                    <div className="col-5">
                        <div className="row justify-content-between align-items-center">

                            <div className="col-6">
                                {timeEstimator?.revision?.is_add && (
                                    <TimeOverviewItem 
                                        title="Revision Percentage"
                                        value={timeEstimator?.revision?.percent}
                                        delimiter={"%"}
                                        onSave={handleRevisionPercentSave}
                                        className="col-12"
                                    />
                                )}
                            </div>
                            <div className="col-6">
                                <FormControlLabel
                                    // key={`day_${item.value}`}
                                    // className="mt-4"
                                    control={
                                        <Checkbox
                                            // id="new_client"
                                            onChange={handleRevisionChange}
                                            checked={timeEstimator?.revision?.is_add ?? false}
                                            size="small"
                                        />
                                    }
                                    label={`Include 20% time for revisions.`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}