import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import _ from "lodash";
import { NUMBER_10 } from "../../modal";

interface IKeyShot {
    index: number;
    formikProps: any;
}

export default function KeyShot(props: IKeyShot) {
    const { index, formikProps } = props;
    const { values, handleChange, errors, touched } = formikProps;

    const keyShots = values.post_production?.edit[index]?.video?.key_shots;

    const [keyShotItems, setKeyShotItems] = useState<any>([""]);

    useEffect(() => {
        console.log("Key Shots: ", keyShots?.items);
        setKeyShotItems(keyShots?.items ?? [""])
    }, [keyShots]);

    const upsertFormElem = (evt:any, index:number, remove = false) => {
        const totalKeyShots = keyShots?.items.length;
        
        if (remove === false) {
            if (index == totalKeyShots-1) {
                setKeyShotItems([...keyShotItems, ""])
            } else {
                const ksItem = keyShotItems;
                ksItem.splice(index+1, 0, "")
                setKeyShotItems([...ksItem])
            }
        } else {
            const ksItem = keyShotItems;
            ksItem.splice(index, 1);
            setKeyShotItems([...ksItem]);
            console.log("Key shots (KS Item): ", ksItem);
            console.log("Key shots: ", keyShotItems);
        }

    }

    const handleTotalKeyShotChange = (evt:any) => {}

    return (
        <div className="row my-4 shadow-sm">
            <div className="col-12 py-2 px-3" style={{backgroundColor: "#E0FDFF"}}>
                Key Shots to Display on Screen
            </div>
            <div className="col-12 pt-3">
                <div className="col-12">
                    <div className='row m-0 mb-2 mt-3'>
                        {/* <div className='col-6 p-0'>
                            <FormControl variant="outlined" size='small' fullWidth>
                                <InputLabel id="no_of_edits">Total Edits?</InputLabel>
                                <Select
                                    labelId="no_of_edits"
                                    id="no_of_edits"
                                    name={`post_production.no_of_edits`}
                                    value={values.post_production.no_of_edits}
                                    onChange={handleTotalKeyShotChange}
                                    label="Total Edits?"
                                >
                                    {
                                        NUMBER_10.map((u: number) => <MenuItem value={u} key={u + 'key'}>{u}</MenuItem>)
                                    }
                                </Select>
                                <FormHelperText>
                                    {(errors?.post_production?.no_of_edits &&
                                        touched?.post_production?.no_of_edits) && errors?.post_production?.no_of_edits
                                    }
                                </FormHelperText>
                            </FormControl>
                        </div> */}
                    </div>
                </div>
                {keyShotItems?.map((_item:any, idx:number) => {
                    return (
                        <div className="d-flex gx-3 align-items-center py-2" key={`key_shot_${idx}`}>
                            <div className='col-8'>
                                <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                                    <TextField
                                        name={`post_production.edit[${index}].video.key_shots.items[${idx}]`}
                                        variant="outlined"
                                        label={`Key Shot ${idx+1}`}
                                        onChange={handleChange}
                                        value={values.post_production.edit[index]?.video?.key_shots?.items[idx] ?? ""}
                                        margin="dense"
                                        size="small"
                                        helperText={(errors?.post_production?.edit[index]?.video?.key_shots?.items[idx] &&
                                            touched?.post_production?.edit[index]?.video?.key_shots?.items[idx]) &&
                                            errors?.post_production?.edit[index]?.video?.key_shots?.items[idx]
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className="col-2 ml-7">
                                <div className="d-flex align-items-center">
                                    <button 
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={(evt:any) => upsertFormElem(evt, idx)}
                                    >
                                        +
                                    </button>

                                    {keyShotItems.length > 1 && (
                                        <button 
                                            type="button"
                                            className="btn btn-danger btn-sm ml-7"
                                            onClick={(evt:any) => upsertFormElem(evt, idx, true)}
                                        >
                                            -
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}