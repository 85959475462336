import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TABS_NUM } from '../../utils/utils';

interface tabsProps {
  tabNumber: number;
  formikProps?: any;
  components: any;
  expanded?: any;
  label: string;
  formName: string
  key? : string
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function DynamicTabs(props: tabsProps) {
  
  const { tabNumber, formikProps, components, expanded, label, formName, key } = props;
  const _Component: any = components;
  const [value, setValue] = React.useState<any>(0);

  const tabsNum: any[] = TABS_NUM(Number(tabNumber) || 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [tabNumber])

  let errors_ = formikProps?.errors[formName] || null;
  errors_ = errors_ ? errors_[key ? key : label] : null;
  
  return (  
    <>
      <Box sx={{ bgcolor: 'background.paper' }}>
        <Tabs
          value={tabNumber === value ? 0 : value}
          className='custom_tabs'
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          centered={false}
        >
          {tabsNum.map((tabs: number, index: number) => {
            return <Tab key={label + tabs} label={label + ' ' + (index + 1)}
              className={errors_ && errors_[index] ? 'errors_tab' : ''}
            />
          })}
        </Tabs>
      </Box>
      {tabsNum.map((tabs: number) => {
        return <TabPanel key={label + tabs} value={value} index={tabs}>
          <_Component formikProps={formikProps} index={tabs} expanded={expanded} />
        </TabPanel>
      })}

    </>
  );
}