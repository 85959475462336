import { authReducer, INITIAL_STATE as AUTH_INITIAL_STATE } from "./auth/reducers";
import { AuthState } from "./auth/state"
import { configReducer, INITIAL_STATE as CONFIG_INITIAL_STATE } from "./config/reducers"
import { FORM_INITIAL_STATE, formReducer } from "./form/reducers";
import { FormState } from "./form/state";

export interface Action {
    type: string,
    data?: any
}

export interface AppState {
    auth: AuthState,
    config: any,
    form: any,
}

export const APP_INITIAL_STATE: AppState = {
    auth: AUTH_INITIAL_STATE,
    config: CONFIG_INITIAL_STATE,
    form: FORM_INITIAL_STATE
}

export const appReducer = (state: AppState, action: Action) => ({
    auth: authReducer(state.auth, action),
    config: configReducer(state.config, action),
    form: formReducer(state.form, action)
})