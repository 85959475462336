import React, { useEffect, useCallback, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText, FormLabel, FormControlLabel, RadioGroup, Radio, Checkbox, InputAdornment, ToggleButtonGroup, ToggleButton } from '@mui/material';
import DateInput from '../../shared/date/DateInput';
import { _editData } from './modal';
import moment from 'moment';
import _, { isNil } from 'lodash';
import { useAppStateContext } from '../../state/provider';
import { useSearchParams } from 'react-router-dom';
import PostProductionKeyShot from './components/post_production/KeyShot';
import BrandingForm from './components/post_production/BrandingForm';
import VideoTimeEstimator from './components/post_production/VideoTimeEstimator';

function PostProductionForm(props: any) {
    const { index, formikProps, expanded } = props;
    const { handleChange, post_production, setFieldValue, values, errors, touched } = formikProps;
    const no_of_edits = values.post_production.no_of_edits;
    const [searchParams, setSearchParams] = useSearchParams();
    const [applyDetails, setApplyDetails] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [projectName, setProjectName] = useState("");
    const [type, setType] = useState("");

    const { state } = useAppStateContext();

    const project_name = values?.client_details.project_name;

    useEffect(() => {
        let data: any = values.post_production.edit;
        if (!data[index]) {
            data[index] = _editData;
            setFieldValue('post_production.edit', data);
        }
        console.log("Request Data (Post Production Edit Data): ", data)
        setFieldValue(`post_production.edit[${index}].aspect_ratio`, "4K")

        const deadline_of_initial_delivery = values?.post_production?.edit[index]?.deadline_of_initial_delivery;
        console.log(`Deadline of initial delivery ${index}: `, values?.production_details?.day.length > 0 && isNil(deadline_of_initial_delivery));

        if (values?.production_details?.day.length > 0 && isNil(deadline_of_initial_delivery)) {
            let deadline = getDeadline(index)
            
            if (!!deadline) {
                const defaultDeadline = moment (deadline).add(5, "days").format("YYYY-MM-DD")
                setFieldValue(`post_production.edit[${index}].deadline_of_initial_delivery`, defaultDeadline)
            }
        }

        const title = values?.post_production?.edit[index]?.title;
        const description = values?.post_production?.edit[index]?.description;
        const previousProjectName = state.form?.previousProjectName ?? "";

        // console.log("Previous Project Name From Post Production Form: ", previousProjectName)
        

        if (!!title)
            setTitle(title);
        
        if (!!description) {
            setDescription(description.replace(previousProjectName, "").replace(project_name, "").replace(` ${title}`, "").trim());
        }

    }, [index]);

    useEffect(() => {
        setProjectName(project_name)
    }, [project_name]);

    useEffect(() => {
        console.log("Form Title: ", title);
        console.log("Form Title: ", description);
        const is_raw_footage_delivery = values.post_production?.edit[index].is_raw_footage_delivery;
        const type = values.post_production?.edit[index].type;
        let desc = description;
        if (is_raw_footage_delivery == true) {
            desc = "";
        } else if(_.isNil(desc) && !_.isNil(type)) {
            desc = getDescription(type)
            // setDescription(desc);
        }
        console.log("Description Updated...: ", desc)

        handleChange({
            target: {
                name: `post_production.edit[${index}].description`,
                value: desc.concat(` ${project_name}`).concat(` ${title}`)
            }
        });
    }, [description, title, project_name]);

    const onlyNumbers = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;
        const keyCode = e.keyCode || e.which;
        // Allow numeric characters (0-9) and the backspace key
        if (!/^[0-9.]+$/.test(key) && keyCode !== 8) {
            e.preventDefault();
        }
    }, []);

    const getActiveType = useCallback((value: string) => {
        if (value) {
            if (value === 'video') return 'Video'
            else if (value === 'photo') return 'Photo'
        }

        return 'Video'
    }, [])

    const handleEditTypeChange = (event: any) => {
        const value = event.target.value;
        setType(value);
        const description = getDescription(value);
        setDescription(description);

        if (value != 'photo') {
            setFieldValue(`post_production.edit[${index}].no_of_photos_edit`, 0)
            setFieldValue(`post_production.edit[${index}].final_run_time_of_intro`, 0)
        }
        if (value != 'video') {
            setFieldValue(`post_production.edit[${index}].aspect_ratio`, "4K")
        }
        handleChange(event);
    }

    const handleTitleChange = (evt:any) => {
        console.log("Title: ", evt.target.value);
        let title = evt.target.value;
        setTitle(title);
        handleChange(evt)
    }

    const getDeadline: any = (form_index: any) => {
        let deadline = values.production_details?.day[form_index]?.filming_date
        let prevIndex;
        if (index > 0 && !deadline) {
            prevIndex = form_index-1
            deadline = values.production_details?.day[prevIndex]?.filming_date

            if (!deadline) return getDeadline(prevIndex)
        }

        return deadline
    }

    const handleApplyDetails = () => {
        if (applyDetails) {
            setNextEditValues(false);
            setApplyDetails(false);
        } else {
            setNextEditValues(true);
            setApplyDetails(true);
        }
    }

    const handleRawFootageDiliveryChange = (evt:any) => {
        const checked = evt.target.checked;
        const type = values.post_production.edit[index].type;
        const defaultTitle = "Raw Footage Delivery";

        setFieldValue(`post_production.edit[${index}].is_raw_footage_delivery`, checked)
        if (checked) {
            setDescription("");
            setFieldValue(`post_production.edit[${index}].title`, defaultTitle);
            setFieldValue(`post_production.edit[${index}].hours_needed_for_initial_delivery`, 2);
            setTitle(defaultTitle);
        } else {
            if (!_.isNil(type)) {
                setDescription(getDescription(type));
            }
            setFieldValue(`post_production.edit[${index}].title`, "");
            setFieldValue(`post_production.edit[${index}].hours_needed_for_initial_delivery`, 0);
            setTitle("");
        }
    }

    const getDescription = (type:string) => {
        const description = "This is the time for our editing team to complete the :type edit for";
        return description.replace(":type", type);
    }

    const setNextEditValues = (set=true) => {
        const next_index = index+1;
        let deadline = null;

        if (values.production_details.day.length > 0) {
            deadline = getDeadline(next_index)
        }

        if (set) {
            setFieldValue(`post_production.edit[${next_index}]`, values.post_production.edit[index]);

            if (!!deadline) {
                const defaultDeadline = moment (deadline).add(5, "days").format("YYYY-MM-DD")
                setFieldValue(`post_production.edit[${next_index}].deadline_of_initial_delivery`, defaultDeadline)
            }
        } else {
            setFieldValue(`post_production.edit[${next_index}].type`, '');
            setFieldValue(`post_production.edit[${next_index}].title`, '');
            setFieldValue(`post_production.edit[${next_index}].description`, '');
            setFieldValue(`post_production.edit[${next_index}].aspect_ratio`, '4K');
            setFieldValue(`post_production.edit[${next_index}].final_run_time_of_intro`, '');
            setFieldValue(`post_production.edit[${next_index}].hours_needed_for_initial_delivery`, '');

            if (!!deadline) {
                const defaultDeadline = moment (deadline).add(5, "days").format("YYYY-MM-DD")
                setFieldValue(`post_production.edit[${next_index}].deadline_of_initial_delivery`, defaultDeadline)
            }
        }
    }

    const isMainInvoice = (): boolean => {
        const type = searchParams.get("type");
        return type === "all" || type == null;
    }

    const handleIncludeLogoVoiceoverChange = (evt:any, field:string) => {
        const val = evt.target.value
        setFieldValue(`post_production.edit[${index}].video.include_${field}`, parseInt (val))
    }

    return (
        <>
            {values.post_production.edit[index] &&
                <div className='row'>
                    <div className='col-sm-12'>
                        <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                            <RadioGroup
                                row
                                name={`post_production.edit[${index}].type`}
                                onChange={handleEditTypeChange}
                                value={values.post_production.edit[index].type}
                            >
                                <FormControlLabel value="photo" control={<Radio />} label="Photo" />
                                <FormControlLabel value="video" control={<Radio />} label="Video" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    
                    {isMainInvoice() && (
                        <div className="col-6 pb-4">
                            <FormControlLabel
                                // className="mt-4"
                                control={
                                    <Checkbox
                                        id="new_client"
                                        // name='post_production.edit[index].is_raw_footage_delivery'
                                        onChange={handleRawFootageDiliveryChange}
                                        checked={values.post_production.edit[index].is_raw_footage_delivery}
                                        size="small"
                                    />
                                }
                                label="Raw Footage Delivery Only"
                            />
                        </div>
                    )}

                    <div className='col-sm-12'>
                        <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                            <TextField
                                name={`post_production.edit[${index}].title`}
                                type={'text'}
                                variant="outlined"
                                label="Edit Title"
                                // onChange={handleChange}
                                onChange={handleTitleChange}
                                value={values.post_production.edit[index].title}
                                margin="dense"
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{values.client_details.project_name}</InputAdornment>
                                }}
                                helperText={(errors?.post_production?.edit[index]?.title &&
                                    touched?.post_production?.edit[index]?.title) &&
                                    errors?.post_production?.edit[index]?.title
                                }
                            />
                        </FormControl>
                    </div>

                    {values.post_production?.edit[index].type === "photo" && (
                        <>
                            <div className='col-sm-12'>
                                <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                                    <TextField
                                        name={`post_production.edit[${index}].description`}
                                        type={'text'}
                                        variant="outlined"
                                        label={`Description of ${getActiveType(values.post_production.edit[index].type)} Edit`}
                                        onChange={handleChange}
                                        value={values.post_production.edit[index].description}
                                        margin="dense"
                                        size="small"
                                        multiline
                                        rows={2}
                                        helperText={(errors?.post_production?.edit[index]?.description &&
                                            touched?.post_production?.edit[index]?.description) &&
                                            errors?.post_production?.edit[index]?.description
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className="row align-items-center">
                                <div className='col-4'>
                                    <FormControl variant="outlined" size='small' margin="dense" fullWidth>
                                        {
                                            values.post_production.edit[index].type === 'video' ?
                                                (
                                                    <div className='container'>
                                                        <FormLabel id="aspect">Aspect Ratio</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            name={`post_production.edit[${index}].aspect_ratio`}
                                                            // defaultValue={'4K'}
                                                            onChange={handleChange}
                                                            value={values.post_production.edit[index].aspect_ratio}
                                                        >
                                                            <FormControlLabel value="4K" control={<Radio />} label="4K" />
                                                            <FormControlLabel value="1080p" control={<Radio />} label="1080p" />
                                                            <FormControlLabel value="Vertical" control={<Radio />} label="Vertical" />
                                                        </RadioGroup>

                                                        <FormHelperText>
                                                            {(errors?.post_production?.edit[index]?.aspect_ratio &&
                                                                touched?.post_production?.edit[index]?.aspect_ratio) && errors?.post_production?.edit[index]?.aspect_ratio
                                                            }
                                                        </FormHelperText>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <TextField
                                                            name={`post_production.edit[${index}].no_of_photos_edit`}
                                                            variant="outlined"
                                                            label={`Number of photo to be edited`}
                                                            onChange={handleChange}
                                                            onKeyDown={onlyNumbers}
                                                            value={values.post_production.edit[index].no_of_photos_edit}
                                                            // margin="dense"
                                                            size="small"
                                                            helperText={(errors?.post_production?.edit[index]?.no_of_photos_edit &&
                                                                touched?.post_production?.edit[index]?.no_of_photos_edit) &&
                                                                errors?.post_production?.edit[index]?.no_of_photos_edit
                                                            }
                                                        />
                                                    </>
                                                )
                                        }
                                    </FormControl>
                                </div>

                                {values.post_production.edit[index].type === 'video' && (
                                    <div className='col-4'>
                                        <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                                            <TextField
                                                name={`post_production.edit[${index}].final_run_time_of_intro`}
                                                variant="outlined"
                                                label={`Estimated final run time of ${getActiveType(values.post_production.edit[index].type)} Edit ( in minute )`}
                                                onChange={handleChange}
                                                onKeyDown={onlyNumbers}
                                                value={values.post_production.edit[index].final_run_time_of_intro}
                                                margin="dense"
                                                size="small"
                                                helperText={(errors?.post_production?.edit[index]?.final_run_time_of_intro &&
                                                    touched?.post_production?.edit[index]?.final_run_time_of_intro) &&
                                                    errors?.post_production?.edit[index]?.final_run_time_of_intro
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                )}

                                <div className='col-4'>
                                    <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                                        <TextField
                                            name={`post_production.edit[${index}].hours_needed_for_initial_delivery`}
                                            variant="outlined"
                                            label={`Estimated hours needed for initial delivery of ${getActiveType(values.post_production.edit[index].type)} Edit`}
                                            onChange={handleChange}
                                            onKeyDown={onlyNumbers}
                                            value={values.post_production.edit[index].hours_needed_for_initial_delivery}
                                            margin="dense"
                                            size="small"
                                            // InputLabelProps={{shrink: true,}}
                                            helperText={(errors?.post_production?.edit[index]?.hours_needed_for_initial_delivery &&
                                                touched?.post_production?.edit[index]?.hours_needed_for_initial_delivery) &&
                                                errors?.post_production?.edit[index]?.hours_needed_for_initial_delivery
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className='col-6'>
                                <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>

                                    <DateInput 
                                        name={`post_production.edit[${index}].deadline_of_initial_delivery`}
                                        value={values.post_production.edit[index].deadline_of_initial_delivery}
                                        label={`Deadline of initial delivery of ${getActiveType(values.post_production.edit[index].type)} Edit`}
                                        handleChange={(date: string) => {
                                            // setFieldValue(`post_production.edit[${index}].deadline_of_initial_delivery`, date)
                                            handleChange({
                                                target: {
                                                    name: `post_production.edit[${index}].deadline_of_initial_delivery`,
                                                    value: date
                                                }
                                            })
                                        }}
                                        helperText={(errors?.post_production?.edit[index]?.deadline_of_initial_delivery &&
                                            touched?.post_production?.edit[index]?.deadline_of_initial_delivery) &&
                                            errors?.post_production?.edit[index]?.deadline_of_initial_delivery
                                        } 
                                    />

                                    {/* <TextField
                                        name={`post_production.edit[${index}].deadline_of_initial_delivery`}
                                        type={'date'}
                                        variant="outlined"
                                        label={`Deadline of initial delivery of ${getActiveType(values.post_production.edit[index].type)} Edit`}
                                        onChange={handleChange}
                                        // defaultValue={values.production_details?.day[index]?.filming_date}
                                        value={values.post_production.edit[index].deadline_of_initial_delivery}
                                        margin="dense"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={(errors?.post_production?.edit[index]?.deadline_of_initial_delivery &&
                                            touched?.post_production?.edit[index]?.deadline_of_initial_delivery) &&
                                            errors?.post_production?.edit[index]?.deadline_of_initial_delivery
                                        }
                                    /> */}
                                </FormControl>
                            </div>
                        </>
                    )}


                    {/* Video Post Production Form */}
                    {values.post_production?.edit[index].type === "video" && (
                        <>
                            <div className='col-sm-12'>
                                <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                                    <TextField
                                        name={`post_production.edit[${index}].video.overall_message`}
                                        type={'text'}
                                        variant="outlined"
                                        label={`Overall Message of the Edit`}
                                        onChange={handleChange}
                                        value={values.post_production?.edit[index]?.video?.overall_message}
                                        margin="dense"
                                        size="small"
                                        multiline
                                        rows={2}
                                        helperText={(errors?.post_production?.edit[index]?.video?.overall_message &&
                                            touched?.post_production?.edit[index]?.video?.overall_message) &&
                                            errors?.post_production?.edit[index]?.video?.overall_message
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className='col-sm-12'>
                                <PostProductionKeyShot 
                                    index={index}
                                    formikProps={formikProps}
                                />
                            </div>
                            <div className='col-sm-12'>
                                <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                                    <TextField
                                        name={`post_production.edit[${index}].video.info_about_text`}
                                        type={'text'}
                                        variant="outlined"
                                        label={`Information About any Text in Video`}
                                        onChange={handleChange}
                                        value={values.post_production.edit[index]?.video?.info_about_text}
                                        margin="dense"
                                        size="small"
                                        multiline
                                        rows={2}
                                        helperText={(errors?.post_production?.edit[index]?.video?.info_about_text &&
                                            touched?.post_production?.edit[index]?.video?.info_about_text) &&
                                            errors?.post_production?.edit[index]?.video?.info_about_text
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className="col-sm-12">
                                <BrandingForm 
                                    index={index}
                                    formikProps={formikProps}
                                />
                            </div>
                            <div className='col-sm-12'>
                                <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                                    <TextField
                                        name={`post_production.edit[${index}].video.music_details`}
                                        type={'text'}
                                        variant="outlined"
                                        label={`Details About Music in Edit`}
                                        onChange={handleChange}
                                        value={values.post_production.edit[index]?.video?.music_details}
                                        margin="dense"
                                        size="small"
                                        multiline
                                        rows={2}
                                        helperText={(errors?.post_production?.edit[index]?.video?.music_detail &&
                                            touched?.post_production?.edit[index]?.video?.music_detail) &&
                                            errors?.post_production?.edit[index]?.video?.music_detail
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className="col-sm-12 my-4">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div className="col">
                                        Will Logos be needed within the Video?
                                    </div>
                                    <div className="">
                                        <ToggleButtonGroup
                                            size="small"
                                            color="primary"
                                            value={values?.post_production?.edit[index]?.video?.include_logo}
                                            exclusive
                                            onChange={(evt:any) => handleIncludeLogoVoiceoverChange(evt, "logo")}
                                            >
                                            <ToggleButton
                                                name={`post_production.edit[${index}].video.include_logo`}
                                                value={1}
                                                id="other"
                                            >
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton
                                                name={`post_production.edit[${index}].video.include_logo`}
                                                id="other"
                                                value={0}
                                            >
                                                No
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="col">
                                        Will a Vioceover be needed within the video?
                                    </div>
                                    <div className="">
                                        <ToggleButtonGroup
                                            size="small"
                                            color="primary"
                                            value={values?.post_production?.edit[index]?.video?.include_voiceover}
                                            exclusive
                                            onChange={(evt:any) => handleIncludeLogoVoiceoverChange(evt, "voiceover")}
                                            >
                                            <ToggleButton
                                                name={`post_production.edit[${index}].video.include_voiceover`}
                                                value={1}
                                                id="voiceover"
                                            >
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton
                                                name={`post_production.edit[${index}].video.include_voiceover`}
                                                id="voiceover"
                                                value={0}
                                            >
                                                No
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-sm-12">
                                <VideoTimeEstimator 
                                    index={index}
                                    formikProps={formikProps}
                                    expanded={expanded}
                                />
                            </div>
                        </>
                    )}

                    {index+1 < no_of_edits && (<div className="col-6">
                        <FormControlLabel
                            // className="mt-4"
                            control={
                                <Checkbox
                                id="new_client"
                                // name='client_details.new_client'
                                onChange={handleApplyDetails}
                                checked={applyDetails}
                                size="small"
                                />
                            }
                            label="Apply edit details to the next edit"
                            />
                    </div>)}
                </div>
            }
        </>
    );
}

export default PostProductionForm;