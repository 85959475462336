import Cookies from 'js-cookie';
import { encrypt, decrypt, compare } from 'n-krypta';
import momentTz from "moment-timezone";
import moment from 'moment';
import { CrewDetailsText } from "../pages/Clients/data";
import _ from 'lodash';
const mstTimezone = "America/Phoenix"; // MST for Arizona
const defaultCode = '$9a$10$CwTycUXWxnje0Thq9StjUM0p';
export const encryptString = (data: string) => {
    return encrypt(data, defaultCode);
}
export const decryptString = (data: string) => {
    return decrypt(data, defaultCode);
}
export const getToken = (): string => {
    return Cookies.get('accessToken') || '';
}

export const getUserInfo = (): any => {
    const data = localStorage.getItem('userInfo') || '';
    if(data) {
        return JSON.parse(data);
    }
}

export const USER_ACCESS: any = {
    admin: ['all'],
    project_manager: ['Home', "Templates", 'Clients', 'Payments', 'Projects'],
    '2': ['all']
}

export const USER_ACCESS_AUTH: any = {
    admin: ['all'],
    project_manager: ['/home', "/templates", '/clients', '/payments', '/projects', '/invoice'],
    '2': ['all']
}

export const TABS_NUM = (num: number = 0) => {
    return Array.from({length: num}, (_, i) => i);
}
export const EMAIL_HELPER_TEXT = 'Use a user email address or comma-separated list of email addresses if adding multiple users';

export const BOOLEAN_ENUM: any = {
    true: 'Yes',
    false: 'No'
}
const booleanFormat = (data: any) => {
    return BOOLEAN_ENUM[data] || false;
}


export const getTimeDifference = (updated_at: any): number => {

    const clientUpdatedAt = formatUpdatedAt(updated_at)

    const currentMST = getFormatCurrentTime()

    let currentDate = clientUpdatedAt.clone();

    let daysWithoutWeekends = 0;
    let daysWithWeekends = 0;
    while (currentDate.isBefore(currentMST) && !currentDate.isSame(currentMST, 'day')) {
        if (currentDate.isoWeekday() !== 6 && currentDate.isoWeekday() !== 7) {
            // Check if the current day is not Saturday (6) or Sunday (7)
            daysWithoutWeekends++;
        } else {
            daysWithWeekends++;
        }
        currentDate.add(1, 'days'); // Move to the next day
    }

    const netDiffInSeconds = currentMST.diff(clientUpdatedAt, "seconds");

    return (netDiffInSeconds - (daysWithoutWeekends * 60 * 60 * 16) - (daysWithWeekends * 60 * 60 * 24)) 
}

export const setupEndTime = (arrival_time="00:00:00", setup_time: any=15) => {
    return moment(arrival_time, "HH:mm:ss").add(setup_time, 'm').format("hh:mm A");
}

export const shootingEndTime = (departure_time="00:00:00", setup_time: any=15) => {
    let st = parseInt(setup_time);
    let half_setup_time : any = 15;
    if (setup_time > 15) {
        half_setup_time = st / 2;
    }
    return moment(departure_time, "HH:mm:ss").subtract(half_setup_time, 'm').format("hh:mm A");
}

export const makeTime = (time=null) => {
    return moment(time,"HH:mm:ss").format("hh:mm A")
}

export const getCrewSizeBasedOnCrewMember = (invoiceItem: any, crew_member="Photographer") => {
    const crew_sizes = ['solo', 'small', 'medium', 'large'];
    const crew_size = invoiceItem.crew_size;
    const crews = [];

    for(let i=1; i<=4; i++) {
        let cm = invoiceItem[`crew_member_${i}`];
        if (cm != null)
            crews.push(cm.toLowerCase())
    }

    if (crews.includes(crew_member.toLowerCase())) {
        let crew_size_index = crew_sizes.findIndex(elem => elem == crew_size)
        if (crew_size != "solo") {
            return crew_sizes[crew_size_index-1];
        }
    }

    return crew_size;
}

export const getCrewSizes = (_invoiceDetails: any) => {
    const production = _invoiceDetails?.invoice?.production;
    const filming_days: any = production?.no_of_filming_days;
    const crew_sizes: any = [];

    for (let i=1;i<=filming_days;i++) {
      const day = production[`day_${i}`];
      const line_items = day?.line_items;

      Object.entries(day?.line_items).forEach(([value, index]) => {
        const li = line_items[value];
        const crew_size = getCrewSizeBasedOnCrewMember(li);
        if (!crew_sizes.includes(crew_size)) {
          crew_sizes.push(crew_size)
        }
      })
    }

    return crew_sizes;
}

export const getCrewSizeDescription = (crew_size: String, show_desc:boolean=true) => {
    const size = crew_size.toLowerCase();
    let detailText;
    switch(size) {
        case "solo":
            detailText = CrewDetailsText.SOLO_VIDEOGRAPHER;
            break;

        case "small":
            detailText = CrewDetailsText.SMALL_CREW;
            break;

        case "medium":
            detailText = CrewDetailsText.MEDIUM_CREW;
            break;

        case "large":
            detailText = CrewDetailsText.LARGE_CREW;
            break;

        case "large":
            detailText = CrewDetailsText.LARGE_CREW;
            break;
    }

    if (!show_desc) {
        return detailText?.replace("This package also includes optional audio recording, teleprompter, and lighting services.", "");
    }
    return detailText;
}

export const getCrewMembers = (crewMembersArr:Array<string>) => {
    // Create an object to store the counts of each value
    const counts:any = {};

    // Count the occurrences of each value in the array
    crewMembersArr.forEach(value => {
        counts[value] = (counts[value] || 0) + 1;
    });

    // Create the new array with the combined values
    const result = Object.keys(counts).map(key => {
        if (_.lowerCase(key) != "project manager" && counts[key] > 1) {
            return `${counts[key]} ${key}s`
        }

        return key;
    });

    return result;
}

// Get the sum of the total-costs of post production raw_footage_delivery items
// in an invoice
export const getPostProductionRawFootageDeliveryTotalCost = (invoiceDetail:any) => {
    const post_production = invoiceDetail?.invoice?.post_production;
    const items = Object.values (post_production?.line_items ?? []);

    let total_raw_footage_delivery_cost = 0;
    const cost = items.map((item:any) => {
        if (item?.is_raw_footage_delivery) {
            total_raw_footage_delivery_cost+=item.total_cost;
        }
    })
    // console.log("Total Raw Footage Delivery: ", total_raw_footage_delivery_cost);
    return total_raw_footage_delivery_cost;
}

/**
 * Determine if a crew package is the last in the production crew package list
 * regardless having many filming days
 * 
 * @param invoiceDetail - invoice details
 * @param day_index - day index
 * @param index - crew package index
 * @return boolean
 */

export const isLastCrewPackageByIndex = (invoiceDetail:any, day_index:number, index:number): boolean => {
    const production = invoiceDetail?.invoice?.production;
    const days = Object.keys(production)?.filter((key:any) => key.includes("day_"));
    const last_day_num = days.length;
    if (last_day_num > 0) {
        const last_day_index = last_day_num-1;
        const crew_packages = Object.values(production[`day_${last_day_num}`]?.line_items);
        const last_crew_package_index = crew_packages.length-1
        // console.log("Last Crew Package: ",day_index,"=", last_day_index, index, "=", last_crew_package_index);
        return last_day_index == day_index && last_crew_package_index == index;
    }
    return false;
}

// to shift updatedAt in working days and in working hours
const formatUpdatedAt = (updated_at: any): any => {

    const clientUpdatedAt = momentTz(updated_at).tz(mstTimezone);
    // const clientUpdatedAt = momentTz(updated_at);
    const updateAtWeekday = clientUpdatedAt.isoWeekday();
    const workingStart = clientUpdatedAt.clone().set({ hour: 9, minute: 0, second: 0 });
    const workingEnd = clientUpdatedAt.clone().set({ hour: 17, minute: 0, second: 0 });

    // client has  assigned in working days
    if (updateAtWeekday >= 1 && updateAtWeekday <= 5) {

        if (clientUpdatedAt.isBefore(workingStart)) {

            // console.log("Client updated before working start");
            return workingStart

        } else if (clientUpdatedAt.isAfter(workingEnd)) {

            // console.log("Client updated after working end");
            return workingEnd

        }

        // Client updatedAt time is within working hours
        return clientUpdatedAt

    }

    // client has assigned in weekends
    const days_to_previous_friday = (updateAtWeekday - 4) % 7
    const endOfLastWorkingDay = workingEnd.clone().subtract(days_to_previous_friday, "days");
    return endOfLastWorkingDay

}

const getFormatCurrentTime = (): any => {

    // const currentTime = momentTz();
    const currentTime = momentTz().tz(mstTimezone);
    const currentTimeWeekday = currentTime.isoWeekday();
    const workingStart = currentTime.clone().set({ hour: 9, minute: 0, second: 0 });
    const workingEnd = currentTime.clone().set({ hour: 17, minute: 0, second: 0 });

    // day is a working day
    if (currentTimeWeekday >= 1 && currentTimeWeekday <= 5) {

        if (currentTime.isBefore(workingStart)) {

            // console.log("Client updated before working start");
            return workingStart

        } else if (currentTime.isAfter(workingEnd)) {

            // console.log("Client updated after working end");
            return workingEnd

        }

        // Client updatedAt time is within working hours
        return currentTime

    }

    // day is a weekend
    const days_to_previous_friday = (currentTimeWeekday - 4) % 7
    const endOfLastWorkingDay = workingEnd.clone().subtract(days_to_previous_friday, "days");
    return endOfLastWorkingDay
}

export {
    booleanFormat
}