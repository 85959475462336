// const CrewDetailsText = {
//     'LARGE_CREW': ' Includes on-site media services provided by 2 media creators, a creative director, and a project manager. The 2 media creators will provide any 2 combinations of the following services: Videography, Photography, or Drone Operating. The creative director ensures all creative elements of the shoot meet and exceed top-tier industry standards. \n The project manager will handle on-set logistics. This package also includes optional audio recording, teleprompter, and lighting services.',
//     'MEDIUM_CREW': 'Includes on-site media services provided by 2 media creators and a project manager. The 2 media creators will provide any 2 combinations of the following services: Videography, Photography, or Drone Operating. The project manager will handle on-set logistics. This package also includes optional audio recording, teleprompter, and lighting services.',
//     'SMALL_CREW': 'Includes on-site media services provided by a media creator and a project manager. The media creator will provide 1 of the following services: Videography, Photography, or Drone Operating. The project manager will handle on-set logistics. This package also includes optional audio recording, teleprompter, and lighting services.',
//     WEDDING: 'Includes camera operator(s), and a creative director on site to guarantee a high level of quality and direction during the entire video shoot. Full lighting and other grip equipment is included in this package. Audio equipment is not included.',
//     LIVE_STREAM: 'Not all platforms are guaranteed compatible The streaming quality is dictated by the internet speed provided by the venue / filming location. Livestream services are limited to just providing the above services. Any presentation slides, interactive features of live streams (such as assisting with chats, or having live stream attendants speak, muting and unmuting attendants, etc) are not included. Company cannot guarantee accuracy or fluidity of WiFi.',
//     POST_PRODUCTION: 'The hourly rate of our editing teams time to complete video editing work. Turnaround time is 7 business days or less if the total length of the edit is less than 5 minutes. Revisions (if requested) to the initial edit will be in addition to this hourly estimate.',
//     PROJECT_PLANNING: 'The time estimates necessary for planning of the upcoming project.',
//     SOLO_VIDEOGRAPHER: ' Includes on-site media services by a single professional for individuals or organizations. The crew member will provide 1 of the following services: Videography, Photography, or Drone Operating. This package does not include audio recording, teleprompter, or lighting services.'
// }

const CrewDetailsText = {
    'LARGE_CREW': 'Includes on-site media services. This package also includes optional audio recording, teleprompter, and lighting services.',
    'MEDIUM_CREW': 'Includes on-site media services. This package also includes optional audio recording, teleprompter, and lighting services.',
    'SMALL_CREW': 'Includes on-site media services. This package also includes optional audio recording, teleprompter, and lighting services.',
    WEDDING: 'Includes camera operator(s), and a creative director on site to guarantee a high level of quality and direction during the entire video shoot. Full lighting and other grip equipment is included in this package. Audio equipment is not included.',
    LIVE_STREAM: 'Not all platforms are guaranteed compatible The streaming quality is dictated by the internet speed provided by the venue / filming location. Livestream services are limited to just providing the above services. Any presentation slides, interactive features of live streams (such as assisting with chats, or having live stream attendants speak, muting and unmuting attendants, etc) are not included. Company cannot guarantee accuracy or fluidity of WiFi.',
    POST_PRODUCTION: 'The hourly rate of our editing teams time to complete video editing work. Turnaround time is 7 business days or less if the total length of the edit is less than 5 minutes.',
    PROJECT_PLANNING: 'The time estimates necessary for planning of the upcoming project.',
    SOLO_VIDEOGRAPHER: ' Includes on-site media services. This package also includes optional audio recording, teleprompter, and lighting services.'
}
export {
    CrewDetailsText
};