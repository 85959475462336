import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Box,
  Switch,
  Checkbox,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {
  DataGridPro,
  DataGridProProps,
  GridCallbackDetails,
  GridColumns,
  GridRowId,
  GridOverlay,
  useGridApiRef,
  GridToolbar,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import _, { debounce, find } from "lodash";
import { darken, lighten, styled } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  randomCreatedDate,
  randomEmail,
  randomInt,
  randomAddress,
  randomCommodity,
} from "@mui/x-data-grid-generator";
import { useAxios } from "../../core/useAxios";
import { APIMETHOD } from "../../core/constant";
import { getUserInfo } from "../../utils/utils";
import AddClients from "./AddClients";
import { Link, useNavigate } from "react-router-dom";
import LoadingService from "../../shared/loader";
import Moment from "react-moment";
import { TabPanel } from "../../shared/tabs/ProjectTabs";
import { padding } from "@mui/system";
import AssignProjectManager from "./AssignProjectManager";
import ReassignProjectManager from "./ReassignPM";
import { useAppStateContext } from "../../state/provider";
import { setRowId } from "../../state/config/actions";
import { cloneBtnStyle } from "../Project/Project";
import { toast } from "../../shared/Toser/ToastManager";
import "./client.css";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import { Pagination } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { PropaneTank } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";

export const style = {
  btnStyle: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 5px #0000000D",
    border: "1px solid #D1D1D1",
    borderRadius: "4px",
    fontFamily: "VerdanaBold",
    fontSize: "10px",
    color: "#000000",
  },
  btnStyle2: {
    // background: '#FFFFFF',
    boxShadow: "0px 2px 5px #0000000D",
    border: "1px solid #D1D1D1",
    borderRadius: "4px",
    fontFamily: "VerdanaBold",
    fontSize: "10px",
    // padding: '5px 10px'
  },
  muiSelect: {
    ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
  },
};
type IdObject = { [key: string]: boolean };
const StyledDataGrid: any = styled(DataGridPro)(({ theme }) => ({
  "& .super-app-theme--inquiry": {
    backgroundColor: "",
  },
  "& .super-app-theme--invoice_sent": {
    backgroundColor: "#90caf9",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#90caf9",
    },
  },
  "& .super-app-theme--fully_paid": {
    backgroundColor: "#81c784",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#81c784",
    },
  },
  "& .super-app-theme--partial_payment": {
    backgroundColor: "#81c784",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#81c784",
    },
  },
  "& .super-app-theme--first_down_payment": {
    backgroundColor: "#81c784",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#81c784",
    },
  },
  "& .super-app-theme--card_info_saved": {
    backgroundColor: "#81c784",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#81c784",
    },
  },
  "& .super-app-theme--manually_booked": {
    backgroundColor: "#81c784",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#81c784",
    },
  },
  "& .super-app-theme--not-responded": {
    backgroundColor: "#FF9C0B",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#FF9C0B",
    },
  },
  "& .super-app-theme--responded-not-proposal-sent": {
    backgroundColor: "#ECBE2F",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#ECBE2F",
    },
  },
  "& .super-app-theme--true": {
    backgroundColor: "#81c784",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#81c784",
    },
  },
  "& .super-app-theme--false": {
    backgroundColor: "",
  },
}));

interface IDetailPanelContent {
  row:any;
  onManuallyBooked?: (row: any) => void;
}

function DetailPanelContent({ row: rowProp, onManuallyBooked }: IDetailPanelContent) {
  const { state, dispatch } = useAppStateContext();
  const { put } = useAxios({showLoader: true})
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const handleClick = (params: any, parentRow: any) => {
    dispatch(setRowId(parentRow.id));
    navigate(
      `/invoice/${params?.row?.invoice}/${params?.row?.client}?type=${params?.row?.invoice_type}`
    );
  };
  const handleClone = (params: any, parentRow: any) => {
    dispatch(setRowId(parentRow.id));
    navigate(
      `/invoice/${params?.row?.invoice}/clone?type=${params?.row?.invoice_type}`
    );
  };
  const handleClickOnProposal = (params: any, parentRow: any) => {
    dispatch(setRowId(parentRow.id));
    navigate("/preview-invoice/" + params?.row?.invoice + "/");
  };

  const handleManuallyBook = (params: any, rowProp: any) => {
    confirm({
      title: (
        <div className="fs-16 fw-400">
          Manually Book: <span className="fw-500 text-primary">{params.row.name}</span>
        </div>
      ),
      confirmationText: "Yes",
      cancellationText: "No",
      description: (
        <div className="py-2 fs-17">
          Are you sure you want to manually book this project.
        </div>
      ),
    }).then(() => {
      // console.log("Params: ", params, rowProp)
      manuallyBookProject(params, rowProp);
    })
  }

  const manuallyBookProject = async (params: any, rowProp: any) => {
    // console.log("Params: ", params)
    // setLoading(true);
    const invoice_id = params.row.invoice;

    const res = await put({
      url: APIMETHOD.INVOICE.CREATE_INVOICE.admin+invoice_id+"/manually_book/",
    })

    console.log("Manually Book Response: ", res);

    if (res.success) {
      params.row.stage = "manually_booked";

      if (!_.isNil (onManuallyBooked))
        onManuallyBooked(rowProp)
    }
    setLoading(false)
  }

  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <LoadingService open={loading} />
      <Paper
        sx={{ flex: 1, mx: "auto", width: "95%", p: 2, background: "#F7F7F7" }}
      >
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography variant="h6">{"Projects"}</Typography>
          <StyledDataGrid
            density="compact"
            getRowClassName={(params: any) =>
              `super-app-theme--${params.row.stage}`
            }
            columns={[
              { field: "id", headerName: "Id", flex: 1 },
              { field: "name", headerName: "Name", flex: 1 },
              {
                field: "created_at",
                headerName: "Created On",
                align: "center",
                type: "date",
                flex: 1,
                renderCell: (params: any) => {
                  return <Moment format="MM/DD/YYYY">{params?.value}</Moment>;
                },
              },
              {
                field: "invoice_total",
                headerName: "Amount",
                flex: 1,
                renderCell: (params: any) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    ${params.value}
                  </div>
                ),
              },
              {
                field: "filming_days",
                headerName: "Total Filming/Photo Days",
                type: "number",
                flex: 1,
              },
              {
                field: "invoice",
                headerName: "Invoice",
                sortable: false,
                width: 100,
                renderCell: (params: any) => {
                  return (
                    <Button
                      size="small"
                      sx={style.btnStyle}
                      className="btn btn_custom"
                      onClick={() => handleClickOnProposal(params, rowProp)}
                    >
                      Proposal
                    </Button>
                  );
                  // <Button size='small' sx={style.btnStyle} className='btn btn_custom'>
                  //     {/* {params?.row?.invoice_pdf_url ? <a style={{textDecoration: 'none', color: '#000'}} target={'_blank'} href={params?.row?.invoice_pdf_url}>Download</a> : */}
                  //     {/* <Link style={{textDecoration: 'none', color: '#000'}} to={'/preview-invoice/' + params?.row?.invoice + '/'}>Proposal</Link> */}
                  //     {/* } */}
                  //   </Button>
                },
              },
              {
                field: "action",
                headerName: "Action",
                sortable: false,
                width: 300,
                renderCell: (params: any) => {
                  return (
                    <>
                      <Button
                        size="small"
                        sx={style.btnStyle}
                        className="btn btn_custom"
                        onClick={() => handleClick(params, rowProp)}
                        disabled={[
                          "fully_paid",
                          "first_down_payment",
                          "partial_payment",
                          "card_info_saved",
                        ].includes(params.row.stage)}
                      >
                        Edit
                      </Button>
                      <Button
                        size="small"
                        sx={cloneBtnStyle.btnStyle}
                        className="btn btn_custom mx-2"
                        onClick={() => handleClone(params, rowProp)}
                      >
                        Clone
                      </Button>
                      <Button
                        size="small"
                        // sx={cloneBtnStyle.btnStyle}
                        className="btn btn-primary mx-1 px-2 fw-bold"
                        disabled={[
                          "fully_paid",
                          "manually_booked",
                          "first_down_payment",
                          "partial_payment",
                          "card_info_saved",
                        ].includes(params.row.stage)}
                        onClick={() => handleManuallyBook(params, rowProp)}
                      >
                        Manually Book
                      </Button>
                    </>
                  );
                },
              },
            ]}
            rows={rowProp?.projects
              .filter((x: any) => x?.invoice !== null)
              .sort((a: any, b: any) => {
                return (
                  new Date(a.created_at).getTime() -
                  new Date(b.created_at).getTime()
                );
              })
              .reverse()}
            sx={{ flex: 1 }}
            hideFooter
          />
        </Stack>
      </Paper>
    </Stack>
  );
}

export default function Client(selectedpm: any) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openProjectDialog, setOpenProjectDialog] = useState<boolean>(false);
  const [openClientDialog, setOpenClientDialog] = useState<boolean>(false);
  const { state, dispatch } = useAppStateContext();
  const { get, put, loading } = useAxios({ showLoader: true });
  const [archiveIds, setArchiveIds] = useState<number[]>([]);
  const [unarchiveIds, setUnarchiveIds] = useState<any[]>([]);
  const [likelyToBookIds, setLikelyToBookIds] = useState<number[]>([]);
  const [unlikelyToBookIds, setUnLikelyToBookIds] = useState<any[]>([]);
  const userInfo = getUserInfo();
  const [rowId, _setRowId] = useState<any>([]);
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [clientRowData, setClientRowData] = useState<any[]>([]);
  const [clientUpdateData, setClientUpdateData] = useState(null);
  const [assinProjectRowData, setAssinProjectRowData] = useState<any[]>([]);
  const [archiveRowData, setArchiveRowData] = useState<any[]>([]);
  const [likelyToBookRowData, setLikelyToBookRowData] = useState<any[]>([]);
  const [bookedClients, setBookedClients] = useState<any[]>([]);
  const [clientDetails, setClientDetails] = useState<any>(null);
  const [page, setPage] = React.useState(1);
  const [pageArchive, setPageArchive] = React.useState(1);
  const [pageLikelyToBook, setPageLikelyToBook] = React.useState(1);
  const [pageAssigne, setPageAssigne] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(0);
  const [totalArchiveRows, setTotalArchiveRows] = React.useState(0);
  const [totalLikelyToBookRows, setTotalLikelyToBookRows] = React.useState(0);
  const [totalBookedRows, setTotalBookedRows] = React.useState(0);
  const [recurringClientRow, setRecurringClientRow] = React.useState(0);
  const [recurringClient, setRecurringClient] = React.useState(0);
  const [totalAssigneRows, setTotalAssigneRows] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchQueryArchive, setSearchQueryArchive] = React.useState("");
  const [searchQueryLikelyToBook, setSearchQueryLikelyToBook] =
    React.useState("");
  const [searchQueryAssigne, setSearchQueryAssigne] = React.useState("");
  const getDetailPanelHeight = React.useCallback(() => 250, []);
  const [value, setValue] = React.useState<any>(0);
  const [loadingRows, setLoadingRows] = useState<IdObject>({});
  const [newlyReplied, setNewlyReplied] = useState<IdObject>({});
  const [projectManager, setProjectManager] = useState<any>(null);
  const [selectedPM, setSelectedPM] = useState<any>(null);
  const [clientList, setClients] = React.useState<any>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState<any>({
    selected: null,
    selectedRows: [],
  });
  let previousRow: any = null;

  const currentDateTime = moment();
  let sortedBookedClients: any;
  let sortedRecurringClients: any;
  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => <DetailPanelContent row={row} onManuallyBooked={handleDetailContentOnManuallyBooked} />, []);

  const handleDetailContentOnManuallyBooked:any = (row:any) => {
    if (!row.statusstage) {
      setClientRowData(prevClientData => prevClientData.map((item) => item.id == row.id? {...item, statusstage: true} : item));
    }
    console.log("Client Row Updated: ", clientRowData);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // doResetIds();
  };

  const getClients = async () => {
    const result: any = await get({
      url:
        APIMETHOD.CLIENTS.GET_PAGINATED_DATA[userInfo.role] +
        "?is_archived=True" +
        "?likely_to_book=True" +
        `&page=${page}` +
        `&searchString=${searchQuery}`,
    });

    // console.log("Client Result: ", result);

    // Recurring Clients
    const recurringResult: any = await get({
      url: `${APIMETHOD.CLIENTS.RECURRING_CLIENTS.recurring_clients}page=${page}`,
    });
    const archiveResults: any = await get({
      url:
        APIMETHOD.CLIENTS.GET_PAGINATED_ARCHIED_DATA[userInfo.role] +
        "?is_archived=False" +
        `&page=${pageArchive}` +
        `&searchString=${searchQueryArchive}`,
    });
  
    const likelyToBookResults: any = await get({
      url:
        APIMETHOD.CLIENTS.GET_PAGINATED_LIKELYTOBOOK_DATA[userInfo.role] +
        "?likely_to_book=False" +
        `&page=${pageLikelyToBook}` +
        `&searchString=${searchQueryLikelyToBook}`,
    });

    if (userInfo.role == "admin") {
      const assigneeResults: any = await get({
        url:
          APIMETHOD.CLIENTS.GET_PAGINATED_ASSIGNE_DATA[userInfo.role] +
          "?is_archived=False" +
          `&page=${pageAssigne}` +
          `&searchString=${searchQueryAssigne}`,
      });
      const projManagers: any = await get({ url: APIMETHOD.user.GET_USER });
      // console.log("assigneeResults::",assigneeResults);
      setProjectManager(projManagers);
      setTotalAssigneRows(assigneeResults.count);
      setAssinProjectRowData(assigneeResults.results);
    }
    // const checkCreds: any = await get({
    //   url: APIMETHOD.user.CHECK_CREDS[userInfo.role],
    // });

    // if (checkCreds) {
    //   if (checkCreds?.auth_url) {
    //     let link: any = document.createElement("a");
    //     link.href = checkCreds?.auth_url;
    //     link.target = "_blank";
    //     link.click();
    //     return;
    //   }
    // }

    // const newOP: any = await get({url:
    //   APIMETHOD.DETAILS.IS_REPLIED[userInfo.role] + '3' + '/'})
    //   console.log("newOP::",newOP)
    let bookedClientsArray: any[] = [];

    if (result && result.results) {
      console.log("Client Result: ", result)
      result.results.map((r: any) => {
        r["status"] = r?.projects?.find(
          (pr: any) => pr.is_stripe_payment_setup == true
        )
          ? true
          : false;
        r["stage"] = r?.projects?.find((pr: any) => pr.stage === "invoice_sent")
          ? true
          : false;
        r["statusstage"] = r?.projects?.find(
          (pr: any) =>
            pr.stage === "card_info_saved" ||
            pr.stage === "partial_payment" ||
            pr.stage === "fully_paid" ||
            pr.stage === "first_down_payment"
        )
          ? true
          : false;
        return r;
      });
      result.results.map((r: any) => {
        r.projects.map((pr: any) => {
          if (
            (pr.is_stripe_payment_setup === true &&
              pr.stage === "invoice_sent") ||
            (pr.is_stripe_payment_setup == true && pr.stage == "inquiry")
          ) {
            pr.stage = "card_info_saved";
          }
        });
        // get latest booked project for booked clients tab

        if (r.statusstage === true) {
          bookedClientsArray.push(r);
        }
      });
      bookedClientsArray.map((bc: any) => {
        bc["latest_booked_date"] = bc.projects.sort(
          (a: any, b: any) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )[0].updated_at;
      });
      sortedBookedClients = bookedClientsArray.sort(
        (a: any, b: any) =>
          new Date(b.latest_booked_date).getTime() -
          new Date(a.latest_booked_date).getTime()
      );

      if (result && result.results) {
        result.results.map(async (r: any) => {
          const is_client_replied = await handleCheckClientRepliedOrNotRequest(
            r
          );
          const is_invoice_sent = r?.projects?.find(
            (pr: any) => pr.stage === "invoice_sent"
          );

          r["not_responded"] =
            r["assigned_to"] &&
            (r["response_time"] || r["response_time"] == 0) &&
            !r["is_replied"] &&
            !newlyReplied[r.id] &&
            !is_client_replied;
          r["responded_not_sent"] =
            r["assigned_to"] &&
            (r["is_replied"] || newlyReplied[r.id] || is_client_replied) &&
            !is_invoice_sent;
        });
      }
      setTotalRows(result.count);
      setClientRowData(result.results);
      setTotalBookedRows(sortedBookedClients.count);
      setBookedClients(sortedBookedClients);
    }
    
    if (archiveResults) {
      console.log("Archive Results: ", archiveResults)
      // setTotalArchiveRows(result.count);
      setArchiveRowData(archiveResults.results);
      setTotalArchiveRows(archiveResults.count);
    }
    if (likelyToBookResults && likelyToBookResults.results) {
      console.log("Likly to Book Results: ", likelyToBookResults)
      likelyToBookResults.results.map((r: any) => {
        r["status"] = r?.projects?.find(
          (pr: any) => pr.is_stripe_payment_setup == true
        )
          ? true
          : false;
        r["stage"] = r?.projects?.find((pr: any) => pr.stage === "invoice_sent")
          ? true
          : false;
        r["statusstage"] = r?.projects?.find(
          (pr: any) =>
            pr.stage === "card_info_saved" ||
            pr.stage === "partial_payment" ||
            pr.stage === "fully_paid" ||
            pr.stage === "first_down_payment"
        )
          ? true
          : false;
        return r;
      });
      likelyToBookResults.results.map((r: any) => {
        r.projects.map((pr: any) => {
          if (
            (pr.is_stripe_payment_setup === true &&
              pr.stage === "invoice_sent") ||
            (pr.is_stripe_payment_setup == true && pr.stage == "inquiry")
          ) {
            pr.stage = "card_info_saved";
          }
        });
      });
      if (likelyToBookResults && likelyToBookResults.results) {
        likelyToBookResults.results.map(async (r: any) => {
          const is_client_replied = await handleCheckClientRepliedOrNotRequest(
            r
          );
          const is_invoice_sent = r?.projects?.find(
            (pr: any) => pr.stage === "invoice_sent"
          );

          r["not_responded"] =
            r["assigned_to"] &&
            (r["response_time"] || r["response_time"] == 0) &&
            !r["is_replied"] &&
            !newlyReplied[r.id] &&
            !is_client_replied;
          r["responded_not_sent"] =
            r["assigned_to"] &&
            (r["is_replied"] || newlyReplied[r.id] || is_client_replied) &&
            !is_invoice_sent;
        });
      }
      setLikelyToBookRowData(likelyToBookResults.results);
      setTotalLikelyToBookRows(likelyToBookResults.count);
    }
    // RecurringClient

    let recurringClientsArray: any[] = [];

    if (recurringResult && recurringResult.results) {
      console.log("Recurring Results: ", recurringResult)
      recurringClientsArray = recurringResult.results.map((r: any) => {
        r["status"] = r.projects.some((pr: any) => pr.is_stripe_payment_setup);
        r["stage"] = r.projects.some((pr: any) => pr.stage === "invoice_sent");
        r["statusstage"] = r.projects.some(
          (pr: any) =>
            pr.stage === "card_info_saved" ||
            pr.stage === "partial_payment" ||
            pr.stage === "fully_paid" ||
            pr.stage === "first_down_payment"
        );
        r.projects.forEach((pr: any) => {
          if (
            (pr.is_stripe_payment_setup && pr.stage === "invoice_sent") ||
            (pr.is_stripe_payment_setup && pr.stage === "inquiry")
          ) {
            pr.stage = "card_info_saved";
          }
        });
        return r;
      });

      recurringClientsArray.forEach((bc: any) => {
        bc["latest_booked_date"] = bc.projects.sort(
          (a: any, b: any) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )[0].updated_at;
      });

      sortedRecurringClients = recurringClientsArray.sort(
        (a: any, b: any) =>
          new Date(b.latest_booked_date).getTime() -
          new Date(a.latest_booked_date).getTime()
      );
      setRecurringClient(sortedRecurringClients);
      setRecurringClientRow(sortedRecurringClients.length);
    }
  };

  useEffect(() => {
    if (value == 0) {
      fetchClients();
    } else if (value == 1) {
      fetchBookedClients();
    }

    console.log("Current Tab Number 0: ", value);
  }, [page, searchQuery, value])

  useEffect(() => {
    if (value == 2) {
      fetchRecurringClients()
    }
  }, [value]);

  useEffect(() => {
    if (value == 4) {
      fetchArchiveClients();
      console.log("Current Tab Number 4: ", value);
    }
  }, [pageArchive, searchQueryArchive, value])

  useEffect(() => {
    if (value == 3) {
      fetchLikelyToBookClients();
      console.log("Current Tab Number 3: ", value);
    }
  }, [pageLikelyToBook, searchQueryLikelyToBook, value])

  useEffect(() => {
    if (value == 5) {
      fetchAssignees();
      console.log("Current Tab Number 5: ", value);
    }
  }, [pageAssigne, searchQueryAssigne, value])

  // useEffect(() => {
  //   getClients();
  // }, [
  //   page,
  //   searchQuery,
  //   pageArchive,
  //   searchQueryArchive,
  //   pageAssigne,
  //   searchQueryAssigne,
  // ]);

  useEffect(() => {
    if (state.config?.rowId && expandedRows && expandedRows.length === 0) {
      setExpandedRows([state.config?.rowId]);
    }
  }, [state.config?.rowId]);

  // Fetching All Clients
  const fetchClients = async () => {
    const result: any = await get({
      url:
        APIMETHOD.CLIENTS.GET_PAGINATED_DATA[userInfo.role] +
        "?is_archived=False" +
        "&likely_to_book=True" +
        `&page=${page}` +
        `&searchString=${searchQuery}`,
    });

    await fetchProjectManagers();

    let bookedClientsArray: any[] = [];

    if (result && result.results) {
      result.results.map((r: any) => {
        r["status"] = r?.projects?.find(
          (pr: any) => pr.is_stripe_payment_setup == true
        )
          ? true
          : false;
        r["stage"] = r?.projects?.find((pr: any) => pr.stage === "invoice_sent")
          ? true
          : false;
        r["statusstage"] = r?.projects?.find(
          (pr: any) =>
            pr.stage === "card_info_saved" ||
            pr.stage === "manually_booked" ||
            pr.stage === "partial_payment" ||
            pr.stage === "fully_paid" ||
            pr.stage === "first_down_payment"
        )
          ? true
          : false;
        return r;
      });
      result.results.map((r: any) => {
        r.projects.map((pr: any) => {
          if (
            (pr.is_stripe_payment_setup === true &&
              pr.stage === "invoice_sent") ||
            (pr.is_stripe_payment_setup == true && pr.stage == "inquiry") || pr.stage == "manually_booked"
          ) {
            pr.stage = "card_info_saved";
          }
        });
        // get latest booked project for booked clients tab

        // if (r.statusstage === true) {
        //   bookedClientsArray.push(r);
        // }
      });
      // bookedClientsArray.map((bc: any) => {
      //   bc["latest_booked_date"] = bc.projects.sort(
      //     (a: any, b: any) =>
      //       new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      //   )[0].updated_at;
      // });
      // sortedBookedClients = bookedClientsArray.sort(
      //   (a: any, b: any) =>
      //     new Date(b.latest_booked_date).getTime() -
      //     new Date(a.latest_booked_date).getTime()
      // );

      if (result && result.results) {
        result.results.map(async (r: any) => {
          const is_client_replied = await handleCheckClientRepliedOrNotRequest(
            r
          );
          const is_invoice_sent = r?.projects?.find(
            (pr: any) => pr.stage === "invoice_sent"
          );

          r["not_responded"] =
            r["assigned_to"] &&
            (r["response_time"] || r["response_time"] == 0) &&
            !r["is_replied"] &&
            !newlyReplied[r.id] &&
            !is_client_replied;
          r["responded_not_sent"] =
            r["assigned_to"] &&
            (r["is_replied"] || newlyReplied[r.id] || is_client_replied) &&
            !is_invoice_sent;
        });
      }
      console.log("Clients Fetched: ", result.results)
      setTotalRows(result.count);
      setClientRowData(result.results);
      // setTotalBookedRows(sortedBookedClients.count);
      // console.log("Booked Clients: ", sortedBookedClients);
      // setBookedClients(sortedBookedClients);
    }
  }

  // Fetching the recurring clients
  const fetchRecurringClients = async () => {
    const recurringResult: any = await get({
      url: `${APIMETHOD.CLIENTS.RECURRING_CLIENTS.recurring_clients}page=${page}`,
    });

    let recurringClientsArray: any[] = [];

    if (recurringResult && recurringResult.results) {
      console.log("Recurring Results: ", recurringResult)
      recurringClientsArray = recurringResult.results.map((r: any) => {
        r["status"] = r.projects.some((pr: any) => pr.is_stripe_payment_setup);
        r["stage"] = r.projects.some((pr: any) => pr.stage === "invoice_sent");
        r["statusstage"] = r.projects.some(
          (pr: any) =>
            pr.stage === "card_info_saved" ||
            pr.stage === "partial_payment" ||
            pr.stage === "fully_paid" ||
            pr.stage === "first_down_payment"
        );
        r.projects.forEach((pr: any) => {
          if (
            (pr.is_stripe_payment_setup && pr.stage === "invoice_sent") ||
            (pr.is_stripe_payment_setup && pr.stage === "inquiry")
          ) {
            pr.stage = "card_info_saved";
          }
        });
        return r;
      });

      recurringClientsArray.forEach((bc: any) => {
        bc["latest_booked_date"] = bc.projects.sort(
          (a: any, b: any) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )[0].updated_at;
      });

      sortedRecurringClients = recurringClientsArray.sort(
        (a: any, b: any) =>
          new Date(b.latest_booked_date).getTime() -
          new Date(a.latest_booked_date).getTime()
      );
      setRecurringClient(sortedRecurringClients);
      setRecurringClientRow(sortedRecurringClients.length);
    }
  }

  const fetchArchiveClients = async () => {
    const archiveResults: any = await get({
      url:
        APIMETHOD.CLIENTS.GET_PAGINATED_ARCHIED_DATA[userInfo.role] +
        "?is_archived=False" +
        `&page=${pageArchive}` +
        `&searchString=${searchQueryArchive}`,
    });

    if (archiveResults) {
      console.log("Archive Results: ", archiveResults)
      // setTotalArchiveRows(totalRows);
      setArchiveRowData(archiveResults.results);
      setTotalArchiveRows(archiveResults.count);
    }
  }

  const fetchLikelyToBookClients = async () => {
    const likelyToBookResults: any = await get({
      url:
        APIMETHOD.CLIENTS.GET_PAGINATED_LIKELYTOBOOK_DATA[userInfo.role] +
        "?likely_to_book=False" +
        `&page=${pageLikelyToBook}` +
        `&searchString=${searchQueryLikelyToBook}`,
    });

    if (likelyToBookResults && likelyToBookResults.results) {
      console.log("Likly to Book Results: ", likelyToBookResults)
      likelyToBookResults.results.map((r: any) => {
        r["status"] = r?.projects?.find(
          (pr: any) => pr.is_stripe_payment_setup == true
        )
          ? true
          : false;
        r["stage"] = r?.projects?.find((pr: any) => pr.stage === "invoice_sent")
          ? true
          : false;
        r["statusstage"] = r?.projects?.find(
          (pr: any) =>
            pr.stage === "card_info_saved" ||
            pr.stage === "partial_payment" ||
            pr.stage === "fully_paid" ||
            pr.stage === "first_down_payment"
        )
          ? true
          : false;
        return r;
      });
      likelyToBookResults.results.map((r: any) => {
        r.projects.map((pr: any) => {
          if (
            (pr.is_stripe_payment_setup === true &&
              pr.stage === "invoice_sent") ||
            (pr.is_stripe_payment_setup == true && pr.stage == "inquiry")
          ) {
            pr.stage = "card_info_saved";
          }
        });
      });

      if (likelyToBookResults && likelyToBookResults.results) {
        likelyToBookResults.results.map(async (r: any) => {
          const is_client_replied = await handleCheckClientRepliedOrNotRequest(
            r
          );
          const is_invoice_sent = r?.projects?.find(
            (pr: any) => pr.stage === "invoice_sent"
          );

          r["not_responded"] =
            r["assigned_to"] &&
            (r["response_time"] || r["response_time"] == 0) &&
            !r["is_replied"] &&
            !newlyReplied[r.id] &&
            !is_client_replied;
          r["responded_not_sent"] =
            r["assigned_to"] &&
            (r["is_replied"] || newlyReplied[r.id] || is_client_replied) &&
            !is_invoice_sent;
        });
      }
      setLikelyToBookRowData(likelyToBookResults.results);
      setTotalLikelyToBookRows(likelyToBookResults.count);
    }
  }

  const fetchAssignees = async () => {
    if (userInfo.role == "admin") {
      const assigneeResults: any = await get({
        url:
          APIMETHOD.CLIENTS.GET_PAGINATED_ASSIGNE_DATA[userInfo.role] +
          "?is_archived=False" +
          `&page=${pageAssigne}` +
          `&searchString=${searchQueryAssigne}`,
      });
      // await fetchProjectManagers()
      setTotalAssigneRows(assigneeResults.count);
      setAssinProjectRowData(assigneeResults.results);
    }
  }

  const fetchProjectManagers = async () => {
    const projManagers: any = await get({ url: APIMETHOD.user.GET_USER });
    setProjectManager(projManagers);
  }

  const fetchBookedClients = async () => {
    try {
      const stage = ["card_info_saved","manually_booked", "partial_payment", "fully_paid", "first_down_payment"];
  
      const result: any = await get({
        url:
          APIMETHOD.CLIENTS.GET_PAGINATED_DATA[userInfo.role] +
          // "?is_archived=False" +
          // "?likely_to_book=True" +
          `?page=${page}` +
          `&searchString=${searchQuery}&stage=${stage}`,
      });
  
      let bookedClientsArray: any[] = [];
  
      console.log("Booked Clients Result: ", result);
  
      if (result && result.results) {
        result.results.map((r: any) => {
          r.projects.map((pr: any) => {
            if (
              (pr.is_stripe_payment_setup === true &&
                pr.stage === "invoice_sent") ||
              (pr.is_stripe_payment_setup == true && pr.stage == "inquiry") || pr.stage == "manually_booked"
            ) {
              pr.stage = "card_info_saved";
            }
          });
        });
        bookedClientsArray = result.results;
  
        bookedClientsArray.map((bc: any) => {
          bc["latest_booked_date"] = bc.projects.sort(
            (a: any, b: any) =>
              new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
          )[0]?.updated_at;
        });
        sortedBookedClients = bookedClientsArray.sort(
          (a: any, b: any) =>
            new Date(b.latest_booked_date).getTime() -
            new Date(a.latest_booked_date).getTime()
        );
  
        console.log("Booked Clients: ", sortedBookedClients);
        setTotalBookedRows(result.count);
        setBookedClients(sortedBookedClients);
      }
    } catch (error) {
      console.error("Booked Clients Error: ", error);
    }


  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const defaultProps = {
    options: clientList,
    getOptionLabel: (option: any) => `${option.full_name}`,
  };

  const handlePageArchiveChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPageArchive(newPage);
  };
  const handlePageLikelyToBookChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPageLikelyToBook(newPage);
  };
  const handlePageAssigneChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPageAssigne(newPage);
  };

  const handleCheckClientRepliedOrNotRequest = async (row: any) => {
    if (!row.is_replied && (row.response_time || row.response_time == 0)) {
      setLoadingRows((prevLoadingRows) => ({
        ...prevLoadingRows,
        [row.id]: true,
      }));
      try {
        // Make your API request here
        // Replace this with your actual API call
        // await fetch(`your_api_endpoint/${row.id}`);

        const clientReplied: any = await get({
          url: APIMETHOD.DETAILS.IS_REPLIED[userInfo.role] + `${row.id}/`,
        });

        // console.log("clientReplied::", clientReplied);
        // set he replied localy
        if (clientReplied && clientReplied.is_replied) {
          setNewlyReplied((preRepliedClients) => ({
            ...preRepliedClients,
            [row.id]: true,
          }));
        }
        setLoadingRows((prevLoadingRows) => ({
          ...prevLoadingRows,
          [row.id]: false, // Stop loading for this row
        }));
        return clientReplied && clientReplied.is_replied
          ? clientReplied.is_replied
          : false;

        // API request successful, update state
      } catch (error) {
        console.error("Error making API request:", error);
        // Handle API request error here
        setLoadingRows((prevLoadingRows) => ({
          ...prevLoadingRows,
          [row.id]: false, // Stop loading for this row
        }));
        return false;
      }
    }
  };

  const upsertClients = (data: any, id=null) => {
    if (!! id) {
      const prevData = find (clientRowData, (elem) => elem.id == id);
      prevData.name = data.name;
      prevData.email = data.email;
      prevData.phone = data.phone;
      console.log("Previous Data: ", prevData)

    } else {
      setClientRowData([...clientRowData, data]);
    }
  };

  const _openProjectDialog = (params: any) => {
    setClientDetails(params?.row);
    setOpenProjectDialog(true);
  };
  const _openClientDialog = (params: any) => {
    setClientDetails(params?.row);
    setOpenClientDialog(true);
  };

  const handleArchiveStatus = async (ids: any[], is_archived: boolean) => {
    try {
      if (ids.length <= 0) {
        toast.show({
          severity: "error",
          message: "Please select clients first",
        });
        return;
      }

      await put({
        url: APIMETHOD.INVOICE.CLIENTS[userInfo.role],
        data: { client_ids: ids, is_archived },
      });
      await getClients();

      if (is_archived) setArchiveIds([]);
      else setUnarchiveIds([]);

      const action = is_archived ? "archived" : "unarchived";

      toast.show({
        severity: "success",
        message: `Client ${action} successfully`,
      });
    } catch (errors) {
      toast.show({ severity: "error", message: "Something went wrong" });
    }
  };

  const handleLikelyToBook = async (ids: any[], likely_to_book: boolean) => {
    try {
      if (ids.length <= 0) {
        toast.show({
          severity: "error",
          message: "Please select clients first",
        });
        return;
      }

      await put({
        url: APIMETHOD.INVOICE.CLIENTS[userInfo.role],
        data: { client_ids: ids, likely_to_book },
      });
      await getClients();

      if (likely_to_book) setLikelyToBookIds([]);
      else setUnLikelyToBookIds([]);

      const action = likely_to_book
        ? "has been set to 'likely to book'"
        : "has been removed from 'likely to book'";

      toast.show({
        severity: "success",
        message: `Client ${action}`,
      });
    } catch (errors) {
      toast.show({ severity: "error", message: "Something went wrong" });
    }
  };
  const doResetIds = () => {
    setArchiveIds([]);
    setUnarchiveIds([]);
  };

  const columnVisibilityModel = React.useMemo(() => {
    if (userInfo.role !== "admin") {
      return {
        assigned_to: false,
        __detail_panel_toggle__: false,
      };
    }
    return {
      assigned_to: true,
      __detail_panel_toggle__: false,
    };
  }, [userInfo.Role]);

  const columns: GridColumns = [
    { field: "name", headerName: "Name", width: 100, flex: 1, editable: true },
    { field: "email", headerName: "Email", flex: 1, editable: true },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      width: 200,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "total_projects",
      headerName: "Total Project",
      width: 10,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Inquiry Date",
      flex: 1,
      width: 10,
      renderCell: (params: any) => {
        const createdDate = params?.value;
        if (createdDate) {
          const date = moment.utc(createdDate); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "latest_booked_date",
      headerName: "Recently Booked Project",
      flex: 1,
      width: 10,
      renderCell: (params: any) => {
        const bookeddate = params?.value;
        if (bookeddate) {
          const date = moment.utc(bookeddate); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },

    {
      field: "assigned_to",
      headerName: "Assigned Project Manager",
      flex: 1,
      width: 50,
      renderCell: (params: any) => {
        if (userInfo.role == "admin") {
          console.log("Assigned_to_fullname: ", params?.row?.assigned_to_fullname)
          return (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={style.muiSelect}
              inputProps={{ IconComponent: () => null }} // this part
              label="Assigned Project Manager"
              onChange={(_, newValue) => {
                _openClientDialog(params);
                setSelectedPM(newValue);
              }}
              value={params?.row?.assigned_to_fullname}
            >
              {projectManager.map((pm: any, index: any) => (
                <MenuItem key={pm.id} value={pm.full_name}>
                  {pm.full_name}
                </MenuItem>
              ))}
            </Select>
          );
        }
        return <div>{params.row.assigned_to_full_name}</div>;
      },
    },
    {
      field: "id",
      headerName: "Action",
      flex: 1,
      width: 5,
      renderCell: (params: any) => {
        return (
          <Button
              onClick={() => {
                setClientUpdateData(params)
                setOpenDialog(true)
              }}
              size="small"
              aria-haspopup="true"
              sx={{ padding: "5px 3px" }}
              disableElevation
          >
              <EditOutlinedIcon style={{fontSize: "15px"}} />
          </Button>
        );
      },
    },
  ];

  const columns1: GridColumns = [
    { field: "name", headerName: "Name", width: 100, flex: 1 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      width: 200,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Assign Project Manager",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Button
            size="small"
            variant="contained"
            sx={style.btnStyle2}
            className="btn btn_custom"
            onClick={() => _openProjectDialog(params)}
          >
            Assign
          </Button>
        );
      },
    },
  ];

  const archiveColumns: GridColumns = [
    { field: "name", headerName: "Name", width: 200, flex: 1 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      width: 100,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      ),
    },
    { field: "total_projects", headerName: "Total Project", flex: 1 },
  ];

  const likelyToBookColumns: GridColumns = [
    { field: "name", headerName: "Name", width: 100, flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      width: 200,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "total_projects",
      headerName: "Total Project",
      width: 10,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Inquiry Date",
      flex: 1,
      width: 10,
      renderCell: (params: any) => {
        const createdDate = params?.value;
        if (createdDate) {
          const date = moment.utc(createdDate); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },

    {
      field: "assigned_to",
      headerName: "Assigned Project Manager",
      flex: 1,
      width: 50,
      renderCell: (params: any) => {
        if (userInfo.role == "admin") {
          return (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={style.muiSelect}
              inputProps={{ IconComponent: () => null }} // this part
              label="Assigned Project Manager"
              onChange={(_, newValue) => {
                _openClientDialog(params);
                setSelectedPM(newValue);
              }}
              value={params.row.assigned_to_fullname}
            >
              {projectManager.map((pm: any, index: any) => (
                <MenuItem key={pm.id} value={pm.full_name}>
                  {pm.full_name}
                </MenuItem>
              ))}
            </Select>
          );
        }
        return <div>{params.row.assigned_to_full_name}</div>;
      },
    },
  ];
  // const visibleColumns = userInfo.role !=="admin" ? columns.filter(column=>column.field !== "assigned_to"):columns

  const onDetailPanelExpandedRowIdsChange = (
    ids: Array<GridRowId>,
    details: GridCallbackDetails
  ) => {
    _setRowId(ids);
  };

  const handleRowClick = (params: any) => {
    const rowId = params.id;
    if (expandedRows.includes(rowId)) {
      // If the row is already expanded, collapse it
      setExpandedRows(expandedRows.filter((id: number) => id !== rowId));
    } else {
      // If the row is not expanded, expand it
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  const getClassName = (params: any) => {
    let className = "super-app-theme--false";

    // client not reponded
    if (params.row.not_responded) {
      className = "super-app-theme--not-responded";
    }

    // client replied but not proposal not sent
    if (params.row.responded_not_sent) {
      className = "super-app-theme--responded-not-proposal-sent";
    }

    // proposal sent
    if (params.row.stage) {
      className = "super-app-theme--invoice_sent";
    }
    // booked clients
    if (params.row.status) {
      className = "super-app-theme--true";
    }
    //proposal sent and payment setup before first payment is made
    if (params.row.statusstage) {
      className = "super-app-theme--card_info_saved";
    }
    //proposal manually booked
    if (params.row.statusstage) {
      className = "super-app-theme--manually_booked";
    }
    return className;
  };

  const handleOnRowSelection = (
    rowSelectionModel: any,
    details: GridCallbackDetails
  ) => {
    console.log("row details", details);
  };

  return (
    <div className="container">
      <LoadingService open={loading} />
      {openDialog && (
        <AddClients
          isOpen={openDialog}
          handleClose={() => {
            setOpenDialog(false)
            setClientUpdateData(null)
          }}
          addClients={upsertClients}
          clientUpdateData={clientUpdateData}
        />
      )}
      {openProjectDialog && (
        <AssignProjectManager
          isOpen={openProjectDialog}
          handleClose={() => setOpenProjectDialog(false)}
          clientDetails={clientDetails}
        />
      )}
      {openClientDialog && (
        <ReassignProjectManager
          isOpen={openClientDialog}
          handleClose={() => setOpenClientDialog(false)}
          clientDetails={clientDetails}
          selectedPM={selectedPM}
        />
      )}
      <Box sx={{ my: 2 }}>
        <h5>Client Management Update</h5>
      </Box>
      <div className="p-4 bg-white shadow rounded-3">
        <Box sx={{ bgcolor: "background.paper" }}>
          <div className="d-flex custom_tabs_style">
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              className="custom_tabs pentagon"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              centered={false}
            >
              <Tab key={"tab1"} label={"Clients"} />
              <Tab key={"tab2"} label={"Booked Clients"} />
              <Tab key={"tab3"} label={"Recurring Clients"} />
              <Tab key={"tab4"} label={"Likely To Book"} />
              <Tab key={"tab5"} label={"Archive"} />
              {userInfo?.role && userInfo.role !== "project_manager" && (
                <Tab key={"tab6"} label={"Assign Project Manager"} />
              )}
            </Tabs>
            <input
              type="text"
              placeholder="Search"
              className="styled-input"
              onChange={(event) => {
                console.log("Current Tab Index: ", value);
                switch(value) {
                  case 0:
                    setSearchQuery(event.target.value);
                    break;

                  case 3:
                    setSearchQueryLikelyToBook(event.target.value);
                    break;

                  case 4:
                    setSearchQueryArchive(event.target.value);
                    break;
                  
                  case 5:
                    setSearchQueryAssigne(event.target.value);
                    break;
                }
              }}
            />
            {/* <div className="col-md-6 align-items-center d-flex justify-content-between"> */}
          </div>
        </Box>
        <TabPanel key={"tabpanel1"} value={value} index={0}>
          <div>
            <div className="row justify-content-between mb-1 ">
              <div className="col-sm-5 mx-3">
                <div className="mx-3 d-flex defaultFontFamilyB">
                  <CropSquareIcon
                    sx={{ color: "#FF9C0B", backgroundColor: "#FF9C0B" }}
                  />
                  <p>Client Has NOT Responded Back</p>
                </div>
                <div className="mx-3 d-flex defaultFontFamilyB">
                  <CropSquareIcon
                    sx={{ color: "#ECBE2F", backgroundColor: "#ECBE2F" }}
                  />
                  <p>Client Has Responded Back But Proposal NOT Sent</p>
                </div>
                <div className="mx-3 d-flex defaultFontFamilyB">
                  <CropSquareIcon
                    sx={{ color: "#90caf9", backgroundColor: "#90caf9" }}
                  />
                  <p>Proposal Sent but NOT Booked Clients</p>
                </div>
                <div className="mx-3 d-flex defaultFontFamilyB">
                  <CropSquareIcon
                    sx={{ color: "#81c784", backgroundColor: "#81c784" }}
                  />
                  <p>Booked Clients</p>
                </div>
              </div>
              <div className="col-md-6 align-items-center d-flex w-full justify-content-end">
                {/* <div className="col-md-6 align-items-center d-flex"> */}
                <Button
                  sx={{
                    height: "32px",
                    background: "#1cc4e7",
                    border: "border: 1px solid #81D0D8",
                    color: "#000",
                    fontFamily: "VerdanaBold",
                  }}
                  variant="contained"
                  onClick={() => setOpenDialog(true)}
                >
                  Add Client
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleLikelyToBook(likelyToBookIds, true)}
                  className="ms-3"
                >
                  Add to Likely To Book
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleArchiveStatus(archiveIds, true)}
                  className="ms-3"
                >
                  Move to archive
                </Button>
              </div>
            </div>
            <Box
              sx={{ width: "100%", minHeight: window.innerHeight - 190 + "px" }}
            >
              <StyledDataGrid
                className="border-0 defaultFontFamily"
                sx={{
                  ".MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold !important",
                  },
                  ".MuiDataGrid-row > div > button.MuiIconButton-root ": {
                    display: "none",
                  },
                  ".MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft":
                    {
                      display: "none",
                    },
                }}
                columns={columns}
                getRowClassName={(params: any) => {
                  return getClassName(params);
                }}
                rows={clientRowData}
                pageSize={50}
                autoPageSize={true}
                rowsPerPageOptions={[50]}
                rowCount={totalRows}
                onPageChange={(params: any) =>
                  handlePageChange(params.event, params.page)
                }
                rowThreshold={0}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                rowHeight={30}
                autoHeight={true}
                onRowClick={handleRowClick} // Handle row click event
                detailPanelExpandedRowIds={expandedRows} // Pass the expanded rows as an array
                components={{ Toolbar: GridToolbar }}
                columnVisibilityModel={columnVisibilityModel}
                checkboxSelection
                onSelectionModelChange={(rowsSelected: any, allRows: any) => {
                  setSelectedCheckboxes(rowsSelected);
                  setLikelyToBookIds(rowsSelected);
                  setArchiveIds(rowsSelected);
                }}
                selectableRow="multiple"
                selectableRowsOnClick={true}
                rowSelected={selectedCheckboxes}
              />
              <Pagination
                count={Math.ceil(totalRows / 50)}
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          </div>
        </TabPanel>
        <TabPanel key={"tabpanel2"} value={value} index={1}>
          <Box
            sx={{ width: "100%", minHeight: window.innerHeight - 190 + "px" }}
          >
            <StyledDataGrid
              className="border-0 defaultFontFamily"
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold !important",
                },
              }}
              columns={columns}
              rows={bookedClients}
              autoPageSize={true}
              rowHeight={30}
              autoHeight={true}
              pageSize={50}
              rowCount={totalBookedRows}
              components={{ Toolbar: GridToolbar }}
              getRowClassName={(params: any) => `super-app-theme--true`}
              onPageChange={(params: any) =>
                handlePageChange(params.event, params.page)
              }
              rowThreshold={0}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              onRowClick={handleRowClick} // Handle row click event
              detailPanelExpandedRowIds={expandedRows} // Pass the expanded rows as an array
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    __detail_panel_toggle__: false,
                  },
                },
              }}
              rowsPerPageOptions={[50]}
            />
            <Pagination
              count={Math.ceil(totalBookedRows / 50)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </TabPanel>
        <TabPanel key={"tabpanel3"} value={value} index={2}>
          <Box
            sx={{ width: "100%", minHeight: window.innerHeight - 190 + "px" }}
          >
            <StyledDataGrid
              className="border-0 defaultFontFamily"
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold !important",
                },
              }}
              columns={columns}
              rows={recurringClient}
              autoPageSize={true}
              rowHeight={30}
              autoHeight={true}
              pageSize={25}
              rowCount={recurringClientRow}
              components={{ Toolbar: GridToolbar }}
              getRowClassName={(params: any) => `super-app-theme--true`}
              onPageChange={(params: any) =>
                handlePageChange(params.event, params.page)
              }
              rowThreshold={0}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              onRowClick={handleRowClick} // Handle row click event
              detailPanelExpandedRowIds={expandedRows} // Pass the expanded rows as an array
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    __detail_panel_toggle__: false,
                  },
                },
              }}
              rowsPerPageOptions={[25]}
            />
            <Pagination
              count={Math.ceil(recurringClientRow / 25)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </TabPanel>
        <TabPanel key={"tabpanel4"} value={value} index={3}>
          <Box
            sx={{ width: "100%", minHeight: window.innerHeight - 190 + "px" }}
          >
            <div className="text-right align-items-center d-flex justify-content-end">
              <Button
                variant="contained"
                onClick={() => handleLikelyToBook(unlikelyToBookIds, false)}
                className="ms-3"
              >
                Move to Clients
              </Button>
              <Button
                variant="contained"
                onClick={() => handleArchiveStatus(archiveIds, true)}
                className="ms-3"
              >
                Move to archive
              </Button>
            </div>
            <StyledDataGrid
              className="border-0 defaultFontFamily"
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold !important",
                },
              }}
              columns={likelyToBookColumns}
              rows={likelyToBookRowData}
              rowThreshold={0}
              rowHeight={30}
              autoHeight={true}
              autoPageSize={true}
              pageSize={25}
              rowCount={totalLikelyToBookRows}
              components={{ Toolbar: GridToolbar }}
              getRowClassName={(params: any) => {
                return getClassName(params);
              }}
              onPageChange={(params: any) =>
                handlePageChange(params.event, params.page)
              }
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              onRowClick={handleRowClick} // Handle row click event
              detailPanelExpandedRowIds={expandedRows} // Pass the expanded rows as an array
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    __detail_panel_toggle__: false,
                  },
                },
              }}
              checkboxSelection
              onSelectionModelChange={(rowsSelected: any, allRows: any) => {
                setSelectedCheckboxes(rowsSelected);
                if (archiveIds) {
                  setArchiveIds(rowsSelected);
                }
                if (likelyToBookIds) {
                  setUnLikelyToBookIds(rowsSelected);
                }
              }}
              selectableRow="multiple"
              selectableRowsOnClick={true}
              rowSelected={selectedCheckboxes}
            />
            <Pagination
              count={Math.ceil(totalLikelyToBookRows / 25)}
              page={pageLikelyToBook}
              onChange={handlePageLikelyToBookChange}
            />
          </Box>
        </TabPanel>
        <TabPanel key={"tabpanel5"} value={value} index={4}>
          <Box
            sx={{ width: "100%", minHeight: window.innerHeight - 190 + "px" }}
          >
            <div className="text-right align-items-center d-flex justify-content-end">
              <Button
                variant="contained"
                onClick={() => handleArchiveStatus(unarchiveIds, false)}
                className="ms-3"
              >
                Unarchive
              </Button>
            </div>
            <StyledDataGrid
              className="border-0 defaultFontFamily"
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold !important",
                },
              }}
              columns={archiveColumns}
              rows={archiveRowData}
              rowThreshold={0}
              rowHeight={30}
              autoHeight={true}
              autoPageSize={true}
              pageSize={25}
              rowCount={totalArchiveRows}
              components={{ Toolbar: GridToolbar }}
              checkboxSelection
              onSelectionModelChange={(rowsSelected: any, allRows: any) => {
                setSelectedCheckboxes(rowsSelected);
                setUnarchiveIds(rowsSelected);
              }}
              selectableRow="multiple"
              selectableRowsOnClick={true}
              rowSelected={selectedCheckboxes}
            />
            <Pagination
              count={Math.ceil(totalArchiveRows / 25)}
              page={pageArchive}
              onChange={handlePageArchiveChange}
            />
          </Box>
        </TabPanel>
        {userInfo?.role && userInfo.role !== "project_manager" && (
          <TabPanel key={"tabpanel6"} value={value} index={5}>
            <Box
              sx={{ width: "100%", minHeight: window.innerHeight - 190 + "px" }}
            >
              <div className="text-right align-items-center d-flex justify-content-end">
                <Button
                  variant="contained"
                  onClick={() => handleArchiveStatus(archiveIds, true)}
                  className="ms-3"
                >
                  Move to archive
                </Button>
              </div>
              <DataGridPro
                className="border-0 defaultFontFamily"
                sx={{
                  ".MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold !important",
                  },
                }}
                columns={columns1}
                rows={assinProjectRowData}
                rowThreshold={0}
                rowHeight={30}
                autoHeight={true}
                autoPageSize={true}
                pageSize={25}
                rowCount={totalAssigneRows}
                components={{ Toolbar: GridToolbar }}
              />
              <Pagination
                count={Math.ceil(totalAssigneRows / 25)}
                page={pageAssigne}
                onChange={handlePageAssigneChange}
              />
            </Box>
          </TabPanel>
        )}
      </div>
    </div>
  );
}