import { useEffect, useState, useMemo } from "react";
import { CREW_SIZE, _CrewData, _days } from "./modal";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import GeoLocation from "../../shared/location/GeoLocation";
import moment from "moment";
import { start } from "repl";
import { String, filter, find, isNaN, isNil, remove } from "lodash";

function CrewDetails(props: any) {
  // const [selectedArrival, setSelectedArrival] = useState(moment())
  const [selectedArrival, setSelectedArrival] = useState();
  const [applyDetails, setApplyDetails] = useState(false);
  const [rentalAutoPolulateIndex, setRentalAutoPolulateIndex] = useState<Number>(NaN);
  const [selectedCrewSize, setSelectedCrewSize] = useState("");

  const { tabNumber, formikProps, crewIndex } = props;
  const {
    handleChange,
    production_details,
    setFieldValue,
    values,
    errors,
    touched,
  } = formikProps;
  const crew_form = `production_details.day[${tabNumber}].crew_package`;
  const crew_value = values?.production_details?.day[tabNumber]?.crew_package;
  const crew_package_needed = values?.production_details?.day[tabNumber]?.crew_package_needed;

  let errors_production = formikProps?.errors?.production_details?.day
    ? formikProps?.errors?.production_details?.day[tabNumber]
    : null;

  errors_production = errors_production
    ? errors_production?.crew_package
    : null;
  errors_production = errors_production ? errors_production[crewIndex] : null;
  useEffect(() => {
    let data: any = crew_value;
    if (!data[crewIndex]) {
      data[crewIndex] = _CrewData;
      setFieldValue(`production_details.day[${tabNumber}].crew_package`, data);
    }

    if (!!crew_value[crewIndex].arrival_time) {
      setSelectedArrival(crew_value[crewIndex].arrival_time);
    }

    if (crew_package_needed < crew_value?.length && data && data.length > 0) {
      const updated_crewPackege: any[] = data.slice(0, crew_package_needed);
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package`,
        updated_crewPackege
      );
    }
    console.log(`Crew Values ${crewIndex}: `, crew_value[crewIndex]);
  }, [crewIndex, crew_package_needed]);

  const updateCrewSize = (e: any) => {
    handleChange(e);
    // const size = crew_value[crewIndex]?.size;
    const size = e.target.value;
    
    if (size) {
      // setFieldValue(
      //   `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_1`,
      // );
      // setFieldValue(
      //   `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_2`,
      // );
      setCrewMembers(e, size);
    }
  };

  const setCrewMembers = (evt:any, size: string) => {
    // let cm = remove (values.production_details.day[tabNumber].crew_package[crewIndex], (value, key) => key != "crew_member_4");
    // console.log("Medium Selected Crews: ", cm);

    // console.log(`Workers Member Crews ${crewIndex}: `, values.production_details.day[tabNumber].crew_package[crewIndex]);

    if (size == "medium") {
      // delete values.production_details.day[tabNumber].crew_package[crewIndex]["crew_member_4"]
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_1`, "Videographer"
      );
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_2`, "Videographer"
      );
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_3`, "Project Manager"
      );
    } else if (size == "large") {
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package[${crewIndex}].crew_member_1`, "Videographer"
      );
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_2`, "Videographer"
      );
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_3`, "Creative Director"
      )
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_4`, "Project Manager"
      )
    } else {
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_1`,
      );
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_2`,
      );
      setFieldValue(
        `production_details.day[${tabNumber}].crew_package.${crewIndex}.crew_member_3`,
      );
    }
  }

  const dayTimeSlots = () => {
    const timeSlots = [];
    const startTime = moment().startOf("day");
    const endTime = moment().endOf("day");

    while (startTime.isBefore(endTime)) {
      timeSlots.push(startTime.format("hh:mm A"));
      startTime.add(15, "minutes");
    }

    return timeSlots;
  };

  const timeSlots = useMemo(dayTimeSlots, []);

  const departureTimeSlots = () => {
    const deptimeSlots = [];
    const startTime = moment(selectedArrival, "hh:mm A").add(15, "minutes");
    const endTime = moment().endOf("day");
    while (startTime.isBefore(endTime)) {
      deptimeSlots.push(startTime.format("hh:mm A"));
      startTime.add(15, "minutes");
    }

    return deptimeSlots;
  };

  const deptimeSlots = useMemo(() => departureTimeSlots(), [selectedArrival]);

  const handleApplyDetails = () => {
    if (applyDetails) {
      setNextCrewDetails(false);
      setApplyDetails(false);
    } else {
      setNextCrewDetails();
      setApplyDetails(true);
    }
  }

  const setNextCrewDetails = (set=true) => {
    // const shot_with_this_package = crew_value[crewIndex]?.shot_with_this_package;
    // const size = crew_value[crewIndex]?.size
    // const location = crew_value[crewIndex].location;
    // const arrival_time = crew_value[crewIndex]?.arrival_time;
    // const departure_time = crew_value[crewIndex]?.departure_time;
    // const setup_time = crew_value[crewIndex]?.setup_time;
    const next_index = crewIndex+1;
    if (set) {
      setFieldValue(`${crew_form}[${next_index}]`, crew_value[crewIndex]);
    } else {
      setFieldValue(`${crew_form}[${next_index}].shot_with_this_package`, '');
      setFieldValue(`${crew_form}[${next_index}].size`, '');
      setFieldValue(`${crew_form}[${next_index}].location`, '');
      setFieldValue(`${crew_form}[${next_index}].arrival_time`, '');
      setFieldValue(`${crew_form}[${next_index}].departure_time`, '');
      setFieldValue(`${crew_form}[${next_index}].setup_time`, '15');
    }
  }

  const handleLocationchange = (val:string) => {
    const total_rentals = values?.rentals?.rental_days_needed;
    const filming_date = values.production_details?.day[tabNumber]?.filming_date;
    const rental = find (values?.rentals?.days, (elem) => elem?.crew_package?.tab == tabNumber && elem?.crew_package?.index == crewIndex);
    console.log("Handle Location - Rental Found: ", rental);

    if (val.toLowerCase() === "chandler studio" && isNil(rental)) {
      console.log("Location Value Selected: ", val);
      const rental_index:Number = total_rentals < 1? 0 : total_rentals;
      const populate_data = {
        location: val,
        date_of_shoot: filming_date,
        rental_start_time: crew_value[crewIndex].arrival_time,
        rental_end_time: crew_value[crewIndex].departure_time,
        crew_package: {
          tab: tabNumber,
          index: crewIndex
        }
      }
      setFieldValue("rentals.rental_days_needed", total_rentals + 1)
      values?.rentals.days.splice(tabNumber, 0, populate_data);
      // setFieldValue(`rentals.days`, rental_days)

      // console.log("New Rentals - Total: ", values?.rentals.rental_days_needed);
      // console.log("New Rentals: ", values?.rentals)

      setRentalAutoPolulateIndex(rental_index);
    } else if(!isNil(rental)) {
      // console.log("Remove Rental - Day Index: ", rentalAutoPolulateIndex);
      const res = remove(values?.rentals?.days, (elem:any) => elem?.crew_package?.tab == tabNumber && elem?.crew_package?.index == crewIndex);
      values.rentals.rental_days_needed = values?.rentals?.days?.length;
      console.log("Handle Location - Remove Rental :", res);
      setRentalAutoPolulateIndex(NaN);
    }
  }

  return (
    <>
      {crew_value && crew_value[crewIndex] && (
        <div className="row">
          <div className="col-12">
            <FormControl
              variant="standard"
              sx={{ mb: 1 }}
              size="small"
              fullWidth
            >
              <TextField
                name={`${crew_form}[${crewIndex}].shot_with_this_package`}
                value={crew_value[crewIndex]?.shot_with_this_package}
                variant="outlined"
                label="What is being shot with this package?"
                onChange={handleChange}
                margin="dense"
                size="small"
                multiline
                rows={4}
                helperText={
                  errors_production &&
                  (errors?.production_details?.day[tabNumber]?.crew_package[
                    crewIndex
                  ]?.shot_with_this_package ||
                    touched?.production_details?.day[tabNumber]?.crew_package[
                      crewIndex
                    ]?.shot_with_this_package) &&
                  errors?.production_details?.day[tabNumber]?.crew_package[
                    crewIndex
                  ]?.shot_with_this_package
                }
                
              
                InputLabelProps={{
                    shrink: true
                }}
              />
              <span className="fs-12 mb-3">Please use a minimum of 50 characters.</span>
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl
              variant="outlined"
              sx={{ mb: 1, mt: 1.3 }}
              size="small"
              fullWidth
            >
              <InputLabel id="role">Crew Size</InputLabel>
              <Select
                labelId="crew"
                id="crew"
                name={`${crew_form}[${crewIndex}].size`}
                value={crew_value[crewIndex]?.size}
                onChange={updateCrewSize}
                label="Crew Size"
              >
                {CREW_SIZE.map((u: any) => (
                  <MenuItem value={u.value} key={u.value}>
                    {u.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.production_details && (
                <FormHelperText>
                  {((errors?.production_details?.day[tabNumber]?.crew_package &&
                    errors?.production_details?.day[tabNumber]?.crew_package[
                      crewIndex
                    ]?.size) ||
                    (touched?.production_details?.day[tabNumber]
                      ?.crew_package &&
                      touched?.production_details?.day[tabNumber]?.crew_package[
                        crewIndex
                      ]?.size)) &&
                    errors?.production_details?.day[tabNumber]?.crew_package[
                      crewIndex
                    ]?.size}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-sm-6">
            <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
              <GeoLocation
                name={`${crew_form}[${crewIndex}].location`}
                value={crew_value[crewIndex].location}
                formikProps={formikProps}
                onChange={handleLocationchange}
              />
              {errors?.production_details && (
                <FormHelperText>
                  {((errors?.production_details?.day[tabNumber]?.crew_package &&
                    errors?.production_details?.day[tabNumber]?.crew_package[
                      crewIndex
                    ]?.location) ||
                    (touched?.production_details?.day[tabNumber]
                      ?.crew_package &&
                      touched?.production_details?.day[tabNumber]?.crew_package[
                        crewIndex
                      ]?.location)) &&
                    errors?.production_details?.day[tabNumber]?.crew_package[
                      crewIndex
                    ]?.location}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
              <Autocomplete
                id={`arrival-time-autocomplete-${crewIndex}`}
                onOpen={() => {
                  setTimeout(() => {
                    const optionEl = document.querySelector(
                      `[data-name="10:00 AM"]`
                    );
                    optionEl?.scrollIntoView();
                  }, 1);
                }}
                options={timeSlots}
                getOptionLabel={(option) => option}
                value={crew_value[crewIndex]?.arrival_time}
                onChange={(_, newValue) => {
                  setSelectedArrival(newValue);
                  handleChange({
                    target: {
                      name: `${crew_form}[${crewIndex}].arrival_time`,
                      value: newValue,
                    },
                  });
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} data-name={option}>
                      {option}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location Arrival Time"
                    variant="outlined"
                    margin="dense"
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value}
                autoHighlight
              />
              {errors?.production_details && (
                <FormHelperText>
                  {((errors?.production_details?.day[tabNumber]?.crew_package &&
                    errors?.production_details?.day[tabNumber]?.crew_package[
                      crewIndex
                    ]?.arrival_time) ||
                    (touched?.production_details?.day[tabNumber]
                      ?.crew_package &&
                      touched?.production_details?.day[tabNumber]?.crew_package[
                        crewIndex
                      ]?.arrival_time)) &&
                    errors?.production_details?.day[tabNumber]?.crew_package[
                      crewIndex
                    ]?.arrival_time}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
              <Autocomplete
                id={`departure-time-autocomplete-${crewIndex}`}
                options={deptimeSlots}
                getOptionLabel={(option) => option}
                value={crew_value[crewIndex]?.departure_time}
                onChange={(_, newValue) => {
                  handleChange({
                    target: {
                      name: `${crew_form}[${crewIndex}].departure_time`,
                      value: newValue,
                    },
                  }); //
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location Departure Time"
                    variant="outlined"
                    margin="dense"
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value}
                autoHighlight
              />
              {errors?.production_details && (
                <FormHelperText>
                  {(errors?.production_details?.day[tabNumber]?.crew_package &&
                    errors?.production_details?.day[tabNumber]?.crew_package[
                      crewIndex
                    ]?.departure_time) ||
                    (touched?.production_details?.day[tabNumber]
                      ?.crew_package &&
                      touched?.production_details?.day[tabNumber]?.crew_package[
                        crewIndex
                      ]?.departure_time &&
                      errors?.production_details?.day[tabNumber]?.crew_package[
                        crewIndex
                      ]?.departure_time)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl
              variant="outlined"
              sx={{ mb: 1, mt: 1.3 }}
              size="small"
              fullWidth
            >
              <InputLabel id="setup-time-label">Setup Time</InputLabel>
              <Select
                labelId="setup-time"
                id="setup-time"
                name={`${crew_form}[${crewIndex}].setup_time`}
                value={crew_value[crewIndex]?.setup_time}
                label="Setup Time"
                onChange={handleChange}
              >
                <MenuItem value={15}>15 Minute</MenuItem>
                <MenuItem value={30}>30 Minute</MenuItem>
                <MenuItem value={60}>1 Hour</MenuItem>
              </Select>
            </FormControl>
          </div>
          <RenderCrewMembers
            crewForm={crew_form}
            crewSize={crew_value[crewIndex]?.size}
            crewIndex={crewIndex}
            crewValue={crew_value}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors_production}
            key={`dp-dx-${crewIndex}`}
          />
          {crewIndex+1 < crew_package_needed && (<div className="col-6">
            <FormControlLabel
              // className="mt-4"
              control={
                <Checkbox
                  id="new_client"
                  // name='client_details.new_client'
                  onChange={handleApplyDetails}
                  checked={applyDetails}
                  size="small"
                />
              }
              label="Apply package details to the next package"
            />
          </div>)}
        </div>
      )}
    </>
  );
}

function RenderCrewMembers({
  crewIndex,
  crewForm,
  crewValue,
  crewSize,
  setFieldValue,
  onChange,
  errors,
}: any) {

  // useEffect(() => {
  //   setSizeField();
  // }, [crewSize]);

  const defaultOptions = (newValues: string[] = []) => {
    const options = ["Videographer", "Photographer", "Drone Operator"];
    return [...options, ...newValues];
  };

  const dropdowns = {
    solo: [{ options: defaultOptions() }],
    small: [{ options: defaultOptions() }],
    // medium: [{ options: defaultOptions() }, { options: defaultOptions() }],
    // large: [
    //   { options: defaultOptions() },
    //   { options: defaultOptions() },
    //   { options: ["Production Assistant", "Creative Director"] },
    // ],
  } as any;

  const setSizeField = () => {
    if (crewSize == "medium") {
      setFieldValue(`${crewForm}[${crewIndex}].crew_member_1`, "Videographer");
      setFieldValue(`${crewForm}[${crewIndex}].crew_member_2`, "Videographer");
    } else if (crewSize == "large") {
      setFieldValue(`${crewForm}[${crewIndex}].crew_member_1`, "Videographer");
      setFieldValue(`${crewForm}[${crewIndex}].crew_member_2`, "Videographer");
      setFieldValue(`${crewForm}[${crewIndex}].crew_member_3`, "Creative Director");
    }
  }

  return (
    <>
      {dropdowns?.[crewSize]?.map((field: any, index: number) => {
        index++;
        return (
          <div className="col-6" key={`crewMember${index}`}>
            <FormControl
              variant="outlined"
              sx={{ mb: 1, mt: 1.3 }}
              size="small"
              fullWidth
            >
              <InputLabel id="role">Crew Member #{index}</InputLabel>
              <Select
                labelId="crew"
                id="crew"
                name={`${crewForm}[${crewIndex}].crew_member_${index}`}
                key={`${crewForm}[${crewIndex}].crew_member_${Math.random()}`}
                value={crewValue[crewIndex]?.[`crew_member_${index}`]}
                onChange={onChange}
                label={`Crew Member #${index}`}
              >
                {field.options.map((u: any) => (
                  <MenuItem value={u} key={u}>
                    {u}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors?.[`crew_member_${index}`] && (
              <FormHelperText>{errors[`crew_member_${index}`]}</FormHelperText>
            )}
          </div>
        );
      })}
    </>
  );
}

export default CrewDetails;
