import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { APIMETHOD } from "../../core/constant";
import { useAxios } from "../../core/useAxios";
import { getUserInfo, TABS_NUM } from "../../utils/utils";
import { clientList, ClientOptionType, NUMBER_10, _days } from "./modal";
import { useAppStateContext } from "../../state/provider";
import { updatePreviousProjectName } from "../../state/form/action";



interface ClientProps {
  formikProps: any;
}
// interface ToastOptions {
//   severity: string;
//   message: string;
//   duration?: number;
// }
function ClientDetails(props: ClientProps) {
  const { get } = useAxios();
  const userInfo = getUserInfo();
  const {
    handleChange,
    client_details,
    setFieldValue,
    values,
    project_name,
    errors,
    touched,
    getFieldValue,
  } = props.formikProps;
  const [clientName, setClientName] = React.useState<any>("");
  const [clientList, setClients] = React.useState<any>([]);
  const [clientOptions, setClientOptions] = React.useState<any>([]);
  const [value, setValue] = React.useState<ClientOptionType | null | string>(
    null
  );
  const { id, clientId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const clientDetails = values?.client_details;
  const defaultProps = {
    options: clientOptions,
    // getOptionLabel: (option: ClientOptionType) => option.name !== '--ADD NEW--' ?
    //   `${option.name}, ${option.email}, ${option.phone}` : `${option.name}`
  };

  const { state, dispatch } = useAppStateContext();

  useEffect(() => {
    const getClients = async () => {
      const result = await get({
        url: APIMETHOD.INVOICE.CLIENTS[userInfo.role],
      });
      if (result) {
        const addNewClients = { name: "--ADD NEW--", email: "", phone: "" };
        let r = [];
        setClients(result);
        r = result.map((r: any) => r.name);
        setClientOptions(r);
        defaultProps.options = r;
        result.forEach((client: any) => {
          if (client.id == clientId) {
            console.log("Result Client Id: ", client.id);
            setValue(client);
            setFieldValue("client_details.name", client?.name);
            setFieldValue("client_details.email", client?.email);
            setFieldValue("client_details.phone", client?.phone);
            setFieldValue("client_details.id", client?.id);
            setFieldValue("client_details.new_client", client?.new_client);
          }
        });
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    setFieldValue(
      "invoice_type",
      searchParams.has("type") ? searchParams.get("type") : "all"
    );
  }, [searchParams]);

  useEffect(() => {
    setClientName(clientDetails.name);
  }, [clientDetails]);

  const setSelectedClient = (name: String) => {
    if (!!clientList) {
      const client = clientList.find((elem: any) => elem.name == name);

      if (!!client) {
        setFieldValue("client_details.name", client?.name);
        setFieldValue("client_details.email", client?.email);
        setFieldValue("client_details.phone", client?.phone);
        setFieldValue("client_details.id", client?.id);
        setFieldValue("client_details.new_client", client?.new_client);
      } else {
        setFieldValue("client_details.name", "");
        setFieldValue("client_details.email", "");
        setFieldValue("client_details.phone", "");
        setFieldValue("client_details.id", "");
        setFieldValue("client_details.new_client", false);
      }
    }
  };

  const handleChangeFilmingDays = (e: any) => {
    const filming_days = Number(e?.target?.value);
    let data: any = values?.production_details?.day;
    const remaining_days = filming_days - data?.length;
    if (remaining_days > 0) {
      TABS_NUM(remaining_days || 0).forEach(() => data.push(_days));

      setFieldValue("production_details.day", data);
    }
    if (filming_days < data?.length && data.length > 0) {
      const updated_day = data.slice(0, filming_days);
      setFieldValue(`production_details.day`, updated_day);
    }
    setFieldValue("client_details.filming_days_num", Number(filming_days));
  };

  // Store previous project name in context on form field focus and 
  // use it to update the edit description field on tab navigation 
  // in the post production section of the invoice form.
  const handleProjectNameFocus = (evt:any) => {
    const projectName = values?.client_details?.project_name;
    console.log("Previous Project Name: ", projectName)
    // update the previousProjectName state in the AppContext
    dispatch(updatePreviousProjectName(projectName))
  }
  
  return (
    <div className="row">
      <div className="col-sm-3">
        <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>

          <Autocomplete
            {...defaultProps}
            id="_clients"
            freeSolo
            disabled={id && clientId !== "clone" ? true : false}
            value={clientName}
            // value={values.client_details.name}
            onChange={(event: any) => {
              console.log("Change Event: ", event.target.outerText);
              setSelectedClient(event.target.outerText);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="client_details.name"
                label="Client Name"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  type: "search",
                }}
                onChange={handleChange}
                margin="dense"
                size="small"
              />
            )}
          />
        </FormControl>
      </div>
      <div className="col-sm-3">
        <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
          <TextField
            name="client_details.email"
            type={"email"}
            variant="outlined"
            label="Client Email"
            onChange={handleChange}
            value={values.client_details.email}
            margin="dense"
            size="small"
            disabled={id && clientId !== "clone" ? true : false}
            helperText={
              errors?.client_details?.email &&
              touched?.client_details?.email &&
              errors?.client_details?.email
            }
          />
        </FormControl>
      </div>
      <div className="col-sm-3">
        <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
          <TextField
            name="client_details.phone"
            type={"text"}
            variant="outlined"
            label="Client Phone No."
            onChange={handleChange}
            value={values.client_details.phone}
            margin="dense"
            size="small"
            disabled={id && clientId !== "clone" ? true : false}
            helperText={
              errors?.client_details?.phone &&
              touched?.client_details?.phone &&
              errors?.client_details?.phone
            }
          />
        </FormControl>
      </div>
      <div className="col-sm-3">
        <FormControlLabel
          className="mt-4"
          control={
            <Checkbox
              id="new_client"
              name="client_details.new_client"
              onChange={handleChange}
              checked={values?.client_details?.new_client}
              size="small"
            />
          }
          label="Discount?"
        />
      </div>
      <div className="col-sm-12">
        <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
          <TextField
            name="client_details.project_name"
            type={"text"}
            variant="outlined"
            label="Project Name"
            onChange={handleChange}
            onFocus={handleProjectNameFocus}
            value={values?.client_details?.project_name}
            margin="dense"
            size="small"
            helperText={
              errors?.client_details?.project_name &&
              touched?.client_details?.project_name &&
              errors?.client_details?.project_name
            }
          />
        </FormControl>
      </div>
    </div>
  );
}

export default ClientDetails;
