import { Box, Button, Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import "./styles.scss";
import invoiceImg from "../../assets/images/invoice_header.png";
import quoteImg from "../../assets/images/quote_header.png";
import { useAxios } from "../../core/useAxios";
import { APIMETHOD } from "../../core/constant";
import { getToken, getUserInfo } from "../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import LoadingService from "../../shared/loader";
import { toast } from "../../shared/Toser/ToastManager";
import { style } from "./AddClients";
import SendEmail from "./SendEmail";
import { FORMAT_SLASH_MM_DD_YYYY } from "../../utils/dateformat";
import { CrewDetailsText } from "./data";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Invoice from "./Invoice";
import ProductionTable from "./components/ProductionTable";
import { getCrewSizeBasedOnCrewMember, getCrewSizes } from "../../utils/utils";
import UpdatePaymentSchedule from "./components/UpdatePaymentSchedule";
import SetUpcharge from "./SetUpcharge";
import DiscountForm from "./Discount";
import PaymentScheduleGenerateForm from "./PaymentScheduleGenerate";
import ToggleCrewSizeDescription from "./ToggleCrewSizeDescription";
import { Translate } from "@mui/icons-material";
import PostProductionItems from "./components/PostProductionItems";

let isSendEmail = false;

function GenerateInvoice(props: any) {
  const { get, error, loading, post, put } = useAxios({ showLoader: true });
  const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openPaymentScheduleUpdateForm, setOpenPaymentScheduleUpdateForm] =
    useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userInfo = getUserInfo();
  const { id, clientId } = useParams();
  const navigate = useNavigate();

  const checkoutLink =
    invoiceDetails?.payment_link ||
    `${APIMETHOD.CHECKOUT.INVOICE}?invoice_id=${id}`;

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      toast.show({ severity: "error", message: error.message });
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      const getInvoiceDetails = async () => {
        const response: any = await get({
          url: APIMETHOD.INVOICE.CREATE_INVOICE[userInfo?.role] + id + "/",
        });
        if (response) {
          setInvoiceDetails(response);
        }
      };
      getInvoiceDetails();
    }
  }, [id]);

  const downloadPdfDocument = async () => {
    setIsLoading(true);
    let pdf_save = false;
    // Generate the PDF content as a blob
    const pdfBlob = await pdf(
      <Invoice invoiceDetails={invoiceDetails} id={id} />
    ).toBlob();

    if (!invoiceDetails?.invoice_pdf_info?.invoice_pdf_url) {
      await uploadPdf(pdfBlob);
    } else {
      // const prefix = isQuote() ? "Quote-" : "Proposal-";
      // // Save the blob as a file
      // await saveAs(pdfBlob, `${prefix}${invoiceDetails?.project?.name}.pdf`);
      savePDFAs(pdfBlob);
    }

    makeInvoiceAsSent();

    setIsLoading(false);
  };

  const makeInvoiceAsSent = async () => {
    try {
      const res = await put({
        url: APIMETHOD.INVOICE.MARK_AS_SENT+id,
        params: {mark: true}
      })
  
      console.log("Mark Invoice as sent: ", res);
    } catch (error) {
      console.error(error)
    }
  }

  const uploadPdf = async (data: any) => {
    const formData: any = new FormData();
    const token = getToken();
    const fileName = invoiceDetails?.invoice_pdf_info?.invoice_number + ".pdf";
    let file: any = new File([data], fileName, { type: "application/pdf" });
    formData.append("pdf_file", file, fileName);
    const response = await post(
      {
        url:
          APIMETHOD.INVOICE.CREATE_INVOICE[userInfo?.role] +
          id +
          "/upload-pdf/",
        data: formData,
      },
      "blob"
    );
    if (response) {
      if (isSendEmail) {
        setOpenDialog(true);
        isSendEmail = false;
      } else {
        // const prefix = isQuote() ? "Quote" : "Proposal";
        // saveAs(data, `${prefix}-${invoiceDetails?.project?.name}.pdf`);
        savePDFAs(data);
      }
      setIsLoading(false);
    }
  };

  const savePDFAs = (data:any) => {
    const prefix = isQuote() ? "Quote" : "Proposal";
    saveAs(data, `${prefix}-${invoiceDetails?.project?.name}.pdf`);
  }

  const emailInvoice = () => {
    if (!invoiceDetails?.invoice_pdf_info?.invoice_pdf_url) {
      isSendEmail = true;
      downloadPdfDocument();
    } else {
      setOpenDialog(true);
      isSendEmail = false;
    }
  };

  const cloneInvoice = () => {
    <Invoice invoiceDetails={invoiceDetails} id={id} />;
    navigate("/invoice/" + id + "/clone?type="+ invoiceDetails?.invoice_type);
  };

  const isSizeInclude = (_invoiceDetails: any, sizes: string = ""): boolean => {
    let crewSizes: any =
      _invoiceDetails?.invoice?.production?.selected_crew_sizes;
    // let crewSizes: any = getCrewSizes(_invoiceDetails);

    if (crewSizes && crewSizes[sizes?.toLowerCase()]) {
      return crewSizes[sizes?.toLowerCase()];
    }
    // if (crewSizes.includes(sizes.toLowerCase())) {
    //   return true;
    // }
    return false;
  };

  const getSumofAllStage = (invoiceCost: any) => {
    if (invoiceCost) {
      const totalPrice =
        (invoiceDetails?.invoice?.production?.production_sub_total_cost || 0) +
        (invoiceDetails?.invoice?.pre_production
          ?.pre_production_sub_total_cost || 0) +
        (invoiceDetails?.invoice?.talents?.talents_sub_total_cost || 0) +
        (invoiceDetails?.invoice?.rentals?.rentals_sub_total_cost || 0) +
        (invoiceDetails?.invoice?.custom?.custom_sub_total_cost || 0) +
        (invoiceDetails?.invoice?.post_production
          ?.post_production_sub_total_cost || 0) -
        (invoiceDetails?.invoice_pdf_info?.invoice_discount || 0);
      return Math.round((totalPrice + Number.EPSILON) * 100) / 100;
    }
    return 0;
  };

  const onInvoiceEdit = () => {
    navigate(
      `/invoice/${id}/${invoiceDetails?.client?.name}?type=${invoiceDetails.invoice_type}`
    );
  };

  const invoiceDiscountForm = () => {
    const discount = invoiceDetails?.invoice.discount;
    const invoice_type = invoiceDetails?.invoice_type;

    if (invoice_type == "all") {
      return (
        <DiscountForm
          invoiceDetails={invoiceDetails}
        />
      );
    }
  }

  const payamentScheduleGenerate = () => {
    return (
      <PaymentScheduleGenerateForm />
    );
  }

  const CrewSizeDescriptionToggle = () => {
    return (
      <ToggleCrewSizeDescription invoiceDetails={invoiceDetails} />
    );
  }

  const isQuote = () => invoiceDetails?.is_quote;

  return (
    <div className="container">
      <LoadingService open={isLoading} />
      <div className="row">
        <div
          className="col-sm-3 mt-3"
          style={{
            position: "sticky",
            top: "0",
            display: openDialog ? "none" : "block",
          }}
        >
          <Grid xs={3} className="mt-5">
            <div className="mt-5 position-fixed"  style={{ width:"23%", position: 'sticky', top: 50, transform: 'translateY(25px)' }}>
              <SetUpcharge
                upchargeDetails={{
                  invoice_id: id,
                  upCharge: invoiceDetails?.up_charge,
                }}
                key={id}
              />

              {invoiceDiscountForm()}
              {payamentScheduleGenerate()}
              {CrewSizeDescriptionToggle()}
            </div>
          </Grid>
        </div>

        {/* xs={!openDialog ? 8 : 4} */}
        <div className={"col-sm-" + (!openDialog ? "8" : "7")}>
          <Grid xs={!openDialog ? 8 : 4}>
            <div className="row justify-content-center px-3">
              <Box sx={{ my: 2, textAlign: "center" }}>
                <h5>{isQuote() ? "Quote" : "Proposal Invoice"}</h5>
              </Box>
              <div className="pdf-page-1" id="generate_pdf">
                <div className="invoice-main-container">
                  <div className="row banner-img">
                    <img src={isQuote()? quoteImg : invoiceImg} width="99%" />
                  </div>

                  <div
                    className="row background-3 px-3 pt-4"
                    style={{ width: "102.3%", marginRight: "10vmax" }}
                  >
                    <div className="col-sm-4">
                      <div className="invoice-header-label">{isQuote() ? "QUOTE" : "INVOICE"} TO</div>
                      <Box sx={{ fontWeight: 600 }}>
                        <b>{invoiceDetails?.client?.name}</b>
                      </Box>
                      <Box sx={{}}>
                        {invoiceDetails?.client?.email} <br />
                        {invoiceDetails?.client?.phone}
                      </Box>
                    </div>
                    <div className="col-sm-3">
                      <Box sx={{ fontWeight: 600, textAlign: "center" }}>
                        {isQuote() ? "Quote" : "Invoice"} Address
                      </Box>
                      <p className="text-center">
                        1000 South Park Lane Suite 1 Tempe Arizona, 85281
                      </p>
                    </div>
                    <div className="col-sm-5 text-end">
                      <Box sx={{}}>
                        No. {invoiceDetails?.invoice_pdf_info?.invoice_number}
                      </Box>
                      <Box sx={{ fontWeight: 600 }}>
                        {isQuote() ? "Quote" : "Invoice"} Creation Date :{" "}
                        {FORMAT_SLASH_MM_DD_YYYY(
                          invoiceDetails?.invoice_pdf_info?.invoice_date
                        )}
                      </Box>
                    </div>
                  </div>

                  <div className="row p-3 background-2">
                    <div className="col-sm-6">
                      <div className="invoice-header-label">PROJECT</div>
                      <div className="invoice-header-bold">
                        {invoiceDetails?.project?.name}
                      </div>
                    </div>
                    {/* <div className='col-sm-6 text-end'>
                    <div className='invoice-header-label'>TOTAL FILMING/PHOTO DAYS</div>
                    <div className='invoice-header-bold'>{invoiceDetails?.project?.filming_days}</div>
                  </div> */}
                  </div>
                  {/* PRE - PRODUCTION TABLE */}
                  {invoiceDetails?.invoice?.pre_production &&
                    invoiceDetails?.invoice?.pre_production?.line_items &&
                    JSON.stringify(
                      invoiceDetails?.invoice?.pre_production?.line_items || {}
                    ) !== "{}" && (
                      <div className="row py-3 background-3 px-2">
                        <div className="col-12">
                          <div className="invoice_sub_header mb-2">
                            Project Planning
                          </div>

                          <div className="row details-text">
                            <p>{CrewDetailsText.PROJECT_PLANNING}</p>
                            <p>Any meetings between the client and the Voyage Project Manager will be considered as project planning hours</p>
                          </div>

                          <table className="table" style={{ width: "100%" }}>
                            <thead className="table-dark">
                              <tr>
                                <th>ITEM</th>
                                <th className="text-right">QTY</th>
                                <th>UNIT</th>
                                <th className="text-right">TOTAL COST</th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoiceDetails?.invoice &&
                                Object.keys(
                                  invoiceDetails?.invoice?.pre_production
                                    ?.line_items
                                )?.map((item: any, index: number) => {
                                  return (
                                    <tr key={index + "item"}>
                                      <td width="250px">
                                        {
                                          invoiceDetails?.invoice
                                            ?.pre_production?.line_items[item]
                                            ?.description
                                        }
                                      </td>
                                      {invoiceDetails?.invoice?.pre_production
                                        ?.line_items[item].selected === 2 ? (
                                        <>
                                          <td className="text-right">No</td>
                                        </>
                                      ) : (
                                        <td className="text-right">
                                          {
                                            invoiceDetails?.invoice
                                              ?.pre_production?.line_items[item]
                                              ?.hours
                                          }
                                        </td>
                                      )}
                                      <td>hours</td>

                                      <td className="text-right">
                                        $
                                        {
                                          invoiceDetails?.invoice
                                            ?.pre_production?.line_items[item]
                                            ?.total
                                        }
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                  {invoiceDetails?.invoice?.production &&
                    invoiceDetails?.invoice?.production?.no_of_filming_days && (
                      <div className="row py-3 background-3 px-2">
                        <div className="col-12">
                          <div className="invoice_sub_header mb-2">
                            Production
                          </div>
                          <ProductionTable invoiceDetails={invoiceDetails} />
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className="row justify-content-center p-0 pdf-page-2">
                {/* <div className={`${ openDialog ? "col-sm-12" : "col-sm-6"} invoice-main-container`}> */}
                <div className="invoice-main-container">
                  {/* POST - PRODUCTION TABLE */}
                  {invoiceDetails?.invoice?.post_production &&
                    invoiceDetails?.invoice?.post_production?.line_items && (
                      <div className="row py-2 background-3 px-2">
                        <div className="col-12">
                          <div className="invoice_sub_header mb-3">
                            Post-Production
                          </div>
                          <div className="row details-text">
                            <p>
                              {CrewDetailsText.POST_PRODUCTION} {" "}
                              {invoiceDetails?.invoice?.post_production?.has_revision && (
                                <span className="bg-orange fw-bold">We have added time for one round of moderate revisions. Please note that a significant number of revisions may require additional payment</span>
                              )}
                            </p>
                            <p>Any meetings regarding edit are part of the post production time</p>
                          </div>
                          <div>
                            <PostProductionItems 
                              invoiceDetails={invoiceDetails}
                            />
                          </div>
                          {/* <table className="table" style={{ width: "100%" }}>
                            <thead className="table-dark">
                              <tr>
                                <th colSpan={1} className="p-2">
                                  Requirements
                                </th>
                                <th colSpan={2} className="p-2">
                                  Details
                                </th>
                                <th
                                  colSpan={2}
                                  className="p-2"
                                  style={{ textAlign: "right" }}
                                >
                                  Total Cost
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoiceDetails &&
                                invoiceDetails?.invoice &&
                                Object.keys(
                                  invoiceDetails?.invoice?.post_production
                                    ?.line_items
                                )?.map((item: any) => {
                                  const is_raw_footage_delivery = invoiceDetails?.invoice
                                    ?.post_production?.line_items[
                                    item
                                  ]?.is_raw_footage_delivery;

                                  return (
                                    <>
                                      <tr>
                                        <td width={400}>
                                          <span className="fw-bold">
                                            {invoiceDetails?.project?.name} {" "}
                                            {
                                              invoiceDetails?.invoice
                                                ?.post_production?.line_items[
                                                item
                                              ]?.title
                                            }
                                          </span>
                                          <div>
                                            {
                                              invoiceDetails?.invoice
                                                ?.post_production?.line_items[
                                                item
                                              ]?.description
                                            }
                                          </div>
                                        </td>
                                        <td colSpan={2}>
                                          {invoiceDetails?.invoice
                                            ?.post_production?.line_items[item]
                                            ?.type === "video" && (
                                            <p
                                              className="mb-1"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              Aspect Ratio:
                                            </p>
                                          )}
                                          {invoiceDetails?.invoice
                                            ?.post_production?.line_items[item]
                                            ?.type === "photo" && (
                                            <p
                                              className="mb-1"
                                              style={{
                                                textTransform: "capitalize",
                                                // backgroundColor: "#FFA600",
                                              }}
                                            >
                                              <span className="bg-yellow px-1 rounded">
                                                Number of photos to be edited:
                                              </span>
                                            </p>
                                          )}
                                          {invoiceDetails?.invoice
                                            ?.post_production?.line_items[item]
                                            ?.type === "video" && (
                                            <p
                                              className="mb-1"
                                              style={{
                                                textTransform: "capitalize",
                                                // backgroundColor: "#FFA600",
                                              }}
                                            >
                                              <span className="bg-yellow px-1 rounded">
                                                Estimated final run time:
                                              </span>
                                            </p>
                                          )}
                                          <p
                                            className="mb-1"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Estimated hours needed for initial
                                            delivery
                                          </p>
                                          <p
                                            className="mb-1"
                                            style={{
                                              textTransform: "capitalize",
                                              // backgroundColor: "#FFA600",
                                            }}
                                          >
                                            <span className="bg-yellow px-1 rounded">
                                              Deadline of initial delivery of{" "}
                                              {
                                                invoiceDetails?.invoice
                                                  ?.post_production?.line_items[
                                                  item
                                                ]?.type
                                              }{" "}
                                              Edit
                                            </span>
                                          </p>
                                        </td>
                                        <td>
                                          {invoiceDetails?.invoice
                                            ?.post_production?.line_items[item]
                                            ?.type === "video" && (
                                            <p
                                              className="mb-1 text-right"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {
                                                invoiceDetails?.invoice
                                                  ?.post_production?.line_items[
                                                  item
                                                ]?.aspect_ratio
                                              }
                                            </p>
                                          )}
                                          {invoiceDetails?.invoice
                                            ?.post_production?.line_items[item]
                                            ?.type === "photo" && (
                                            <p
                                              className="mb-1 text-right"
                                              style={{
                                                textTransform: "capitalize",
                                                // backgroundColor: "#FFA600",
                                              }}
                                            >
                                              <span className="bg-yellow px-1 rounded">
                                                {
                                                  invoiceDetails?.invoice
                                                    ?.post_production
                                                    ?.line_items[item]
                                                    ?.no_of_photos_edit
                                                }
                                              </span>
                                            </p>
                                          )}
                                          {invoiceDetails?.invoice
                                            ?.post_production?.line_items[item]
                                            ?.type === "video" && (
                                            <p
                                              className="mb-1 text-right"
                                              style={{
                                                textTransform: "capitalize",
                                                // backgroundColor: "#FFA600",
                                              }}
                                            >
                                              <span className="bg-yellow px-1 rounded">
                                                {
                                                  invoiceDetails?.invoice
                                                    ?.post_production
                                                    ?.line_items[item]
                                                    ?.final_run_time_of_intro
                                                }{" "}
                                                Minute
                                              </span>
                                            </p>
                                          )}
                                          <p
                                            className="mb-1 text-right"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {
                                              invoiceDetails?.invoice
                                                ?.post_production?.line_items[
                                                item
                                              ]
                                                ?.hours_needed_for_initial_delivery
                                            }{" "}
                                            Hour
                                          </p>
                                          <p
                                            className="mb-1 text-right"
                                            // style={{ backgroundColor: "#FFA600" }}
                                          >
                                            <span className="bg-yellow px-1 rounded">
                                              {FORMAT_SLASH_MM_DD_YYYY(
                                                invoiceDetails?.invoice
                                                  ?.post_production?.line_items[
                                                  item
                                                ]?.deadline_of_initial_delivery
                                              )}
                                            </span>
                                          </p>
                                        </td>
                                        <td>
                                          <p className="my-2 text-right">
                                            ${!is_raw_footage_delivery? invoiceDetails?.invoice
                                                ?.post_production?.line_items[
                                                item
                                              ]?.total_cost : 0}
                                          </p>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </table> */}
                        </div>
                      </div>
                    )}

                  {/* talents */}
                  {invoiceDetails?.invoice?.talents &&
                    invoiceDetails?.invoice?.talents?.days && (
                      <div className="row py-3 background-3 px-2">
                        <div className="col-12">
                          <div className="invoice_sub_header mb-2">Talents</div>
                          <div>
                            {invoiceDetails?.invoice?.talents.days.map(
                              (item: any, index: number) => {
                                interface StudioInfo {
                                  rent: number;
                                  address: string;
                                }

                                const studios: Record<string, StudioInfo> = {
                                  "Tempe Studio": {
                                    rent: 69,
                                    address:
                                      "1000 S Park Ln Suite 1, Tempe, AZ 85281",
                                  },
                                  "Chandler Studio": {
                                    rent: 89,
                                    address:
                                      "6100 W Gila Springs Pl 25 Chandler, AZ 85226",
                                  },
                                };

                                let studio: StudioInfo | undefined =
                                  studios[item.location];

                                return (
                                  <>
                                    <div className="row">
                                      <div className="col-sm-2 day_heading">
                                        Day {index + 1}
                                      </div>
                                      <div className="col-sm-2">
                                        <div>Shooting Date</div>
                                        <p
                                          className="font-600 bg-yellow rounded px-2"
                                          style={{ width: "7rem" }}
                                        >
                                          {FORMAT_SLASH_MM_DD_YYYY(
                                            item.date_of_shoot
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-sm-2">
                                        <div>Total Talents</div>
                                        <p
                                          className="font-600 bg-yellow rounded px-2"
                                          style={{ width: "7rem" }}
                                        >
                                          {item.num_of_talents_needed}
                                        </p>
                                      </div>
                                    </div>
                                    <table
                                      className="table"
                                      style={{ width: "100%" }}
                                    >
                                      <thead className="table-dark">
                                        <tr>
                                          <th colSpan={3}>LOCATION</th>
                                          <th>NUMBER OF TALENTS</th>
                                          <th>TALENT ARRIVAL TIME</th>
                                          <th>TALENT DEPARTURE TIME</th>
                                          <th>TALENT HOURS</th>
                                          <th className="text-end">
                                            TOTAL COST
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.packages.map((pkg:any, p_index:number) => {
                                          return (
                                            <tr key={index + "item"}>
                                              <td
                                                // rowSpan={2}
                                                colSpan={3}
                                                width="200px"
                                              >
                                                <div className="bg-yellow px-2 rounded">
                                                  <span className="font-600">
                                                    {pkg.location}
                                                  </span>
                                                  <br />
                                                  <small>{studio?.address}</small>
                                                </div>
                                              </td>

                                              <td>
                                                <div
                                                  className="bg-yellow rounded px-1"
                                                  style={{
                                                    width: "4.8rem",
                                                  }}
                                                >
                                                  { "Talent "+(p_index+1) }
                                                </div>
                                              </td>

                                              <td>
                                                <div
                                                  className="bg-yellow rounded px-1"
                                                  style={{
                                                    width: "4.8rem",
                                                  }}
                                                >
                                                  {moment(
                                                    pkg?.talent_arrival_time,
                                                    "HH:mm:ss"
                                                  ).format("hh:mm A")}
                                                </div>
                                              </td>
                                              <td>
                                                <div
                                                  className="bg-yellow rounded px-1"
                                                  style={{
                                                    width: "4.8rem",
                                                  }}
                                                >
                                                  {moment(
                                                    pkg?.talent_departure_time,
                                                    "HH:mm:ss"
                                                  ).format("hh:mm A")}
                                                </div>
                                              </td>
                                              <td className="text-center">
                                                {pkg.total_package_hours} hours
                                              </td>
                                              <td className="text-end">
                                                ${pkg?.total_package_amount}
                                              </td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                  {/* rentals */}
                  {invoiceDetails?.invoice?.rentals &&
                    invoiceDetails?.invoice?.rentals?.days && (
                      <div className="row py-3 background-3 px-2">
                        <div className="col-12">
                          <div className="invoice_sub_header mb-2">Studios</div>
                          <div>
                            {invoiceDetails?.invoice?.rentals.days.map(
                              (item: any, index: number) => {
                                interface StudioInfo {
                                  title?: string;
                                  rent: number;
                                  address: string;
                                }

                                const studios: Record<string, StudioInfo> = {
                                  "Tempe Studio": {
                                    rent: 69,
                                    address:
                                      "1000 S Park Ln Suite 1, Tempe, AZ 85281",
                                  },
                                  "Chandler Studio": {
                                    rent: 89,
                                    address:
                                      "6100 W Gila Springs Pl 25 Chandler, AZ 85226",
                                  },
                                  "scottsdale_studio": {
                                    title: "Scottsdale Studio",
                                    rent: 75,
                                    address: "",
                                  },
                                };

                                let studio: StudioInfo | undefined =
                                  studios[item.location];

                                return (
                                  <>
                                    <div className="row">
                                      <div className="col-sm-2 day_heading">
                                        Day {index + 1}
                                      </div>
                                      <div className="col-sm-3">
                                        <div>Rental Date</div>
                                        <p
                                          className="font-600 bg-yellow rounded px-2"
                                          style={{ width: "7rem" }}
                                        >
                                          {FORMAT_SLASH_MM_DD_YYYY(
                                            item.date_of_shoot
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <table
                                      className="table"
                                      style={{ width: "100%" }}
                                    >
                                      <thead className="table-dark">
                                        <tr>
                                          <th colSpan={3}>STUDIO NAME</th>
                                          <th>RENTAL START TIME</th>
                                          <th>RENTAL END TIME</th>
                                          <th>RENTAL HOURS</th>
                                          <th className="text-end">
                                            TOTAL COST
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr key={index + "item"}>
                                          <td
                                            rowSpan={2}
                                            colSpan={3}
                                            width="200px"
                                          >
                                            <div className="bg-yellow px-2 rounded">
                                              <span className="font-600">
                                                {studio?.title ?? item.location}
                                              </span>
                                              <br />
                                              <small>{studio?.address}</small>
                                            </div>
                                          </td>

                                          <td>
                                            <div
                                              className="bg-yellow rounded px-1"
                                              style={{
                                                width: "4.8rem",
                                              }}
                                            >
                                              {moment(
                                                item?.rental_start_time,
                                                "HH:mm:ss"
                                              ).format("hh:mm A")}
                                            </div>
                                          </td>
                                          <td>
                                            <div
                                              className="bg-yellow rounded px-1"
                                              style={{
                                                width: "4.8rem",
                                              }}
                                            >
                                              {moment(
                                                item?.rental_end_time,
                                                "HH:mm:ss"
                                              ).format("hh:mm A")}
                                            </div>
                                          </td>
                                          <td className="text-center">
                                            {item.total_hours} hours
                                          </td>
                                          <td className="text-end">
                                            ${item?.total_amount}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                      <div className="pb-2 px-2 mb-3 border-bottom">
                                        <span className="fw-bold">Sales Tax: </span>
                                        { item.sales_tax }
                                      </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  {/* custom */}
                  {invoiceDetails?.invoice?.custom &&
                    invoiceDetails?.invoice?.custom?.number_of_items && (
                      <div className="row py-3 background-3 px-2">
                        <div className="col-12">
                          <div className="invoice_sub_header mb-2">Custom</div>
                          <div>
                            {invoiceDetails?.invoice?.custom.number_of_items.map(
                              (item: any, index: number) => {
                                // interface ItemInfo {
                                //   price: number;
                                //   item_description: string;
                                // }

                                return (
                                  <>
                                    <div className="row">
                                      <div className="col-sm-3 day_heading">
                                        {/* Item:{index + 1} */}
                                        {item?.item_name ?? `Item ${index + 1}`}
                                      </div>
                                    </div>
                                    <table
                                      className="table"
                                      style={{ width: "100%" }}
                                    >
                                      <thead className="table-dark">
                                        <tr>
                                          <th colSpan={3}>ITEM DESCRIPTION</th>
                                          <th className="text-end">
                                            ITEM PRICE
                                          </th>
                                          {/* <th className="text-end">TOTAL COST</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr key={index + "item"}>
                                          <td
                                            rowSpan={2}
                                            colSpan={3}
                                            width="200px"
                                          >
                                            {/* <b style={{ display: "block" }}> */}
                                            {item.item_description}
                                            {/* </b> */}
                                          </td>
                                          <td>
                                            <span
                                              style={{
                                                display: "block",
                                                float: "right",
                                              }}
                                            >
                                              ${item.price}
                                            </span>
                                          </td>
                                          {/* <td>
                                          <b style={{ display: "block", float:"right"}}>
                                            {item.total_amount}
                                          </b>
                                        </td> */}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                  {invoiceDetails?.invoice_pdf_info?.invoice_discount ? (
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <div
                        className="row mx-3 mb-3 ptb-10 w-50 float-right"
                        style={{
                          background: "#ffffff",
                          border: "2px solid green",
                        }}
                      >
                        <div className="col-10 p-0">
                          <b>Discount (New Clients first order) </b>
                        </div>
                        <div className="col-2 text-end p-0">
                          <b style={{ paddingLeft: "1rem" }}>
                            $
                            {invoiceDetails?.invoice_pdf_info?.invoice_discount}
                          </b>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="w-100">
                    <div
                      className="row mx-2 mb-3 ptb-10"
                      style={{
                        background: "#0c0",
                        borderBottomRightRadius: "15px",
                        borderTopRightRadius: "15px",
                        borderTopLeftRadius: "15px",
                        borderBottomLeftRadius: "15px",
                      }}
                    >
                      <div className="col-10 p-0" style={{ fontWeight: 700 }}>
                        GRAND TOTAL
                      </div>
                      <div className="col-2 text-end p-0">
                        <b style={{ paddingLeft: "1rem" }}>
                          ${getSumofAllStage(invoiceDetails?.invoice)}
                          {/* {isNaN(
                            getSumofAllStage(invoiceDetails?.invoice) +
                              (getSumofAllStage(invoiceDetails?.invoice) *
                                invoiceDetails?.up_charge) /
                                100
                          ) 
                            ? 0
                            : (
                                getSumofAllStage(invoiceDetails?.invoice) +
                                (getSumofAllStage(invoiceDetails?.invoice) *
                                  invoiceDetails?.up_charge) /
                                  100
                              ).toFixed(2)} */}
                        </b>
                      </div>
                    </div>
                    <div
                      className="row details-text"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <p>
                        Raw footage and/or raw photos are delivered within 48
                        hours for free.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="invoice-background-grey">
                  <div className="row py-3 px-2 payment_section">
                    <div className="col-12">
                      <div className="invoice_sub_header mb-2">
                        Payment Schedule
                      </div>
                      <table className="table" style={{ width: "100%" }}>
                        <thead className="table-dark">
                          <tr>
                            <th>{isQuote() ? "QUOTE" : "INVOICE"} NO</th>
                            <th>PAYMENT DATE</th>
                            <th>AMOUNT</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceDetails?.payment_schedule &&
                            invoiceDetails?.payment_schedule?.map(
                              (item: any, index: number) => {
                                return (
                                  <>
                                    {openPaymentScheduleUpdateForm[
                                      `ps_item${index}`
                                    ] && (
                                      <UpdatePaymentSchedule
                                        key={index + "itemUpdate"}
                                        isOpen={
                                          openPaymentScheduleUpdateForm[
                                            `ps_item${index}`
                                          ]
                                        }
                                        handleClose={() =>
                                          setOpenPaymentScheduleUpdateForm({
                                            [`ps_item${index}`]: false,
                                          })
                                        }
                                        paymentSchedule={
                                          invoiceDetails?.payment_schedule
                                        }
                                        paymentScheduleIndex={index}
                                        newUpdate={(data) =>
                                          (item.payment_date =
                                            data.payment_date)
                                        }
                                        invoiceNumber={
                                          item?.invoice_number ||
                                          invoiceDetails?.invoice_pdf_info
                                            ?.invoice_number
                                        }
                                      />
                                    )}
                                    <tr key={index + "item"}>
                                      <td>
                                        {item?.invoice_number ||
                                          invoiceDetails?.invoice_pdf_info
                                            ?.invoice_number}
                                      </td>
                                      <td>
                                        {FORMAT_SLASH_MM_DD_YYYY(
                                          item?.payment_date
                                        )}
                                      </td>
                                      <td>${item?.amount}</td>
                                      <td>
                                        <Button
                                          key={"button" + index}
                                          sx={{
                                            height: "0px",
                                            background: "transparent",
                                            // border: "border: 1px solid #81D0D8",
                                            color: "#000",
                                            fontSize: "10px",
                                            fontFamily: "VerdanaBold",
                                          }}
                                          // variant="contained"
                                          onClick={() =>
                                            setOpenPaymentScheduleUpdateForm({
                                              [`ps_item${index}`]: true,
                                            })
                                          }
                                        >
                                          Edit
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                      <div
                        className="row details-text"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <p>
                          We guarantee not to go over the hourly estimates
                          within this {isQuote() ? "quote" : "invoice"} based on the information provided
                          so far on{" "}
                          <b>
                            {FORMAT_SLASH_MM_DD_YYYY(
                              invoiceDetails?.project?.updated_at
                            )}
                          </b>
                          . The only way additional labor time will be needed is
                          if additional requests are provided after{" "}
                          <b>
                            {FORMAT_SLASH_MM_DD_YYYY(
                              invoiceDetails?.project?.updated_at
                            )}
                          </b>{" "}
                          such as changes to the project scope, revisions etc.
                        </p>
                        <p>
                          If a project takes less time for us to complete than
                          on these hourly estimates, we will apply credit for
                          future project orders.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-2 invoice-main-container">
                  <p style={{ fontSize: "1.5rem" }}>
                    <b className={isQuote() ? "bg-yellow" : ""}>{isQuote() ? 
                            "Once client has confirmed the official dates, times and locations, a bookable version of this quote will be sent over. This quote currently has tentative dates, times, and locations." : 
                            "For Payment Please Click The Button Below"} </b>
                  </p>
                </div>
              </div>

              <div className="py-2 px-4 invoice-main-container">
                {/* <p style={{fontSize: '1.5rem'}}><b>For Payment, please click the link: </b></p> */}
                {!isQuote() && (
                  <p>
                    <Button
                      className="w-100 pay_button"
                      style={{ background: "#23BAD1" }}
                      href={checkoutLink}
                    >
                      <a className="w-100" style={{ textAlign: "center" }}>
                        BOOK YOUR PROJECT HERE
                      </a>
                    </Button>
                  </p>
                )}
                <p>
                  {isQuote() ? "View Terms and Conditions Here: " : "On making payment, agree on "}
                  <a
                    target={"_blank"}
                    href={"https://www.voyagepro.co/serviceinfo"}
                  >
                    terms and conditions.
                  </a>
                </p>
              </div>
            </div>

            <div
              className="px-3 py-2 invoice-main-container"
              style={{ marginBottom: "1rem", marginTop: "0rem" }}
            >
              <div>
                <Divider variant="fullWidth" />
              </div>
              <Box sx={{ my: 1 }}>
                <small className="small_text">
                  To change the details in the {isQuote() ? "quote" : "invoice"}, please press the ‘Back’
                  button
                </small>
              </Box>

              <div className="py-3">
                <div className="col-md-12">
                  <Button
                    sx={style.secondary}
                    onClick={() => navigate(-1)}
                    className="my-2"
                  >
                    BACK
                  </Button>

                  <Button
                    style={{ marginLeft: "10px" }}
                    sx={style.secondary}
                    className="btn btn_custom my-2"
                    onClick={onInvoiceEdit}
                    disabled={[
                      "fully_paid",
                      "first_down_payment",
                      "partial_payment",
                    ].includes(invoiceDetails?.invoice_stage)}
                  >
                    Edit
                  </Button>

                  <Button
                    style={{ marginLeft: "10px" }}
                    sx={style.submit}
                    onClick={downloadPdfDocument}
                    className="my-2"
                  >
                    DOWNLOAD {isQuote() ? "QUOTE" : "INVOICE"}
                  </Button>

                  <Button
                    style={{ marginLeft: "10px" }}
                    sx={style.submit}
                    onClick={() => emailInvoice()}
                    className="my-2"
                  >
                    EMAIL {isQuote() ? "QUOTE" : "INVOICE"}
                  </Button>

                  <Button
                    style={{ marginLeft: "10px" }}
                    sx={style.submit}
                    onClick={() => cloneInvoice()}
                    className="my-2"
                  >
                    CLONE {isQuote() ? "QUOTE" : "INVOICE"}
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </div>

        <div className="col-sm-3">
          {openDialog && (
            <Grid item xs={8}>
              <SendEmail
                emailDetails={{
                  invoice_id: id,
                  client_id: invoiceDetails?.client?.id,
                  email: invoiceDetails?.client?.email || "",
                  project: invoiceDetails?.project?.name,
                }}
                handleCloseEmail={() => setOpenDialog(false)}
                key={id}
              />
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

export default GenerateInvoice;
