import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link
} from "@react-pdf/renderer";
import { styles } from './pdf-stylesheet';
import VerdanaRegular from "../../assets/font/verdana/verdana.ttf";
import VerdanaBold from "../../assets/font/verdana/verdanab.ttf";
import invoiceImg from "../../assets/images/invoice_header.png";
import quoteImg from "../../assets/images/quote_header.png";
import { FORMAT_SLASH_MM_DD_YYYY } from "../../utils/dateformat";
import { CrewDetailsText } from "./data";
import { APIMETHOD } from "../../core/constant";
import moment from "moment";

import { makeTime, setupEndTime, shootingEndTime, getCrewSizeBasedOnCrewMember, getCrewSizes, getCrewSizeDescription, getCrewMembers, getPostProductionRawFootageDeliveryTotalCost, isLastCrewPackageByIndex } from "../../utils/utils";
import _ from "lodash";

// Register fonts
Font.register({
  family: "Verdana",
  fonts: [
    {
      src: VerdanaRegular
    },
    {
      src: VerdanaBold,
      fontWeight: "bold"
    }
  ]
});

const Bullet = () => {
  return (
    <>
      •
    </>
  )
}

const isSizeInclude = (_invoiceDetails, sizes = "") => {
  // let crewSizes = _invoiceDetails?.invoice?.production?.selected_crew_sizes;
  let crewSizes = getCrewSizes(_invoiceDetails);
  // if (crewSizes && crewSizes[sizes?.toLowerCase()]) {
  //   return crewSizes[sizes?.toLowerCase()];
  // }
  if (crewSizes.includes(sizes.toLowerCase())) {
    return true
  }
  return false;
};

const infoSection = invoiceDetails => {
  return (
    <View>
      <Image src={invoiceDetails?.is_quote ? quoteImg : invoiceImg} />
      <View
        style={[
          styles.container,
          styles.flexRow,
          styles.justifyBetween,
          styles.alignCenter
        ]}
      >
        <View>
          <Text
            style={[
              styles.fontBold,
              { textTransform: "uppercase", color: "#7f7f7f" }
            ]}
          >
            {invoiceDetails?.is_quote ? "Quote" : "Invoice"} To
          </Text>
          <Text style={styles.fontBold}>{invoiceDetails?.client?.name}</Text>
          <Text>{invoiceDetails?.client?.email}</Text>
          <Text>{invoiceDetails?.client?.phone}</Text>
        </View>
        <View style={[styles.address]}>
          <Text style={[styles.fontBold, styles.textCenter]}>{invoiceDetails?.is_quote ? "Quote" : "Invoice"} Address</Text>
          <Text style={[styles.textCenter]}>1000 South Park Lane </Text>
          <Text style={[styles.textCenter]}>Suite 1 Tempe Arizona, 85281</Text>
        </View>
        <View style={styles.textRight}>
          <Text>No. {invoiceDetails?.invoice_pdf_info?.invoice_number}</Text>
          <Text style={[styles.fontBold]}>
            {invoiceDetails?.is_quote ? "Quote" : "Invoice"} Creation Date :{" "}
            {FORMAT_SLASH_MM_DD_YYYY(
              invoiceDetails?.invoice_pdf_info?.invoice_date
            )}
          </Text>
        </View>
      </View>
      <View style={[styles.container, { backgroundColor: "#b9f0f8" }]}>
        <Text
          style={[
            styles.fontBold,
            { textTransform: "uppercase", color: "#7f7f7f" }
          ]}
        >
          PROJECT
        </Text>
        <Text style={[styles.fontBold, { marginTop: 2 }]}>
          {invoiceDetails?.project?.name}
        </Text>
      </View>
    </View>
  );
};

const preProductionSection = invoiceDetails => {
  return (
    invoiceDetails?.invoice?.pre_production &&
    invoiceDetails?.invoice?.pre_production?.line_items &&
    JSON.stringify(
      invoiceDetails?.invoice?.pre_production?.line_items || {}
    ) !== "{}" && (
      <View>
        <View style={styles.container}>
          <View style={styles.flexRow}>
            <Text style={styles.heading}>Project Planning </Text>
            {/* <Text style={styles.subHeading}>
              ($
              {invoiceDetails?.invoice?.pre_production?.hourly_charge} per hour)
            </Text> */}
          </View>
          <View>
            <Text>{CrewDetailsText.PROJECT_PLANNING}</Text>
          </View>
        </View>
        <View style={[styles.container, { paddingTop: 0 }]}>
          <View style={styles.tableHeaderRow}>
            <Text style={[styles.tableHeader, { width: "40%" }]}>ITEM</Text>
            <Text
              style={[
                styles.tableHeader,
                {
                  textAlign: "right",
                  width: "20%"
                }
              ]}
            >
              QTY
            </Text>
            <Text style={[styles.tableHeader, { width: "20%" }]}>UNIT</Text>
            <Text
              style={[
                styles.tableHeader,
                {
                  textAlign: "right",
                  width: "20%"
                }
              ]}
            >
              TOTAL COST
            </Text>
          </View>
          {invoiceDetails?.invoice &&
            Object.keys(
              invoiceDetails?.invoice?.pre_production?.line_items
            )?.map((item, index) => {
              return (
                <View key={index + "item"} style={[styles.flexRow]} wrap={false}>
                  <Text style={[styles.tableCell, { width: "38%",paddingBottom:"10px", paddingTop:"5px" }]}>
                    {
                      invoiceDetails?.invoice?.pre_production?.line_items[item]
                        ?.description
                    }
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.textRight,
                      { width: "22%",paddingBottom:"10px", paddingTop:"5px", marginRight:"5px" }
                    ]}
                  >
                    {
                      invoiceDetails?.invoice?.pre_production?.line_items[item]
                        ?.hours
                    }
                  </Text>
                  <Text style={[styles.tableCell, { width: "20%",paddingBottom:"10px", paddingTop:"5px", marginLeft:"5px" }]}>
                    hours
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.textRight,
                      { width: "20%",paddingBottom:"10px", paddingTop:"5px" }
                    ]}
                  >
                    $
                    {
                      invoiceDetails?.invoice?.pre_production?.line_items[item]
                        ?.total
                    }
                  </Text>
                </View>
              );
            })}
        </View>
      </View>
    )
  );
};

const productionSection = invoiceDetails => {
  return (
    invoiceDetails?.invoice?.production &&
    invoiceDetails?.invoice?.production?.no_of_filming_days && (
      <View style={styles.container}>
        <View style={styles.flexRow}>
          <Text style={styles.heading}>Production </Text>
        </View>
        <View>
          {isSizeInclude(invoiceDetails, "solo") && (
            <View wrap={false}>
              <Text style={[styles.fontBold]}>
                Solo Crew:
                <Text style={[styles.fontNormal]}>
                  {" "}
                  {CrewDetailsText.SOLO_VIDEOGRAPHER}
                </Text>
              </Text>
            </View>
          )}
          {isSizeInclude(invoiceDetails, "large") && (
            <View wrap={false}>
              <Text style={[styles.fontBold]}>
                Large Crew:
                <Text style={[styles.fontNormal]}>
                  {" "}
                  {CrewDetailsText.LARGE_CREW}
                </Text>
              </Text>
            </View>
          )}
          {isSizeInclude(invoiceDetails, "medium") && (
            <View wrap={false}>
              <Text style={[styles.fontBold]}>
                Medium Crew:
                <Text style={[styles.fontNormal]}>
                  {" "}
                  {CrewDetailsText.MEDIUM_CREW}
                </Text>
              </Text>
            </View>
          )}
          {isSizeInclude(invoiceDetails, "small") && (
            <View wrap={false}>
              <Text style={[styles.fontBold]}>
                Small Crew:
                <Text style={[styles.fontNormal]}>
                  {" "}
                  {CrewDetailsText.SMALL_CREW}
                </Text>
              </Text>
            </View>
          )}
          {isSizeInclude(invoiceDetails, "wedding") && (
            <View wrap={false}>
              <View>
                <Text style={[styles.fontBold]}>
                  Wedding:
                  <Text style={[styles.fontNormal]}>
                    {" "}
                    {CrewDetailsText.WEDDING}
                  </Text>
                </Text>
              </View>
              <View>
                <Text style={[styles.fontBold]}>Gear Included:</Text>
                <Text>- 2, 4K Cameras</Text>
                <Text>- Full Range of Lens</Text>
                <Text>- Tripods</Text>
                <Text>- Gimbals</Text>
                <Text>- 3 Point Lighting</Text>
                <Text>- RGB Tubes</Text>
                <Text>- Grip Equipment</Text>
              </View>
            </View>
          )}
          {isSizeInclude(invoiceDetails, "live_stream") && (
            <View wrap={false}>
              <Text style={[styles.fontBold]}>Live Stream:</Text>
              <View>
                <Text>Included :</Text>
                <View>- 2, 4K Cameras</View>
                <View>- Full Range of Lens</View>
                <View>- Tripods</View>
                <View>- Gimbals</View>
                <View>- 3 Point Lighting</View>
                <View>- RGB Tubes</View>
                <View>- Grip Equipment</View>
              </View>
              <Text>{CrewDetailsText.LIVE_STREAM}</Text>
            </View>
          )}
          <Text style={[styles.crewMeal]}>Crew will have a meal break every 4 hours.</Text>
        </View>
        {invoiceDetails?.invoice &&
          Object.keys(invoiceDetails?.invoice?.production)
            ?.filter(s => s.includes("day_"))
            ?.map((item, index) => {
              return (
                <View>
                  <View
                    style={[
                      styles.flexRow,
                      { marginTop: 15, marginBottom: 12 }
                    ]}
                    wrap={false}
                  >
                    <Text
                      style={[
                        styles.fontBold,
                        { fontSize: 14, marginRight: 70 }
                      ]}
                    >
                      Day {index + 1}
                    </Text>
                    <View style={{ marginRight: 70 }}>
                      <Text>Shoot Date</Text>
                      <Text style={[styles.fontBold,{backgroundColor:"#FFA600",width:"65rem"}]}>
                        {FORMAT_SLASH_MM_DD_YYYY(
                          invoiceDetails?.invoice?.production[item]
                            ?.filming_date
                        )}
                      </Text>
                    </View>
                    <View>
                      <Text>Crew Size</Text>
                      <View>
                        {(
                          invoiceDetails?.invoice?.production[item]
                            ?.crew_size_bought || []
                        ).map((size, index) => (
                          <View
                            key={size}
                            style={[styles.textCapitalize, styles.fontBold]}
                          >
                            <Text>
                              {size.replace("_", " ")}
                              {(
                                invoiceDetails?.invoice?.production[item]
                                  ?.crew_size_bought || []
                              )?.length -
                                1 !==
                                index && <Text>, </Text>}{" "}
                            </Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                  <View>
                    <View style={styles.tableHeaderRow} wrap={false}>
                      <Text style={[styles.tableHeader, { width: "21%" }]}>
                        LOCATION
                      </Text>
                      <Text style={[styles.tableHeader, { width: "20%" }]}>
                        CREW SIZE
                      </Text>
                      <Text style={[styles.tableHeader, { width: "20%" }]}>
                        CREW MEMBERS
                      </Text>
                      <Text style={[styles.tableHeader, { width: "10%", marginRight:"5px" }]}>
                        ARRIVAL TIME
                      </Text>
                      <Text style={[styles.tableHeader, { width: "15%" }]}>
                        DEPARTURE TIME
                      </Text>
                      <Text
                        style={[
                          styles.tableHeader,
                          styles.textRight,
                          { width: "15%" }
                        ]}
                      >
                        TOTAL COST
                      </Text>
                    </View>
                    {Object.keys(
                      invoiceDetails?.invoice?.production[item]?.line_items
                    )?.map((crew, index) => {
                      const invoiceItem =
                        invoiceDetails?.invoice?.production[item]?.line_items[
                          crew
                        ];

                      const sizes = {
                        solo: "Solo Crew",
                        large: "Large Crew",
                        medium: "Medium Crew",
                        small: "Small Crew"
                      };

                      let crewSize =
                        sizes[invoiceItem?.crew_size?.toLowerCase()];

                      return (
                        <View>
                          <View
                            key={index + "item"}
                            style={[styles.flexRow,
                              styles.borderBottom,
                              { marginBottom: 10, paddingTop:5, paddingBottom:5}]}
                            wrap={false}
                          >
                            <Text style={[{width: "21%", backgroundColor:"#FFA600", marginTop:"3px",height:"auto" }]}>
                                {invoiceItem?.location}
                            </Text>
                            <Text
                              style={[
                                styles.textCapitalize,
                                {
                                  width: "20%",
                                  marginLeft:"5px"
                                }
                              ]}
                            >
                              {crewSize}
                            </Text>
                            <View style={[{ width: "20%" }]}>
                              {invoiceItem?.crew_member_1 && (
                                <Text>#1 {invoiceItem?.crew_member_1}</Text>
                              )}
                              {invoiceItem?.crew_member_2 && (
                                <Text>#2 {invoiceItem?.crew_member_2}</Text>
                              )}
                              {invoiceItem?.crew_member_3 && (
                                <Text>#3 {invoiceItem?.crew_member_3}</Text>
                              )}
                              {invoiceItem?.crew_member_4 && (
                                <Text>#4 {invoiceItem?.crew_member_4}</Text>
                              )}
                            </View>
                            <Text style={[{ width: "10%",marginRight:"5px",  backgroundColor:"#FFA600", marginTop:"3px", height:"50%"}]}>
                                {moment(
                                  invoiceItem?.arrival_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}
                            </Text>
                            <Text style={[{ width: "10%", backgroundColor:"#FFA600", marginTop:"3px", height:"50%" }]}>
                                {moment(
                                  invoiceItem?.departure_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}
                            </Text>
                            <Text
                              style={[
                                styles.textRight,
                                { width: "15%" }
                              ]}
                            >
                              ${invoiceItem?.total_cost}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.paddingY,
                              styles.borderBottom,
                              { marginBottom: 10 }
                            ]}
                            wrap={false}
                          >
                            <Text style={[styles.fontBold]}>
                              WHAT IS BEING SHOT WITH THIS PACKAGE?
                            </Text>
                            <Text style={styles.fontNormal}>
                              {" "}
                              {invoiceItem?.shot_with_this_package}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.borderBottom,
                              { paddingLeft: "16.6%", paddingBottom: 10 }
                            ]}
                            wrap={false}
                          >
                            <View
                              style={[
                                styles.flexRow,
                                styles.justifyBetween,
                                styles.paddingY,
                                styles.paddingX,
                                {
                                  backgroundColor: "#efefef"
                                }
                              ]}
                            >
                              <View style={styles.flexRow}>
                                <Text>Total Travel Mile : </Text>
                                <Text>{invoiceItem?.total_travel_miles}</Text>
                              </View>
                              <View style={styles.flexRow}>
                                <Text>Travel Cost : </Text>
                                <Text>${invoiceItem?.travel_cost}</Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              );
            })}
      </View>
    )
  );
};

const ProductionTimeSection = (invoiceItem, setup_end_time, shooting_end_time) => {

  const arrival_time = makeTime(invoiceItem?.arrival_time);
  const departure_time = makeTime(invoiceItem?.departure_time);

  return (
    <View style={[{margin: "10px 0px"}]}>
      {/* <View style={styles.flexRow}>
        <Text style={[styles.fontBold]}>Location: </Text> <Text>{invoiceItem?.location}</Text>
      </View> */}
      <View style={[styles.flexRow, {marginBottom: "2px"}]}>
        <Text style={[styles.fontBold]}>Setup Time: </Text> 
        <View style={[styles.highlight, styles.borderRadius]}>
          <Text>{ arrival_time }</Text>
        </View>
        <Text style={[styles.paddingX]}>-</Text> 
        <Text>{ setup_end_time }</Text>
      </View>
      <View style={[styles.flexRow, {marginBottom: "2px"}]}>
        <Text style={[styles.fontBold]}>Shooting Time: </Text> 
        <Text>{ setup_end_time }</Text>
        <Text style={[styles.paddingX]}>-</Text>
        <Text>{ shooting_end_time }</Text>
      </View>
      <View style={styles.flexRow}>
        <Text style={[styles.fontBold]}>Tear-down Time: </Text> 
        <Text>{ shooting_end_time }</Text> 
        <Text style={[styles.paddingX]}>-</Text>
        <View style={[styles.highlight, styles.borderRadius]}>
          <Text>{ departure_time }</Text>
        </View>
      </View>
    </View>
  );
}

const productionSectionNew = invoiceDetails => {
  return (
    invoiceDetails?.invoice?.production &&
    invoiceDetails?.invoice?.production?.no_of_filming_days && (
      <View style={styles.container}>
        <View style={styles.flexRow}>
          <Text style={styles.heading}>Production </Text>
        </View>
        {invoiceDetails?.invoice &&
          Object.keys(invoiceDetails?.invoice?.production)
            ?.filter(s => s.includes("day_"))
            ?.map((item, index) => {
              const day_index = index;
              return (
                <View>
                  
                  <View>
                    <View style={[styles.tableHeaderRow, styles.justifyBetween]} wrap={false}>
                      <Text style={[styles.tableHeader, { width: "21%" }]}>
                        DETAILS
                      </Text>
                      <Text style={[styles.tableHeader, styles.textRight, { width: "15%" }]}>
                        TOTAL COST
                      </Text>
                    </View>
                    <Text style={[styles.fontBold, { fontSize: 14, padding: "10px 0" }]}>
                      Day {index + 1}
                    </Text>

                    {Object.keys(
                      invoiceDetails?.invoice?.production[item]?.line_items
                    )?.map((crew, index) => {
                      const invoiceItem =
                        invoiceDetails?.invoice?.production[item]?.line_items[
                          crew
                        ];

                      const sizes = {
                        solo: "Solo Crew",
                        large: "Large Crew",
                        medium: "Medium Crew",
                        small: "Small Crew"
                      };

                      let crewSize = sizes[invoiceItem?.crew_size?.toLowerCase()];
                      const crewMembers = _.join(getCrewMembers(invoiceItem.crew_members), ", ");

                      let setup_end_time = setupEndTime(invoiceItem?.arrival_time, invoiceItem?.setup_time ?? 15);
                      let shooting_end_time = shootingEndTime(invoiceItem?.departure_time, invoiceItem?.setup_time ?? 15);

                      let total_cost = invoiceItem?.total_cost;
                      const rawDeliveryTotalCost = getPostProductionRawFootageDeliveryTotalCost(invoiceDetails)
                      const isLast = isLastCrewPackageByIndex(invoiceDetails, day_index, index);
                      if (isLast) {
                          total_cost+=rawDeliveryTotalCost;
                      }

                      return (
                        <View style={[styles.packageContainer]}>
                          <View style={[styles.packageInnerTop]} key={index + "item"} wrap={false}>
                            {/* Package Inner Top Two */}
                            <View style={[styles.packageInnerTopItemOne]}>
                              <View style={[{ marginBottom: 10 }]} wrap={false}>
                                <Text style={[styles.fontBold]}>
                                  What Is Being Shot With This Package?
                                </Text>
                                <Text style={styles.fontNormal}>
                                  {" "}
                                  {invoiceItem?.shot_with_this_package}
                                </Text>
                              </View>

                              <View style={[{ marginBottom: 10 }]} wrap={false}>
                                <Text style={[styles.fontBold, styles.marginBottom]}>
                                  Crew Details
                                </Text>
                                <Text style={styles.fontNormal}>
                                  { `${getCrewSizeDescription(invoiceItem?.crew_size, invoiceDetails?.show_crew_size_description)} Crew will have a meal break every 4 hours.` }
                                </Text>
                              </View>

                              {/* Shoot Date & Crew Size */}
                              <View style={[styles.flexRow]}>
                                <View>
                                  <Text style={styles.fontBold}>Shoot Date</Text>
                                  <View style={[styles.highlight, styles.borderRadius]}>
                                    <Text>
                                      {FORMAT_SLASH_MM_DD_YYYY(
                                        invoiceDetails?.invoice?.production[item]
                                          ?.filming_date
                                      )}
                                    </Text>
                                  </View>
                                </View>
                                {/* <View style={[{marginLeft: 20}]}>
                                  <Text style={styles.fontBold}>Crew Size</Text>
                                  <Text style={styles.textCapitalize}>{getCrewSizeBasedOnCrewMember(invoiceItem)}</Text>
                                  <Text style={styles.textCapitalize}>{ crewSize }</Text>
                                </View> */}
                                <View style={[{marginLeft: 20}]}>
                                  <Text style={styles.fontBold}>Location</Text>
                                  <View style={[styles.highlight, styles.borderRadius]}>
                                    <Text style={styles.textCapitalize}>{ invoiceItem?.location }</Text>
                                  </View>
                                </View>
                              </View>

                              { ProductionTimeSection(invoiceItem, setup_end_time, shooting_end_time) }

                              <View style={styles.flexRow}>
                                <Text style={[styles.fontBold]}>Crew Members: </Text>
                                <Text style={[styles.textCapitalize]}>{crewMembers}</Text>
                                {/* {invoiceItem?.crew_member_1 && (
                                  <Text>{invoiceItem?.crew_member_1}</Text>
                                )}
                                {invoiceItem?.crew_member_2 && (
                                  <Text>, {invoiceItem?.crew_member_2}</Text>
                                )}
                                {invoiceItem?.crew_size == "medium" && (
                                  <Text>, {invoiceItem?.crew_member_3}</Text>
                                )}
                                {invoiceItem?.crew_size == "large" && (
                                  <Text>, {invoiceItem?.crew_member_3}, {invoiceItem?.crew_member_4}</Text>
                                )} */}
                              </View>
                            </View>
                            
                            {/* Package Inner Top Two */}
                            <View style={styles.packageInnerTopItemTwo}>
                              <Text>
                                ${total_cost}
                              </Text>
                            </View>
                          </View>
                          
                          <View style={[styles.packageInnerBottom ]} wrap={false}>
                            <View style={[styles.flexRow, styles.justifyBetween, styles.paddingY, styles.paddingX]}>
                              <View style={styles.flexRow}>
                                <Text style={[styles.fontBold]}>Sales Tax: </Text>
                                <Text>{invoiceItem?.sales_tax}</Text>
                              </View>
                              <View style={styles.flexRow}>
                                <Text style={[styles.fontBold]}>Total Travel Mile: </Text>
                                <Text>{invoiceItem?.total_travel_miles}</Text>
                              </View>
                              <View>
                                <Text style={{marginBottom: 2}}>Each crew member is compensated for driving to the shoot.</Text>
                                <View style={[styles.flexRow, styles.justifyEnd]}>
                                  <Text style={[styles.fontBold]}>Travel Cost: </Text>
                                  <Text>${invoiceItem?.travel_cost}</Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              );
            })}
      </View>
    )
  );
};

const postProductionSectionOld = invoiceDetails => {
  return (
    invoiceDetails?.invoice?.post_production &&
    invoiceDetails?.invoice?.post_production?.line_items && (
      <View style={styles.container}>
        <View wrap={false}>
          <Text style={styles.heading}>
            Post-Production
            {/* <Text style={styles.subHeading}>
              ($ {invoiceDetails?.invoice?.post_production?.hourly_charge} per
              hour)
            </Text> */}
          </Text>
          <Text style={styles.pb_10}>
          {/* <Text style={{ marginBottom: 10 }}> */}
            {CrewDetailsText.POST_PRODUCTION}
          </Text>
        </View>
        <View style={styles.tableHeaderRow} wrap={false}>
          <Text style={[styles.tableHeader, { width: "37%" }]}>
            Requirements
          </Text>
          <Text style={[styles.tableHeader, { width: "53%" }]}>Details</Text>
          <Text
            style={[styles.tableHeader, styles.textRight, { width: "10%" }]}
          >
            Total Cost
          </Text>
        </View>
        {invoiceDetails &&
          invoiceDetails?.invoice &&
          Object.keys(
            invoiceDetails?.invoice?.post_production?.line_items
          )?.map((item, index) => {
            const is_raw_footage_delivery = invoiceDetails?.invoice
              ?.post_production?.line_items[
              item
            ]?.is_raw_footage_delivery;

            return (
              <View key={index + "item"} style={[styles.flexRow,styles.borderBottom,{
                              marginBottom: 10, paddingTop:5, paddingBottom:5}]}>
                <View style={[{ width: "37%", paddingRight: "5px" }]}>
                  <Text style={[styles.fontBold, {marginBottom: "2px"}]}>
                    {invoiceDetails?.project?.name}{" "}
                    {
                      invoiceDetails?.invoice?.post_production?.line_items[item]
                        ?.title
                    }
                  </Text>
                  <Text>
                    {
                      invoiceDetails?.invoice?.post_production?.line_items[item]
                        ?.description
                    }
                  </Text>
                </View>
                <View style={[{ width: "53%"}]}>
                  <View style={[styles.flexRow, styles.justifyBetween]}>
                    <View style={{ width: "70%" }}>
                      {invoiceDetails?.invoice?.post_production?.line_items[item]
                        ?.type === "video" && (
                        <Text style={[styles.textCapitalize,{marginBottom:5}]}>Aspect Ratio:</Text>
                      )}
                      {invoiceDetails?.invoice?.post_production?.line_items[item]
                        ?.type === "photo" && (
                          <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginBottom:5}]}>
                            <Text style={[styles.textCapitalize]}>
                              Number of photos to be edited:
                            </Text>
                          </View>
                      )}
                      {invoiceDetails?.invoice?.post_production?.line_items[item]
                        ?.type === "video" && (
                          <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginBottom:5}]}>
                            <Text style={[styles.textCapitalize]}>
                              Estimated final run time:
                            </Text>
                          </View>
                      )}
                      <View style={[{marginBottom:5}]}>
                        <Text style={[styles.textCapitalize]}>
                          Estimated hours needed for initial delivery
                        </Text>
                      </View>
                      <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginBottom:5}]}>
                        <Text style={[styles.textCapitalize]}>
                          Deadline of initial delivery of{" "}
                          {
                            invoiceDetails?.invoice?.post_production?.line_items[
                              item
                            ]?.type
                          }{" "}
                          Edit
                        </Text>
                      </View>
                    </View>
                    <View style={[ styles.textRight, {width: "25%"} ]}>
                      {invoiceDetails?.invoice?.post_production?.line_items[item]
                        ?.type === "video" && (
                          <View style={[{marginBottom:5}]}>
                            <Text style={[styles.textCapitalize]}>
                              {
                                invoiceDetails?.invoice?.post_production?.line_items[
                                  item
                                ]?.aspect_ratio
                              }
                            </Text>
                          </View>
                      )}
                      {invoiceDetails?.invoice?.post_production?.line_items[item]
                        ?.type === "photo" && (
                          <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginLeft:5, marginBottom:5}]}>
                            <Text style={[styles.textCapitalize]}>
                              {
                                invoiceDetails?.invoice?.post_production?.line_items[
                                  item
                                ]?.no_of_photos_edit
                              }
                            </Text>
                          </View>
                      )}
                      {invoiceDetails?.invoice?.post_production?.line_items[item]
                        ?.type === "video" && (
                          <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginLeft:5, marginBottom:5}]}>
                            <Text style={[styles.textCapitalize]}>
                              {
                                invoiceDetails?.invoice?.post_production?.line_items[
                                  item
                                ]?.final_run_time_of_intro
                              }{" "}
                              Minute
                            </Text>
                          </View>
                      )}
                      <Text style={[styles.textCapitalize, {marginLeft:5, marginBottom:5}]}>
                        {
                          invoiceDetails?.invoice?.post_production?.line_items[
                            item
                          ]?.hours_needed_for_initial_delivery
                        }{" "}
                        Hour
                      </Text>
                      <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginLeft:5, marginBottom:5}]}>
                        <Text>
                          {FORMAT_SLASH_MM_DD_YYYY(
                            invoiceDetails?.invoice?.post_production?.line_items[
                              item
                            ]?.deadline_of_initial_delivery
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <Text
                  style={[styles.textRight, { width: "10%" }]}
                >
                  ${is_raw_footage_delivery? 0 : invoiceDetails?.invoice
                      ?.post_production?.line_items[
                      item
                    ]?.total_cost}
                  {/* {invoiceDetails?.invoice?.post_production?.hourly_charge *
                    invoiceDetails?.invoice?.post_production?.line_items[item]
                      ?.hours_needed_for_initial_delivery} */}
                </Text>
              </View>
            );
          })}
      </View>
    )
  );
};

const PostProductionItem = (item, index, invoiceDetails) => {
  const lineItem = invoiceDetails?.invoice?.post_production?.line_items[item]
  const is_raw_footage_delivery = lineItem?.is_raw_footage_delivery;
  const filmingDays = lineItem?.video?.time_estimator?.filming_days;
  
  return (
    <View style={[styles.packageContainer, styles.flexRow]} key={`post_production_${item}`}>
      {/* Left Section */}
      <View style={{width: "60%", borderRight: "1px solid #6C757D" }}>
        <View style={[styles.pkgCol, styles.borderBottom]}>
          {/* <Text style={[styles.fontBold, styles.mb_4]}>Requirements</Text> */}
          <Text style={[styles.fontBold, styles.mb_4]}>
            {invoiceDetails?.project?.name}{" "}
            { lineItem?.title }
          </Text>
          <Text>{lineItem?.type === "video"? lineItem?.video?.overall_message : lineItem?.description}</Text>
        </View>

        {lineItem?.type === "photo" && (
          <View style={[styles.pkgCol, styles.col_12]}>
            <View style={[styles.flexRow, styles.justifyBetween, styles.row]}>
              <View style={[styles.mb_6, {width: "48%"}]}>
                <Text style={[styles.fontBold, styles.highlight, styles.borderRadius, styles.mb_4]}>Number of photos to be edited</Text>
                <View style={[styles.highlight, styles.borderRadius]}>
                  <Text>{lineItem?.no_of_photos_edit}</Text>
                </View>
              </View>
              <View style={[styles.mb_6, {width: "48%"}]}>
                <Text style={[styles.fontBold, styles.mb_4]}>Estimated Hours Needed for Initial Delivery</Text>
                <Text>{lineItem?.hours_needed_for_initial_delivery} Hours</Text>
              </View>
              <View style={[styles.mb_6, {width: "48%"}]}>
                <Text style={[styles.fontBold, styles.highlight, styles.borderRadius, styles.mb_4]}>Deadline of initial delivery of photo Edit</Text>
                <View style={[styles.highlight, styles.borderRadius]}>
                  <Text>{FORMAT_SLASH_MM_DD_YYYY (lineItem?.deadline_of_initial_delivery)}</Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {lineItem?.type === "video" && (
          <>
            {/* Key Shots */}
            <View style={[styles.pkgCol, styles.borderBottom]}>
              <Text style={[styles.fontBold, styles.mb_4]}>Key Shots to Display on Screen</Text>
              <View>
                <View>
                  {lineItem?.video?.key_shots?.items.map((item, index) => {
                      console.log("Post Production key Shot: ", item, index)
                      return (
                          // <li key={`key_shot_${index}`}>{ item }</li>
                          <Text key={`key_shot_${index}`}>{Bullet()} {item}</Text>
                      )
                  })}
                </View>
              </View>
            </View>
            {/* Key Shots End */}

            {/* Branding Section */}
            <View style={[styles.pkgCol, styles.borderBottom]}>
              <View style={[styles.flexRow, {marginBottom: 8}]}>
                <View style={[{width: "50%"}]}>
                  <Text style={[styles.fontBold, styles.mb_4]}>Fonts</Text>
                  <Text>{ lineItem?.video?.branding?.fonts }</Text>
                </View>
                <View style={[{width: "50%"}]}>
                  <Text style={[styles.fontBold, styles.mb_4]}>Branding Colors</Text>
                  <Text>{lineItem?.video?.branding?.colors}</Text>
                </View>
              </View>
              <View>
                <Text style={[styles.fontBold, styles.mb_4]}>Branding Detail</Text>
                <Text>{lineItem?.video?.branding?.details}</Text>
              </View>
            </View>
            {/* Branding Section End */}

            {/* Includes and Detail About Music in Edit */}
            <View style={[styles.pkgCol]}>
              <View style={[styles.flexRow, {marginBottom: 8}]}>
                {(lineItem?.video?.include_logo == true || lineItem?.video?.include_voiceover == true) && (
                  <View style={[{width: "50%"}]}>
                    <Text style={[styles.fontBold, styles.mb_4]}>Include</Text>
                    {lineItem?.video?.include_logo == true && (
                        <Text>{Bullet()} Logo</Text>
                    )}
                    {lineItem?.video?.include_voiceover == true && (
                        <Text>{Bullet()} Voiceover</Text>
                    )}
                  </View>
                )}

                <View style={[{width: "50%"}]}>
                  <Text style={[styles.fontBold, styles.mb_4]}>The Length of Final Edit</Text>
                  <Text>{lineItem?.video?.time_estimator?.final_video_length} Minutes</Text>
                </View>

              </View>

              <View>
                <Text style={[styles.fontBold, styles.mb_4]}>Detail About Music in Edit</Text>
                <Text>{lineItem?.video?.music_details}</Text>
              </View>
            </View>
            {/* Includes and Detail About Music in Edit End */}
          </>
        )}

      </View>
      {/* Left Section End */}

      {/* Right Section */}
      <View style={{width: "40%"}}>
        <View style={[styles.pkgCol]}>
          <Text style={[styles.fontBold, styles.mb_4, styles.textRight]}>Total Cost</Text>
          <Text style={[styles.textRight]}>${!is_raw_footage_delivery? lineItem?.total_cost : 0}</Text>
        </View>
        
        {lineItem?.type === "video" && (
          <>
            <View style={[styles.pkgCol, styles.borderTop, ]}>
              <Text style={[styles.fontBold, styles.mb_4]}>Break Down Of Estimated Time</Text>
              {_.isNil (lineItem?.video?.time_estimator?.is_b_roll) && (
                <>
                  <View style={[styles.flexRow]}>
                    <Text>{Bullet()} Organizing Footage: </Text>  
                    <Text style={[styles.textOrange]}>{lineItem?.estimate?.organizing_footage} Hours</Text>
                  </View>
                  <View style={[styles.flexRow]}>
                    <Text>{Bullet()} Trimming Footage: </Text>  
                    <Text style={[styles.textOrange]}>{lineItem?.estimate?.trimming_footage} Hours</Text>
                  </View>
                  <View style={[styles.flexRow]}>
                    <Text>{Bullet()} Arranging Clips into Storyline: </Text>  
                    <Text style={[styles.textOrange]}>{lineItem?.estimate?.arranging_clips} Hours</Text>
                  </View>
                  <View style={[styles.flexRow]}>
                    <Text>{Bullet()} Color Grading: </Text>  
                    <Text style={[styles.textOrange]}>{lineItem?.estimate?.color_grading} Hours</Text>
                  </View>
                  <View style={[styles.flexRow]}>
                    <Text>{Bullet()} Refining: </Text>  
                    <Text style={[styles.textOrange]}>{lineItem?.estimate?.refining} Hours</Text>
                  </View>
                  <View style={[styles.flexRow]}>
                    <Text>{Bullet()} Adding Music to Edit: </Text>  
                    <Text style={[styles.textOrange]}>{lineItem?.estimate?.music} Hours</Text>
                  </View>
                  <View style={[styles.flexRow]}>
                    <Text>{Bullet()} Adding Text to Edit: </Text>  
                    <Text style={[styles.textOrange]}>{lineItem?.estimate?.text} Hours</Text>
                  </View>
                </>
              )}

              {!_.isNil (lineItem?.video?.time_estimator?.is_b_roll) && (
                <View>
                  <Text style={[styles.mb_4]}>{Bullet()} Gathering Asset, Transferring and Delivering Files, Ensuring Requirements are Met: </Text>  
                  <Text style={[styles.textOrange]}>{lineItem?.estimate?.base_buffer} Hours</Text>
                </View>

              )}
              {!_.isNil(lineItem?.estimate?.revision) && (
                <View style={[styles.flexRow]}>
                  <Text>{Bullet()} Revisions: </Text>  
                  <Text style={[styles.textOrange]}>{lineItem?.estimate?.revision} Hours</Text>
                </View>
              )}

              <Text style={{marginTop: 10}}>This time estimate is calculated based on all the details that have been provided from the client about their project so far. We base this estimate on parameters such as the amount of shots we need to capture, the length of the edit, and variables included within the edit such as text and music.</Text>
            </View>
            {filmingDays.length > 0 && (
              <View style={[styles.pkgCol, styles.borderTop, ]}>
                <Text style={[styles.fontBold, styles.mb_4]}>Filming Days Included</Text>
                {filmingDays.map((item) => (
                    <Text key={`fDay${item}`}>{Bullet()} Day {item}</Text>  
                ))}
              </View>
            )}

            <View style={[styles.pkgCol, styles.flexRow, styles.justifyEnd, styles.borderTop, ]}>
              <View>
                <Text style={[styles.fontBold, styles.mb_4, styles.textRight]}>Estimated Hours</Text>
                <View style={[styles.highlight, styles.borderRadius]}>
                  <Text style={[styles.textRight]}>{lineItem?.total_hours} Hours</Text>
                </View>
              </View>
            </View>
          </>
        )}
      </View>
      {/* Right Section Ebd */}
    </View>
  )
}

const postProductionSection = invoiceDetails => {
  return (
    invoiceDetails?.invoice?.post_production &&
    invoiceDetails?.invoice?.post_production?.line_items && (
      <View style={styles.container}>
        <View wrap={false}>
          <Text style={styles.heading}>
            Post-Production
            {/* <Text style={styles.subHeading}>
              ($ {invoiceDetails?.invoice?.post_production?.hourly_charge} per
              hour)
            </Text> */}
          </Text>
          <View style={styles.flexRow}>
            <Text style={styles.pb_10}>
              {CrewDetailsText.POST_PRODUCTION}{" "}
              {invoiceDetails?.invoice?.post_production?.has_revision && (
                <Text style={styles.highlight}>We have added time for one round of moderate revisions. Please note that a significant number of revisions may require additional payment</Text>
              )}
            </Text>
          </View>
          <Text style={styles.pb_10}>Any meetings regarding edit are part of the post production time</Text>
        </View>

        {/* <View style={styles.tableHeaderRow} wrap={false}>
          <Text style={[styles.tableHeader, { width: "37%" }]}>
            Requirements
          </Text>
          <Text style={[styles.tableHeader, { width: "53%" }]}>Details</Text>
          <Text
            style={[styles.tableHeader, styles.textRight, { width: "10%" }]}
          >
            Total Cost
          </Text>
        </View> */}
        {invoiceDetails &&
          invoiceDetails?.invoice &&
          Object.keys(
            invoiceDetails?.invoice?.post_production?.line_items
          )?.map((item, index) => {
            const is_raw_footage_delivery = invoiceDetails?.invoice
              ?.post_production?.line_items[
              item
            ]?.is_raw_footage_delivery;

            return (
              <>
                {PostProductionItem(item, index, invoiceDetails)}
              </>
              // <View key={index + "item"} style={[styles.flexRow,styles.borderBottom,{
              //                 marginBottom: 10, paddingTop:5, paddingBottom:5}]}>
              //   <View style={[{ width: "37%", paddingRight: "5px" }]}>
              //     <Text style={[styles.fontBold, {marginBottom: "2px"}]}>
              //       {invoiceDetails?.project?.name}{" "}
              //       {
              //         invoiceDetails?.invoice?.post_production?.line_items[item]
              //           ?.title
              //       }
              //     </Text>
              //     <Text>
              //       {
              //         invoiceDetails?.invoice?.post_production?.line_items[item]
              //           ?.description
              //       }
              //     </Text>
              //   </View>
              //   <View style={[{ width: "53%"}]}>
              //     <View style={[styles.flexRow, styles.justifyBetween]}>
              //       <View style={{ width: "70%" }}>
              //         {invoiceDetails?.invoice?.post_production?.line_items[item]
              //           ?.type === "video" && (
              //           <Text style={[styles.textCapitalize,{marginBottom:5}]}>Aspect Ratio:</Text>
              //         )}
              //         {invoiceDetails?.invoice?.post_production?.line_items[item]
              //           ?.type === "photo" && (
              //             <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginBottom:5}]}>
              //               <Text style={[styles.textCapitalize]}>
              //                 Number of photos to be edited:
              //               </Text>
              //             </View>
              //         )}
              //         {invoiceDetails?.invoice?.post_production?.line_items[item]
              //           ?.type === "video" && (
              //             <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginBottom:5}]}>
              //               <Text style={[styles.textCapitalize]}>
              //                 Estimated final run time:
              //               </Text>
              //             </View>
              //         )}
              //         <View style={[{marginBottom:5}]}>
              //           <Text style={[styles.textCapitalize]}>
              //             Estimated hours needed for initial delivery
              //           </Text>
              //         </View>
              //         <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginBottom:5}]}>
              //           <Text style={[styles.textCapitalize]}>
              //             Deadline of initial delivery of{" "}
              //             {
              //               invoiceDetails?.invoice?.post_production?.line_items[
              //                 item
              //               ]?.type
              //             }{" "}
              //             Edit
              //           </Text>
              //         </View>
              //       </View>
              //       <View style={[ styles.textRight, {width: "25%"} ]}>
              //         {invoiceDetails?.invoice?.post_production?.line_items[item]
              //           ?.type === "video" && (
              //             <View style={[{marginBottom:5}]}>
              //               <Text style={[styles.textCapitalize]}>
              //                 {
              //                   invoiceDetails?.invoice?.post_production?.line_items[
              //                     item
              //                   ]?.aspect_ratio
              //                 }
              //               </Text>
              //             </View>
              //         )}
              //         {invoiceDetails?.invoice?.post_production?.line_items[item]
              //           ?.type === "photo" && (
              //             <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginLeft:5, marginBottom:5}]}>
              //               <Text style={[styles.textCapitalize]}>
              //                 {
              //                   invoiceDetails?.invoice?.post_production?.line_items[
              //                     item
              //                   ]?.no_of_photos_edit
              //                 }
              //               </Text>
              //             </View>
              //         )}
              //         {invoiceDetails?.invoice?.post_production?.line_items[item]
              //           ?.type === "video" && (
              //             <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginLeft:5, marginBottom:5}]}>
              //               <Text style={[styles.textCapitalize]}>
              //                 {
              //                   invoiceDetails?.invoice?.post_production?.line_items[
              //                     item
              //                   ]?.final_run_time_of_intro
              //                 }{" "}
              //                 Minute
              //               </Text>
              //             </View>
              //         )}
              //         <Text style={[styles.textCapitalize, {marginLeft:5, marginBottom:5}]}>
              //           {
              //             invoiceDetails?.invoice?.post_production?.line_items[
              //               item
              //             ]?.hours_needed_for_initial_delivery
              //           }{" "}
              //           Hour
              //         </Text>
              //         <View style={[styles.solidyellowbg, styles.borderRadius, styles.px_3, {marginLeft:5, marginBottom:5}]}>
              //           <Text>
              //             {FORMAT_SLASH_MM_DD_YYYY(
              //               invoiceDetails?.invoice?.post_production?.line_items[
              //                 item
              //               ]?.deadline_of_initial_delivery
              //             )}
              //           </Text>
              //         </View>
              //       </View>
              //     </View>
              //   </View>
              //   <Text
              //     style={[styles.textRight, { width: "10%" }]}
              //   >
              //     ${is_raw_footage_delivery? 0 : invoiceDetails?.invoice
              //         ?.post_production?.line_items[
              //         item
              //       ]?.total_cost}
              //     {/* {invoiceDetails?.invoice?.post_production?.hourly_charge *
              //       invoiceDetails?.invoice?.post_production?.line_items[item]
              //         ?.hours_needed_for_initial_delivery} */}
              //   </Text>
              // </View>
            );
          })}
      </View>
    )
  );
};

const talentSection = invoiceDetails => {
  return (
    invoiceDetails?.invoice?.talents &&
    invoiceDetails?.invoice?.talents?.days && (
      <View style={styles.container}>
        <Text style={styles.heading}>
          Talents
          {/* <Text style={styles.subHeading}>
            {" "}
            ({invoiceDetails?.invoice?.rentals?.rental_days_needed} Days)
          </Text> */}
        </Text>
        {invoiceDetails?.invoice?.talents.days.map((item, index) => {
          const studios = {
            "Tempe Studio": {
              rent: 69,
              address: "1000 S Park Ln Suite 1, Tempe, AZ 85281"
            },
            "Chandler Studio": {
              rent: 89,
              address: "6100 W Gila Springs Pl 25 Chandler, AZ 85226"
            }
          };

          let studio = studios[item.location];

          return (
            <View style={{ marginBottom: 14 }}>
              <View style={styles.flexRow} wrap={false}>
                <Text
                  style={[styles.fontBold, { fontSize: 14, marginRight: 70 }]}
                >
                  Day {index + 1}
                </Text>
                <View>
                  <Text>Shooting Date</Text>
                  <View style={[styles.borderRadius, styles.px_3, {backgroundColor:"#FFA600"}]}>
                    <Text style={[styles.fontBold]}>
                      {FORMAT_SLASH_MM_DD_YYYY(item.date_of_shoot)}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={[styles.tableHeaderRow, { marginTop: 12 }]}
                wrap={false}
              >
                <Text style={[styles.tableHeader, { width: "28%" }]}>
                  LOCATION
                </Text>
                <Text style={[styles.tableHeader, { width: "11%" }]}>
                  NUMBER OF TALENTS
                </Text>
                <Text style={[styles.tableHeader, { width: "15%" }]}>
                  RENTAL ARRIVAL TIME
                </Text>
                <Text style={[styles.tableHeader, { width: "16%" }]}>
                  RENTAL DEPARTURE TIME
                </Text>
                <Text style={[styles.tableHeader, { width: "18%" }]}>
                  RENTAL HOURS
                </Text>
                <Text
                  style={[
                    styles.tableHeader,
                    styles.textRight,
                    { width: "14%" }
                  ]}
                >
                  TOTAL COST
                </Text>
              </View>
              {item.packages.map((pkg, p_index) => {
                return (
                  <View key={p_index + "pkg"} style={[styles.flexRow,
                                  styles.borderBottom,
                                  { marginBottom: 10, paddingTop:5, paddingBottom:5}]} wrap={false}>
                    <View style={[styles.borderRadius, styles.px_3, { width: "32%",backgroundColor:"#FFA600", marginTop:"3px",height:"auto", marginRight:"2px" }]}>
                      <Text style={styles.fontBold}>{pkg.location}</Text>
                      <Text>{studio?.address}</Text>
                    </View>
                    <View style={[styles.borderRadius, styles.px_3, { width: "11%",backgroundColor:"#FFA600",marginTop:"3px", marginRight:"5px", marginLeft:"5px", paddingBottom:0, height:"auto" }]}>
                      <Text>
                        { "Talent "+(p_index+1) }
                      </Text>
                    </View>
                    <View style={[styles.borderRadius, styles.px_3, { width: "11%",backgroundColor:"#FFA600",marginTop:"3px", marginRight:"5px", marginLeft:"5px", paddingBottom:0, height:"auto" }]}>
                      <Text>
                        {moment(pkg?.talent_arrival_time, "HH:mm:ss").format(
                          "hh:mm A"
                        )}
                      </Text>
                    </View>
                    <View style={[styles.borderRadius, styles.px_3, { width: "11%", backgroundColor:"#FFA600", marginLeft:"34px",marginTop:"3px",paddingBottom:0, height:"auto" }]}>
                      <Text>
                        {moment(pkg?.talent_departure_time, "HH:mm:ss").format("hh:mm A")}
                      </Text>
                    </View>
                    <Text style={{ width: "18%", marginLeft:"35px" }}>
                      {pkg.total_package_hours} hours
                    </Text>
                    <Text
                      style={[styles.textRight, { width: "14%" }]}
                    >
                      ${pkg?.total_package_amount}
                    </Text>
                  </View>
                )
              })}
            </View>
          );
        })}
      </View>
    )
  );
};

const rentalSection = invoiceDetails => {
  return (
    invoiceDetails?.invoice?.rentals &&
    invoiceDetails?.invoice?.rentals?.days && (
      <View style={styles.container}>
        <Text style={styles.heading}>
          Studios
          {/* <Text style={styles.subHeading}>
            {" "}
            ({invoiceDetails?.invoice?.rentals?.rental_days_needed} Days)
          </Text> */}
        </Text>
        {invoiceDetails?.invoice?.rentals.days.map((item, index) => {
          const studios = {
            "Tempe Studio": {
              rent: 69,
              address: "1000 S Park Ln Suite 1, Tempe, AZ 85281"
            },
            "Chandler Studio": {
              rent: 89,
              address: "6100 W Gila Springs Pl 25 Chandler, AZ 85226"
            }
          };

          let studio = studios[item.location];

          return (
            <View style={{ marginBottom: 14 }}>
              <View style={styles.flexRow} wrap={false}>
                <Text
                  style={[styles.fontBold, { fontSize: 14, marginRight: 70 }]}
                >
                  Day {index + 1}
                </Text>
                <View>
                  <Text>Rental Date</Text>
                  <View style={[styles.borderRadius, styles.px_3, {backgroundColor:"#FFA600"}]}>
                    <Text style={[styles.fontBold]}>
                      {FORMAT_SLASH_MM_DD_YYYY(item.date_of_shoot)}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={[styles.tableHeaderRow, { marginTop: 12 }]}
                wrap={false}
              >
                <Text style={[styles.tableHeader, { width: "32%" }]}>
                  STUDIO NAME
                </Text>
                <Text style={[styles.tableHeader, { width: "18%" }]}>
                  RENTAL START TIME
                </Text>
                <Text style={[styles.tableHeader, { width: "18%" }]}>
                  RENTAL END TIME
                </Text>
                <Text style={[styles.tableHeader, { width: "18%" }]}>
                  RENTAL HOURS
                </Text>
                <Text
                  style={[
                    styles.tableHeader,
                    styles.textRight,
                    { width: "14%" }
                  ]}
                >
                  TOTAL COST
                </Text>
              </View>
              <View key={index + "item"} style={[styles.flexRow,
                              styles.borderBottom,
                              { marginBottom: 5, paddingTop:5, paddingBottom:5}]} wrap={false}>
                <View style={[styles.borderRadius, styles.px_3, { width: "32%",backgroundColor:"#FFA600", marginTop:"3px",height:"auto", marginRight:"2px" }]}>
                  <Text style={styles.fontBold}>{item.location}</Text>
                  <Text>{studio?.address}</Text>
                </View>
                <View style={[styles.borderRadius, styles.px_3, { width: "11%",backgroundColor:"#FFA600",marginTop:"3px", marginRight:"5px", marginLeft:"5px", paddingBottom:0, height:"30%" }]}>
                  <Text>
                    {moment(item?.rental_start_time, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                  </Text>
                </View>
                <View style={[styles.borderRadius, styles.px_3, { width: "11%", backgroundColor:"#FFA600", marginLeft:"34px",marginTop:"3px",paddingBottom:0, height:"30%" }]}>
                  <Text>
                    {moment(item?.rental_end_time, "HH:mm:ss").format("hh:mm A")}
                  </Text>
                </View>
                <Text style={{ width: "18%", marginLeft:"35px" }}>
                  {item.total_hours} hours
                </Text>
                <Text
                  style={[styles.textRight, { width: "14%" }]}
                >
                  ${item?.total_amount}
                </Text>
              </View>
              <View style={[styles.flexRow, styles.borderBottom, {paddingBottom: 5}]}>
                <Text style={[styles.fontBold]}>Sales Tax: </Text>
                <Text>{ item?.sales_tax }</Text>
              </View>
            </View>
          );
        })}
      </View>
    )
  );
};

const customSection = invoiceDetails => {
  return (
    invoiceDetails?.invoice?.custom &&
    invoiceDetails?.invoice?.custom?.number_of_items && (
      <View style={styles.container}>
        <Text style={styles.heading}>
          Custom 
        </Text>
        {invoiceDetails?.invoice?.custom.number_of_items.map((item, index) => {
  
          return (
            <View style={{ marginBottom: 14 }}>
              <View style={styles.flexRow} wrap={false}>
                <Text
                  style={[styles.fontBold, { fontSize: 14, marginRight: 70 }]}
                >
                  {item?.item_name ?? `Item ${index + 1}`}
                </Text>
              </View>
              <View
                style={[styles.tableHeaderRow, { marginTop: 12 }]}
                wrap={false}
              >
                <Text style={[styles.tableHeader, { width: "75%" }]}>
                  ITEM DESCRIPTION
                </Text>
                <Text style={[styles.tableHeader, styles.textRight, { width: "25%" }]}>
                  ITEM COST
                </Text>
                {/* <Text style={[styles.tableHeader,styles.textRight, { width: "33%" }]}>
                  TOTAL COST
                </Text> */}
              </View>
              <View key={index + "item"} style={styles.flexRow} wrap={false}>
                  <Text style={[styles.fontBold, { width: "75%" }]}>{item.item_description}</Text>
                  <Text style={[styles.fontBold, styles.textRight, { width: "25%" }]}>${item.price}</Text>
                  {/* <Text style={[styles.fontBold, styles.textRight, { width: "33%" }]}>{item?.total_amount}</Text> */}
              </View>
            </View>
          );
        })}
      </View>
    )
  );
};

const discountSection = invoiceDetails => {
  return invoiceDetails?.invoice_pdf_info?.invoice_discount ? (
    <View
      style={[
        styles.flexRow,
        styles.justifyBetween,
        styles.paddingX,
        styles.paddingY,
        {
          border: "2px solid green",
          width: "370px",
          alignSelf: "flex-end",
          marginRight: 15,
          fontWeight:800,
        }
      ]}
      wrap={false}
    >
      <Text>Discount (New Clients first order) </Text>
      <Text>${invoiceDetails?.invoice_pdf_info?.invoice_discount}</Text>
    </View>
  ) : null;
};

const getSumofAllStage = (invoiceDetails, invoiceCost) => {
  if (invoiceCost) {
    const totalPrice =
      (invoiceDetails?.invoice?.production?.production_sub_total_cost || 0) +
      (invoiceDetails?.invoice?.pre_production?.pre_production_sub_total_cost ||
        0) +
      (invoiceDetails?.invoice?.talents?.talents_sub_total_cost || 0) +
      (invoiceDetails?.invoice?.rentals?.rentals_sub_total_cost || 0) +
      (invoiceDetails?.invoice?.custom?.custom_sub_total_cost || 0) +
      (invoiceDetails?.invoice?.post_production
        ?.post_production_sub_total_cost || 0) -
      (invoiceDetails?.invoice_pdf_info?.invoice_discount || 0);
    return Math.round((totalPrice + Number.EPSILON) * 100) / 100;
  }
  return 0;
};

const grandTotalSection = invoiceDetails => {
  return (
    <View style={styles.container} wrap={false}>
      <View
        style={[
          styles.paddingX,
          styles.paddingY,
          styles.flexRow,
          styles.justifyBetween,
          styles.fontBold,
          {
            backgroundColor: "#0c0",
            borderBottomRightRadius: "15px",
            borderBottomLeftRadius: "15px",
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
          }
        ]}
      >
        <Text>GRAND TOTAL</Text>
        <Text>
          ${getSumofAllStage(invoiceDetails, invoiceDetails?.invoice)}
        </Text>
      </View>
      <View>
        <Text style={{ marginTop: 14 }} wrap={false}>
          Raw footage and/or raw photos are delivered within 48 hours for free.
        </Text>
      </View>
    </View>
  );
};

const paymentScheduleSection = invoiceDetails => {
  return (
    <View style={[styles.container, { backgroundColor: "#f4f4f4" }]}>
      <Text style={styles.heading}>Payment Schedule</Text>
      <View style={styles.tableHeaderRow} wrap={false}>
        <Text style={[styles.tableHeader, { width: "33.33%" }]}>
          {invoiceDetails?.is_quote ? "QUOTE" : "INVOICE"} NO
        </Text>
        <Text style={[styles.tableHeader, { width: "33.33%" }]}>
          PAYMENT DATE
        </Text>
        <Text style={[styles.tableHeader, { width: "33.33%" }]}>AMOUNT</Text>
      </View>
      {invoiceDetails?.payment_schedule &&
        invoiceDetails?.payment_schedule?.map((item, index) => {
          return (
            <View style={styles.flexRow} key={index + "item"} wrap={false}>
              <Text style={[styles.tableCell, { width: "33.33%", paddingBottom:"10px", paddingTop:"5px" }]}>
                {item?.invoice_number ||
                  invoiceDetails?.invoice_pdf_info?.invoice_number}
              </Text>
              <Text style={[styles.tableCell, { width: "33.33%", paddingBottom:"10px",paddingTop:"5px" }]}>
                {FORMAT_SLASH_MM_DD_YYYY(item?.payment_date)}
              </Text>
              <Text style={[styles.tableCell, { width: "33.33%",paddingBottom:"10px",paddingTop:"5px"  }]}>
                ${item?.amount}
              </Text>
            </View>
          );
        })}
      <Text style={{ marginTop: 14 }} wrap={false}>
        We guarantee not to go over the hourly estimates within this {invoiceDetails?.is_quote ? "quote" : "invoice"}{" "}
        based on the information provided so far on{" "}
        <Text style={styles.fontBold}>
          {FORMAT_SLASH_MM_DD_YYYY(invoiceDetails?.project?.updated_at)}
        </Text>
        . The only way additional labor time will be needed is if additional
        requests are provided after{" "}
        <Text style={styles.fontBold}>
          {FORMAT_SLASH_MM_DD_YYYY(invoiceDetails?.project?.updated_at)}
        </Text>{" "}
        such as changes to the project scope, revisions etc.
      </Text>
      <Text style={{ marginTop: 14 }} wrap={false}>
        If a project takes less time for us to complete than on these hourly
        estimates, we will apply credit for future project orders.
      </Text>
    </View>
  );
};

const paymentLinkSection = (invoiceDetails, id) => {
  const checkoutLink =
    invoiceDetails?.payment_link ||
    `${APIMETHOD.CHECKOUT.INVOICE}?invoice_id=${id}`;
  return (
    <View style={styles.container} wrap={false}>
      <Text style={[styles.fontBold, { fontSize: 18, backgroundColor: invoiceDetails?.is_quote ? "#E7A34F" : "" }]}>
          {invoiceDetails?.is_quote ? 
              "Once client has confirmed the official dates, times and locations, a bookable version of this quote will be sent over. This quote currently has tentative dates, times, and locations." : 
              "For Payment Please Click The Button Below"
          }      
      </Text>
      {!invoiceDetails?.is_quote && (
        <View
          style={[
            styles.paddingY,
            styles.fontBold,
            {
              backgroundColor: "#23BAD1",
              borderRadius: 4,
              fontSize: 16,
              marginTop: 14
            }
          ]}
        >
          <Link
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "center"
            }}
            src={checkoutLink}
          >
            BOOK YOUR PROJECT HERE
          </Link>
        </View>
      )}
      <Text style={{ marginTop: 14, marginBottom: 14 }}>
        {invoiceDetails?.is_quote ? "View Terms and Conditions Here:" : "On making payment, agree on"}{" "}
        <Link src="https://www.voyagepro.co/serviceinfo">
          terms and conditions.
        </Link>
      </Text>
    </View>
  );
};

// PDF Invoice Component
const Invoice = ({ invoiceDetails, id }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Info */}
        {infoSection(invoiceDetails)}
        {/* Pre Production */}
        {preProductionSection(invoiceDetails)}
        {/* Production */}
        {productionSectionNew(invoiceDetails)}
        {/* Custom */}
        {customSection(invoiceDetails)}
        {/* Post Production */}
        {postProductionSection(invoiceDetails)}
        {/* Talent */}
        {talentSection(invoiceDetails)}
        {/* Rental */}
        {rentalSection(invoiceDetails)}
        {/* Discount */}
        {discountSection(invoiceDetails)}
        {/* Grand Total */}
        {grandTotalSection(invoiceDetails)}
        {/* Payment Schedule */}
        {paymentScheduleSection(invoiceDetails)}
        {/* Payment Link */}
        {paymentLinkSection(invoiceDetails, id)}
      </Page>
    </Document>
  );
};

export default Invoice;