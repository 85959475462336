import * as React from "react";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material";
import LoadingService from "../../shared/loader";
import { useAxios } from "../../core/useAxios";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { APIMETHOD } from "../../core/constant";
import {
  DataGridPro,
  DataGridProProps,
  GridCallbackDetails,
  GridColumns,
  GridToolbar,
  GridRowId,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  TextField,
  Button,
  Box,
  Switch,
  Checkbox,
  IconButton,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { LineChart } from "@mui/x-charts/LineChart";
import BottomNavigation from "@mui/material/BottomNavigation";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import Archive from "@mui/icons-material/Archive";
import { getUserInfo } from "../../utils/utils";
import moment from "moment";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Pagination } from "@mui/material";
import { toast } from "../../shared/Toser/ToastManager";
import { useAppStateContext } from "../../state/provider";
import { CommitSharp, Style } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import SendReminder from "./SendReminder";
import { fileURLToPath } from "url";
import ManuallyPay from "../Clients/components/ManuallyPay";

type IdObject = { [key: string]: boolean };

let gridData: any[] = [];

const monthMap: { [key: string]: number } = {
  January: 12,
  February: 11,
  March: 10,
  April: 9,
  May: 8,
  June: 7,
  July: 6,
  August: 5,
  September: 4,
  October: 3,
  November: 2,
  December: 1,
};
let potentialres: any[];

const date = new Date();

const Payment = () => {
  const { get, put, loading } = useAxios({ showLoader: true });
  const ref = React.useRef<HTMLDivElement>(null);
  const [value, setValue] = React.useState(0);
  const [allRows, setAllRows] = React.useState<any[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<any[]>([]);
  const [filteredRow, setFilteredRow] = React.useState<any[]>([]);
  const [archivedRow, setArchivedRow] = React.useState<any[]>([]);
  const [manuallyBookedData, setManuallyBookedData] = useState<any[]>([]);
  const [manualPayments, setManualPayments] = useState<any[]>([]);
  const [allMonths, setAllMonths] = React.useState<any[]>([]);
  const [archiveIds, setArchiveIds] = useState<any[]>([]);
  const [unarchiveIds, setUnarchiveIds] = useState<any[]>([]);
  const getDetailPanelHeight = React.useCallback(() => 250, []);
  const userInfo = getUserInfo();
  const [potentialRows, setPotentialRows] = React.useState<any[]>([]);
  const [depositDatesArr, setDepositDatesArr] = React.useState<any[]>([]);
  const [paidPaymentsPerDay, setPaidPaymentsPerDay] = React.useState<any[]>([]);
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const { state, dispatch } = useAppStateContext();
  const [loadingRows, setLoadingRows] = useState<IdObject>({});
  const [searchQueryArchive, setSearchQueryArchive] = React.useState("");
  const [archiveRowData, setArchiveRowData] = useState<any[]>([]);
  const [totalArchiveRows, setTotalArchiveRows] = React.useState(0);
  const [pageArchive, setPageArchive] = React.useState(1);
  const [upcomingPaymentsRows, setUpcomingPaymentsRows] = useState<any[]>([]);
  const [paidPaymentsRows, setPaidPaymentsRows] = useState<any[]>([]);
  const [searchData, setSearchData] = useState("");
  const [isReminderOpen, setisReminderOpen] = useState(false);
  const [selectedPaymentReminder, setselectedPaymentReminder] = useState<any>();
  const [page, setPage] = React.useState(1);
  const [totalBookedRows, setTotalBookedRows] = React.useState(0);
  const [openManuallyPayForm, setOpenManuallyPayForm] = useState(false);
  const [invoiceToPay, setInvoiceToPay] = useState<any>(null);

  const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    "& .super-app-theme--overdue": {
      backgroundColor: "#efc0c2",
    },
    "& .super-app-theme--upcoming": {
      backgroundColor: "#c1fec1",
    },
    "& .super-app-theme--paid": {
      backgroundColor: "#0c0",
    },
  }));

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "",
      width: 5,
      renderCell: (params: any) => {
        return (
          <Checkbox
            onChange={() =>
              setArchiveIds((prev: any) => [...prev, params?.value])
            }
          />
        );
      },
    },
    { field: "customer_name", headerName: "Client Name", width: 200, flex: 1 },
    { field: "project_name", headerName: "Project Name", width: 200, flex: 1 },
    {
      field: "customer_email",
      headerName: "Client Email",
      width: 200,
      flex: 1,
    },
    {
      field: "invoice_sent_at",
      headerName: "Invoice Sent",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const invoicesent = params?.value;
        if (invoicesent) {
          const date = moment.utc(invoicesent); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "total",
      headerName: "Charge Amount",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? " $" + params?.value : "";
      },
    },
    {
      field: "charge_show",
      headerName: "Charge Date",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const dueDateUnix = params?.value;
        if (dueDateUnix) {
          const date = moment.unix(dueDateUnix).utc(); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "charge_search",
      headerName: "Charge Search",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? params?.value : "";
      },
    },
    {
      field: "deposit_show",
      headerName: "Deposit Date",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const dueDateUnix = params?.value;
        if (dueDateUnix) {
          const date = moment.unix(dueDateUnix).utc(); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "deposit_search",
      headerName: "Deposit Search",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? params?.value : "";
      },
    },
    {
      field: "url",
      headerName: "Download Invoice",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <a href={params.value} target="_blank">
              {" "}
              Download Invoice
            </a>
          );
        }
        return "Not Available";
      },
    },
  ];
  const columnsWithoutCheckbox: GridColumns = [
    { field: "customer_name", headerName: "Client Name", width: 200, flex: 1 },
    {
      field: "customer_email",
      headerName: "Client Email",
      width: 200,
      flex: 1,
    },
    // { field: 'invoice_sent', headerName: 'Invoice Sent', width: 200, flex: 1 },
    {
      field: "total",
      headerName: "Charge Amount",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? " $" + params?.value : "";
      },
    },
    {
      field: "charge_show",
      headerName: "Charge Date",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const dueDateUnix = params?.value;
        if (dueDateUnix) {
          const date = moment.unix(dueDateUnix).utc(); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "charge_search",
      headerName: "Charge Search",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? params?.value : "";
      },
    },
    {
      field: "deposit_show",
      headerName: "Deposit Date",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const dueDateUnix = params?.value;
        if (dueDateUnix) {
          const date = moment.unix(dueDateUnix).utc(); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "deposit_search",
      headerName: "Deposit Search",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? params?.value : "";
      },
    },
    {
      field: "url",
      headerName: "Download Invoice",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <a href={params.value} target="_blank">
              {" "}
              Download Invoice
            </a>
          );
        }
        return "Not Available";
      },
    },
  ];
  const overduePaymentsColumns: GridColumns = [
    { field: "customer_name", headerName: "Client Name", width: 200, flex: 1 },
    {
      field: "customer_email",
      headerName: "Client Email",
      width: 200,
      flex: 1,
    },
    // { field: 'invoice_sent', headerName: 'Invoice Sent', width: 200, flex: 1 },
    {
      field: "total",
      headerName: "Charge Amount",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? " $" + params?.value : "";
      },
    },
    {
      field: "charge_show",
      headerName: "Charge Date",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const dueDateUnix = params?.value;
        if (dueDateUnix) {
          const date = moment.unix(dueDateUnix).utc(); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "charge_search",
      headerName: "Charge Search",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? params?.value : "";
      },
    },
    {
      field: "deposit_show",
      headerName: "Deposit Date",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const dueDateUnix = params?.value;
        if (dueDateUnix) {
          const date = moment.unix(dueDateUnix).utc(); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "deposit_search",
      headerName: "Deposit Search",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? params?.value : "";
      },
    },
    {
      field: "url",
      headerName: "Download Invoice",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <a href={params.value} target="_blank">
              {" "}
              Download Invoice
            </a>
          );
        }
        return "Not Available";
      },
    },
    {
      field: "",
      headerName: "Actions",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              setisReminderOpen(true);
              setselectedPaymentReminder(params.row);
            }}
          >
            Send reminder
          </Button>
        );
      },
    },
  ];
  const manuallyBookedInvoiceColumns: GridColumns = [
    { field: "customer_name", headerName: "Client Name", width: 200, flex: 1 },
    {
      field: "customer_email",
      headerName: "Client Email",
      width: 200,
      flex: 1,
    },
    {
      field: "total",
      headerName: "Charge Amount",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? " $" + params?.value : "";
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const dueDateUnix = params?.value;
        if (dueDateUnix) {
          const date = moment(dueDateUnix).utc(); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    {
      field: "",
      headerName: "Actions",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Button
            className="fw-bold"
            variant="contained"
            onClick={() => {
              manuallyPayInvoice(params)
            }}
          >
            Mark Manually Paid
          </Button>
        );
      },
    },
  ];
  const manualPaymentsColumns: GridColumns = [
    { field: "client_name", headerName: "Client Name", width: 200, flex: 1 },
    {
      field: "client_email",
      headerName: "Client Email",
      width: 200,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Charge Amount",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? " $" + params?.value : "";
      },
    },
    {
      field: "date",
      headerName: "Due Date",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        const dueDateUnix = params?.value;
        if (dueDateUnix) {
          const date = moment(dueDateUnix).utc(); // Convert Unix timestamp to milliseconds
          return date.format("MM/DD/YYYY");
        }
        return "";
      },
    },
    // {
    //   field: "",
    //   headerName: "Actions",
    //   width: 200,
    //   flex: 1,
    //   renderCell: (params: any) => {
    //     return (
    //       <Button
    //         className="fw-bold"
    //         variant="contained"
    //         onClick={() => {
    //           manuallyPayInvoice(params)
    //         }}
    //       >
    //         Mark Manually Paid
    //       </Button>
    //     );
    //   },
    // },
  ];
  const archiveColumns: GridColumns = [
    {
      field: "id",
      headerName: "",
      width: 5,
      renderCell: (params: any) => (
        <Checkbox
          onChange={() => setUnarchiveIds((prev) => [...prev, params?.value])}
        />
      ),
    },
    { field: "customer_name", headerName: "Client Name", width: 200, flex: 1 },
    {
      field: "customer_email",
      headerName: "Client Email",
      width: 200,
      flex: 1,
    },
    {
      field: "total",
      headerName: "Charge Amount",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params?.value ? " $" + params?.value : "";
      },
    },
    {
      field: "url",
      headerName: "Download Invoice",
      width: 200,
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <a href={params.value} target="_blank">
              {" "}
              Download Invoice
            </a>
          );
        }
        return "Not Available";
      },
    },
  ];

  let array: any;

  const [cols, setCols] = React.useState<any[]>(columns);
  const [colsWithoutCheck, setColsWithoutCheck] = React.useState<any[]>(
    columnsWithoutCheckbox
  );
  const [overdueColumnsCheck, setOverdueColumnsCheck] = React.useState<any[]>(
    overduePaymentsColumns
  );
  // const [potentialCols, setPotentialCols] = React.useState<any[]>z(columns);

  const filterRows = (rows: any, result2: any) => {
    let rawData: any;
    if (rows) {
      rawData = rows;
    } else {
      rawData = allRows;
    }
    const currentDateTime = moment();
    let tempArray = [];
    let tempArrays: React.SetStateAction<any[]> = [];
    let tempArraysUpcoming: React.SetStateAction<any[]> = [];
    let tempArraysPaid: React.SetStateAction<any[]> = [];
    let colsArray = [];

    if (searchData) {
      rawData = rawData.filter(
        (x: any) =>
          (x.customer_name || "").includes(searchData) ||
          x.customer_email.includes(searchData)
      );
    }
    if (value == 0) {
      colsArray = columns.filter(
        (col: any) => col.field != "deposit_show" && col.field != "charge_show"
      );
      setCols(colsArray);
      potentialres =
        result2 &&
        result2?.results?.sort((a: any, b: any) => {
          if (a.invoice_sent_at == null) {
            return 1;
          }
          if (b.invoice_sent_at == null) {
            return -1;
          }
          if (a.invoice_sent_at === b.invoice_sent_at) {
            return 0;
          }
          return a.invoice_sent_at < b.invoice_sent_at ? 1 : -1;
        });

      tempArray = potentialres
        ? [...potentialres]
        : potentialRows && potentialRows?.length >= 0
        ? [...potentialRows]
        : [];
    } else if (value == 1) {
      colsArray = colsWithoutCheck.filter((col: any) => col.field != "url");
      setColsWithoutCheck(colsArray);
      let depositdatesarray: any[] = [];
      let paidpaymentsarray: any[] = [];
      let daylists: any[] = [];
      let datesarray: any[] = [];

      let initialData = rawData.filter((payment: any) => {
        let flag = false;
        if (payment?.deposit_search && payment?.paid == false) {
          const unixDateTime = moment(payment.deposit_search, "MM-DD-YYYY");
          const isDueDateGreater = unixDateTime.isAfter(currentDateTime);
          flag = isDueDateGreater;
          payment["stage"] = "upcoming";
        }
        return flag;
      });
      initialData.map((fd: any) => {
        let depositdates = new Date(fd.deposit_search)
          .toISOString()
          .slice(0, 10);
        depositdatesarray.push({ depositdates, total: fd.total });
        let todays = new Date();
        let today = new Date().toISOString().slice(0, 10);
        let nextDates = new Date(new Date().setDate(todays.getDate() + 59));
        let startDate = nextDates.toISOString().slice(0, 10);

        //get all dates since startdate
        let getDaysArray = function (s: any, e: any) {
          for (
            var a = [], d = new Date(s);
            d <= new Date(e);
            d.setDate(d.getDate() + 1)
          ) {
            a.push(new Date(d));
          }
          return a;
        };
        let daylist = getDaysArray(new Date(today), new Date(startDate));
        daylists = daylist.map((v) => v.toISOString().slice(0, 10));
      });
      daylists.forEach((ad: any) => {
        let total = 0;
        depositdatesarray.forEach((dd: any) => {
          if (ad == dd.depositdates) {
            total = dd.total;
          }
        });
        paidpaymentsarray.push(total);
      });

      const formattedData = _.chain(initialData.slice(0, 100))
        .map((o) => ({
          ...o,
          deposit_year: new Date(o.deposit_search).getFullYear(),
        }))
        .groupBy("deposit_year")
        .map((items, year) => {
          const mm = _.chain(items)
            .map((oo) => ({
              ...oo,
              deposit_year: year,
              deposit_month: new Date(oo.deposit_search).toLocaleString(
                "default",
                { month: "long" }
              ),
            }))
            .groupBy("deposit_month")
            .map((items, month) => ({ id: month, month, array: items }))
            .sortBy((item: any) => {
              return monthMap[item.month];
            })
            .reverse()
            .value();
          return {
            id: year,
            year,
            monthArray: mm,
          };
        })
        .value();

      daylists.map((r: any) => {
        var date = new Date(r);
        let dayss = date.getDate();
        datesarray.push(dayss);
      });
      //sort days
      formattedData.map((ma: any) => {
        ma.monthArray.map((arr: any) => {
          arr.array.sort((a: any, b: any) =>
            a.deposit_search > b.deposit_search ? 1 : -1
          );
        });
      });
      setDepositDatesArr(datesarray);
      setPaidPaymentsPerDay(paidpaymentsarray);
      tempArraysUpcoming = _.orderBy(formattedData, "year", "asc");
    } else if (value == 2) {
      setColsWithoutCheck(colsWithoutCheck);
      let depositdatesarray: any[] = [];
      let paidpaymentsarray: any[] = [];
      let daylists: any[] = [];
      let datesarray: any[] = [];

      let initialData = rawData.filter((payment: any) => {
        let flag = false;
        if (payment?.paid == true) {
          flag = true;
          payment["stage"] = "paid";
        }
        return flag;
      });

      initialData.map((fd: any) => {
        let depositdates = new Date(fd.deposit_search)
          .toISOString()
          .slice(0, 10);
        depositdatesarray.push({ depositdates, total: fd.total });

        let todays = new Date();
        let today = new Date().toISOString().slice(0, 10);
        let priorDates = new Date(new Date().setDate(todays.getDate() - 59));
        let startDate = priorDates.toISOString().slice(0, 10);

        //get all dates since startdate
        let getDaysArray = function (s: any, e: any) {
          for (
            var a = [], d = new Date(s);
            d <= new Date(e);
            d.setDate(d.getDate() + 1)
          ) {
            a.push(new Date(d));
          }
          return a;
        };
        let daylist = getDaysArray(new Date(startDate), new Date(today));
        daylists = daylist.map((v) => v.toISOString().slice(0, 10));
      });
      daylists.forEach((ad: any) => {
        let total = 0;
        depositdatesarray.forEach((dd: any) => {
          if (ad == dd.depositdates) {
            total = dd.total;
          }
          //1. get all duplicate days in depositdatesarray
          //2. calculate their totals
          //3. push their totals as one day
        });
        paidpaymentsarray.push(total);
      });
      const formattedData = _.chain(initialData.slice(0, 100))
        .map((o) => ({
          ...o,
          deposit_year: new Date(o.deposit_search).getFullYear(),
        }))
        .groupBy("deposit_year")
        .map((items, year) => {
          const mm = _.chain(items)
            .map((oo) => ({
              ...oo,
              deposit_year: year,
              deposit_month: new Date(oo.deposit_search).toLocaleString(
                "default",
                { month: "long" }
              ),
            }))
            .groupBy("deposit_month")
            .map((items, month) => ({ id: month, month, array: items }))
            .sortBy((item: any) => {
              return monthMap[item.month];
            })
            .value();
          return {
            id: year,
            year,
            monthArray: mm,
          };
        })
        .value();
      daylists.map((r: any) => {
        var date = new Date(r);
        let dayss = date.getDate();
        datesarray.push(dayss);
      });
      //sort days
      formattedData.map((ma: any) => {
        ma.monthArray.map((arr: any) => {
          arr.array.sort((a: any, b: any) =>
            b.deposit_search > a.deposit_search ? 1 : -1
          );
        });
      });
      setDepositDatesArr(datesarray);
      setPaidPaymentsPerDay(paidpaymentsarray);
      tempArraysPaid = _.orderBy(formattedData, "year", "desc");
    } else if (value == 3) {
      colsArray = overduePaymentsColumns.filter((col: any) => {
        return col.field != "url"
      });
      setOverdueColumnsCheck(colsArray);
      tempArray = rawData.filter((payment: any) => {
        let flag = false;
        if (payment?.charge_search) {
          const unixDateTime = moment(payment.charge_search, "MM-DD-YYYY");
          const isDueDateGreater = unixDateTime.isAfter(currentDateTime);
          flag = !isDueDateGreater && payment.paid != true;
          payment["stage"] = "overdue";
        }
        return flag;
      });
    }

    console.log("Overdue Payments: ", tempArray)

    setFilteredRows(tempArray);
    setTotalBookedRows(result2?.count);
    setFilteredRow(tempArray);
    setUpcomingPaymentsRows(tempArraysUpcoming);
    setPaidPaymentsRows(tempArraysPaid);
    setAllMonths(array);
  };

  const getPayments = async (page: any) => {
    const result: any = await get({
      url: APIMETHOD.PAYMENTS.GET[userInfo.role],
    });
    const result2: any = await get({
      url: APIMETHOD.INVOICE.PAYMENT_SECTION[userInfo.role] + `page=${page}`,
    });
    // const archiveResults: any = await get({
    //   url:
    //     APIMETHOD.INVOICE.GET_PAGINATED_ARCHIVED_DATA[userInfo.role] +
    //     "?is_archived=True" +
    //     `&page=${pageArchive}`,
    //   // `&searchString=${searchQueryArchive}`,
    // });
    // if (archiveResults) {
    //   console.log(archiveResults);
    //   setTotalArchiveRows(result.count);
    //   setArchiveRowData(archiveResults);
    //   setTotalArchiveRows(archiveResults.count);
    // }
    console.log("Result: ", result);
    console.log("Result 2: ", result2);
    setPotentialRows(potentialres);
    setAllRows(result?.data);
    filterRows(result?.data, result2);
  };

  const fetchManuallyBookedInvoice = async (page=1) => {

    try {
      const result: any = await get({
        url: APIMETHOD.INVOICE.MANUALLY_BOOKED.list,
      });
  
      console.log("Manually Booked Invoice: ", result);
      setManuallyBookedData(result)
    } catch (error) {
      console.error("Manual Boook fetch Error: ", error)
    }
  }

  const fetchManualPayments = async () => {
    const result: any = await get({
      url: APIMETHOD.PAYMENTS.MANUAL_LIST,
    });

    console.log("Manual Payment List: ", result)
    setManualPayments(result)
  }

  const fetchArchivePayments = async () => {
    const archiveResults: any = await get({
      url:
        APIMETHOD.INVOICE.GET_PAGINATED_ARCHIED_DATA[userInfo?.role] +
        "?is_archived=True" +
        `&page=${pageArchive}`,
      // `&searchString=${searchQueryArchive}`,
    });
    console.log("Archive Results: ", archiveResults);
    if (archiveResults) {
      setTotalArchiveRows(archiveResults.length);
      setArchiveRowData(archiveResults);
      // setTotalArchiveRows(archiveResults.count);
    }
  }

  const manuallyPayInvoice = (params:any) => {
    setOpenManuallyPayForm(true)
    setInvoiceToPay(params)
    console.log("Invoice To Pay: ", params);
  }

  const handleRowClick = (params: any) => {
    // console.log("i amin handel row click");
    const rowId = params.id;
    if (expandedRows.includes(rowId)) {
      // If the row is already expanded, collapse it
      setExpandedRows(expandedRows.filter((id: number) => id !== rowId));
    } else {
      // If the row is not expanded, expand it
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  useEffect(() => {
    if (state.config?.rowId && expandedRows && expandedRows.length === 0) {
      setExpandedRows([state.config?.rowId]);
    }
  }, [state.config?.rowId]);

  const handleArchiveStatus = async (ids: any[], is_archived: boolean) => {
    try {
      if (ids.length <= 0) {
        toast.show({
          severity: "error",
          message: "Please select payments first",
        });
        return;
      }

      const endpoint = APIMETHOD.INVOICE.PAYMENT_SECTION[userInfo.role].replace("?", "/");

      const resdata = await put({
        url: endpoint,
        data: { payment_ids: ids, is_archived },
      });
      console.log("Archive Response: ", resdata);

      if (value == 4) {
        await fetchArchivePayments()
      } else {
        await getPayments(page);
      }

      if (is_archived) setArchiveIds([]);
      else setUnarchiveIds([]);

      const action = is_archived ? "archived" : "unarchived";

      toast.show({
        severity: "success",
        message: `Payment ${action} successfully`,
      });
    } catch (errors) {
      toast.show({ severity: "error", message: "Something went wrong" });
    }
  };

  const handlePageArchiveChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPageArchive(newPage);
  };

  React.useEffect(() => {
    console.log("Tab: ", value)
    if (value == 6) {
      fetchManuallyBookedInvoice()
    } else if (value == 5) {
      fetchManualPayments()
    } else if (value == 4) {
      fetchArchivePayments();
    } else {
      getPayments(page);
    }
  }, [value]);

  React.useEffect(() => {
    if (allRows?.length || potentialRows?.length) {
      filterRows(null, null);
    }
  }, [value]);

  React.useEffect(() => {
    filterRows(allRows, potentialRows);
  }, [searchData]);

  const boldText: React.CSSProperties = {
    fontWeight: "bold",
  };
  const darkRows: React.CSSProperties = {
    backgroundColor: "black",
    color: "white",
    width: "100vw",
    fontSize: "1rem",
    position: "relative",
    textAlign: "center",
  };

  const lineChartsParams = {
    series: [
      {
        label: "Payments Per Day",
        data: paidPaymentsPerDay,
        showMark: false,
      },
    ],
    sx: {
      "--ChartsLegend-itemWidth": "200px",
    },
    width: 1000,
    height: 400,
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format;

  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
    await getPayments(newPage);
  };

  let pageSize = 50;
  const startIndex = (page - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredRows.length);

  // Slice the rows to display only the records for the current page
  const rowsToShow = filteredRows.slice(startIndex, endIndex);

  return (
    <>
      <div className="container">
        <ManuallyPay 
          isOpen={openManuallyPayForm}
          invoice={invoiceToPay}
          handleClose={(fullyPaid:boolean) => {
            if (fullyPaid)
              setManuallyBookedData(manuallyBookedData.filter((item) => item.id !== invoiceToPay?.id))

            setInvoiceToPay(null)
            setOpenManuallyPayForm(false)
          }}
        />
        <Box sx={{ my: 2 }}>
          <h5>Payment Section</h5>
        </Box>
        <LoadingService open={loading} />
        <Box sx={{ pb: 7 }} ref={ref}>
          <CssBaseline />
          <Paper
            sx={{ position: "static", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event: any, newValue: any) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction
                label="Potential Booking"
                icon={<BookOnlineIcon />}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
              <BottomNavigationAction
                label="Upcoming Payments"
                icon={<UpcomingIcon />}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
              <BottomNavigationAction
                label="Paid Payments"
                icon={<CheckCircleIcon />}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
              <BottomNavigationAction
                label="Overdue Payments"
                icon={<AssignmentLateIcon />}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
              <BottomNavigationAction
                label="Archive"
                icon={<Archive />}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
              <BottomNavigationAction
                label="Manual Paid Payment"
                icon={<AssignmentLateIcon />}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
              <BottomNavigationAction
                label="Manually Booked Payment"
                icon={<AssignmentLateIcon />}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
            </BottomNavigation>
          </Paper>
          <div className="p-4 bg-white shadow rounded-3">
            <Box
              sx={{ width: "100%", minHeight: window.innerHeight - 190 + "px" }}
            >
              {/* paid payments */}
              {value == 2 ? (
                <TableContainer className="border-0 defaultFontFamily">
                  <Table>
                    {/* <div className='col-sm-4 col-xs-6 justify-content-end'> */}
                    <div className="col-md-12 d-flex justify-content-end">
                      <TextField
                        sx={{ marginBottom: "2px" }}
                        size="small"
                        id="search-grid"
                        variant="standard"
                        placeholder="Search"
                        onChange={(e: any) => setSearchData(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <SearchOutlinedIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </div>
                    <TableBody>
                      {paidPaymentsRows.map((row) => {
                        return (
                          <>
                            {row.monthArray.map((m: any) => (
                              <>
                                <TableRow style={darkRows} key={m.id}>
                                  {row.year == "1970" || row.year == "1969"
                                    ? "Invalid Deposit Date"
                                    : `${m.month + " " + row.year}`}
                                </TableRow>
                                <StyledDataGrid
                                  rows={m.array}
                                  columns={colsWithoutCheck}
                                  rowThreshold={0}
                                  getDetailPanelHeight={getDetailPanelHeight}
                                  rowHeight={30}
                                  autoHeight={true}
                                  autoPageSize={true}
                                  getRowClassName={(params) =>
                                    `super-app-theme--${params.row.stage}`
                                  }
                                  className="border-0 defaultFontFamily"
                                  sx={{
                                    ".MuiDataGrid-columnHeaderTitle": {
                                      fontWeight: "bold !important",
                                    },
                                  }}
                                  initialState={{
                                    columns: {
                                      columnVisibilityModel: {
                                        deposit_search: false,
                                        charge_search: false,
                                      },
                                    },
                                  }}
                                />
                              </>
                            ))}
                          </>
                        );
                      })}
                    </TableBody>
                    {depositDatesArr.length > 0 ||
                    paidPaymentsPerDay.length > 0 ? (
                      <LineChart
                        {...lineChartsParams}
                        xAxis={[{ scaleType: "pow", data: depositDatesArr }]}
                        series={lineChartsParams.series.map((s) => ({
                          ...s,
                          valueFormatter: currencyFormatter,
                        }))}
                        // series={[{
                        //     data: paidPaymentsPerDay
                        // }]}
                      />
                    ) : (
                      <div>No data found for 60 days </div>
                    )}
                  </Table>
                </TableContainer>
              ) : (
                <div>
                  {/* potential payments */}
                  {value == 0 ? (
                    <div className="text-right align-items-center justify-content-end">
                      <Button
                        variant="contained"
                        onClick={() => handleArchiveStatus(archiveIds, true)}
                        className="ms-3"
                      >
                        Move to archive
                      </Button>

                      <DataGridPro
                        className="border-0 defaultFontFamily"
                        sx={{
                          ".MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold !important",
                          },
                        }}
                        page={page}
                        pageSize={pageSize}
                        columns={cols}
                        rows={filteredRows}
                        rowsPerPageOptions={[pageSize]}
                        rowThreshold={0}
                        rowCount={totalBookedRows}
                        getDetailPanelHeight={getDetailPanelHeight}
                        rowHeight={30}
                        autoHeight={true}
                        autoPageSize={true}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                      <Pagination
                        count={Math.ceil(totalBookedRows / pageSize)}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </div>
                  ) : (
                    <div>
                      {/* archived payments */}
                      {value == 4 ? (
                        <div>
                          <Box
                            sx={{
                              width: "100%",
                              minHeight: window.innerHeight - 190 + "px",
                            }}
                          >
                            <div className="text-right align-items-center d-flex justify-content-end">
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleArchiveStatus(unarchiveIds, false)
                                }
                                className="ms-3"
                              >
                                Unarchive
                              </Button>
                            </div>
                            <DataGridPro
                              className="border-0 defaultFontFamily"
                              sx={{
                                ".MuiDataGrid-columnHeaderTitle": {
                                  fontWeight: "bold !important",
                                },
                              }}
                              columns={archiveColumns}
                              rows={archiveRowData}
                              rowThreshold={0}
                              rowHeight={30}
                              autoHeight={true}
                              autoPageSize={true}
                              components={{ Toolbar: GridToolbar }}
                              rowCount={totalArchiveRows}
                              componentsProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                            />
                          </Box>
                        </div>
                      ) : (
                        <div>
                          {/* upcoming payments */}
                          {value == 1 ? (
                            <TableContainer className="border-0 defaultFontFamily">
                              <Table>
                                {/* <div className='col-sm-4 col-xs-6 justify-content-end'> */}
                                <div className="col-md-12 d-flex justify-content-end">
                                  <TextField
                                    sx={{ marginBottom: "2px" }}
                                    size="small"
                                    id="search-grid"
                                    variant="standard"
                                    placeholder="Search"
                                    onChange={(e: any) =>
                                      setSearchData(e.target.value)
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <IconButton>
                                          <SearchOutlinedIcon />
                                        </IconButton>
                                      ),
                                    }}
                                  />
                                </div>
                                <TableBody>
                                  {upcomingPaymentsRows.map((row) => {
                                    return (
                                      <>
                                        {row.monthArray.map((m: any) => (
                                          <>
                                            <TableRow
                                              style={darkRows}
                                              key={m.id}
                                            >
                                              {row.year == "1970" ||
                                              row.year == "1969"
                                                ? "Invalid Deposit Date"
                                                : `${m.month + " " + row.year}`}
                                            </TableRow>
                                            <StyledDataGrid
                                              rows={m.array}
                                              columns={colsWithoutCheck}
                                              rowThreshold={0}
                                              getDetailPanelHeight={
                                                getDetailPanelHeight
                                              }
                                              getRowClassName={(params) =>
                                                `super-app-theme--${params.row.stage}`
                                              }
                                              rowHeight={30}
                                              autoHeight={true}
                                              autoPageSize={true}
                                              className="border-0 defaultFontFamily"
                                              sx={{
                                                ".MuiDataGrid-columnHeaderTitle":
                                                  {
                                                    fontWeight:
                                                      "bold !important",
                                                  },
                                              }}
                                              initialState={{
                                                columns: {
                                                  columnVisibilityModel: {
                                                    deposit_search: false,
                                                    charge_search: false,
                                                  },
                                                },
                                              }}
                                            />
                                          </>
                                        ))}
                                      </>
                                    );
                                  })}
                                </TableBody>
                                {depositDatesArr.length > 0 ||
                                paidPaymentsPerDay.length > 0 ? (
                                  <LineChart
                                    {...lineChartsParams}
                                    xAxis={[
                                      {
                                        scaleType: "pow",
                                        data: depositDatesArr,
                                      },
                                    ]}
                                    series={lineChartsParams.series.map(
                                      (s) => ({
                                        ...s,
                                        valueFormatter: currencyFormatter,
                                      })
                                    )}
                                  />
                                ) : (
                                  <div>No data found for 60 days </div>
                                )}
                              </Table>
                            </TableContainer>
                          ) : (
                            <div>
                              {/* Manually Paid Payments */}
                              {value == 5 ? (
                                <StyledDataGrid
                                  className="border-0 defaultFontFamily"
                                  sx={{
                                    ".MuiDataGrid-columnHeaderTitle": {
                                      fontWeight: "bold !important",
                                      padding: "0 5px"
                                    },
                                  }}
                                  // initialState={{
                                  //   columns: {
                                  //     columnVisibilityModel: {
                                  //       deposit_search: false,
                                  //       charge_search: false,
                                  //     },
                                  //   },
                                  // }}
                                  columns={manualPaymentsColumns}
                                  getRowClassName={(params) =>
                                    `super-app-theme--paid`
                                  }
                                  rows={manualPayments}
                                  rowThreshold={0}
                                  getDetailPanelHeight={getDetailPanelHeight}
                                  rowHeight={50}
                                  onRowClick={handleRowClick} // Handle row click event
                                  autoHeight={true}
                                  autoPageSize={true}
                                  components={{ Toolbar: GridToolbar }}
                                  componentsProps={{
                                    toolbar: {
                                      showQuickFilter: true,
                                    },
                                  }}
                                />
                              ) : (
                                <div>
                                  {/* Manually Booked Invoice */}
                                  {value == 6 ? (
                                    <StyledDataGrid
                                      className="border-0 defaultFontFamily"
                                      sx={{
                                        ".MuiDataGrid-columnHeaderTitle": {
                                          fontWeight: "bold !important",
                                          padding: "0 5px"
                                        },
                                      }}
                                      // initialState={{
                                      //   columns: {
                                      //     columnVisibilityModel: {
                                      //       deposit_search: false,
                                      //       charge_search: false,
                                      //     },
                                      //   },
                                      // }}
                                      columns={manuallyBookedInvoiceColumns}
                                      getRowClassName={(params) =>
                                        `super-app-theme--${params.row.stage}`
                                      }
                                      rows={manuallyBookedData}
                                      rowThreshold={0}
                                      getDetailPanelHeight={getDetailPanelHeight}
                                      rowHeight={50}
                                      onRowClick={handleRowClick} // Handle row click event
                                      autoHeight={true}
                                      autoPageSize={true}
                                      components={{ Toolbar: GridToolbar }}
                                      componentsProps={{
                                        toolbar: {
                                          showQuickFilter: true,
                                        },
                                      }}
                                    />
                                  ) : (
                                    // overdue payments
                                    <StyledDataGrid
                                      className="border-0 defaultFontFamily"
                                      sx={{
                                        ".MuiDataGrid-columnHeaderTitle": {
                                          fontWeight: "bold !important",
                                        },
                                      }}
                                      initialState={{
                                        columns: {
                                          columnVisibilityModel: {
                                            deposit_search: false,
                                            charge_search: false,
                                          },
                                        },
                                      }}
                                      columns={overdueColumnsCheck}
                                      getRowClassName={(params) =>
                                        `super-app-theme--${params.row.stage}`
                                      }
                                      rows={filteredRows}
                                      rowThreshold={0}
                                      getDetailPanelHeight={getDetailPanelHeight}
                                      rowHeight={30}
                                      onRowClick={handleRowClick} // Handle row click event
                                      autoHeight={true}
                                      autoPageSize={true}
                                      components={{ Toolbar: GridToolbar }}
                                      componentsProps={{
                                        toolbar: {
                                          showQuickFilter: true,
                                        },
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Box>
          </div>
        </Box>
      </div>
      {isReminderOpen && (
        <SendReminder
          isOpen={isReminderOpen}
          handleClose={() => setisReminderOpen(false)}
          clientDetails={selectedPaymentReminder}
        />
      )}
    </>
  );
};

export default Payment;
