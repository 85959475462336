import {
    StyleSheet,
  } from "@react-pdf/renderer";


  export const styles = StyleSheet.create({
    page: {
      fontFamily: "Verdana",
      fontSize: 9,
      backgroundColor: "#FFFFFF"
    },
    container: {
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 15,
      paddingBottom: 15
    },
    row: {
      display: "flex",
      flexWrap: "wrap"
    },
    col_12: {
      flex: "0 0 auto",
      width: "100%"
    },
    col_6: {
      flex: "0 0 auto",
      width: "58%"
    },
    flexRow: {
      flexDirection: "row"
    },
    justifyBetween: {
      justifyContent: "space-between"
    },
    justifyEnd: {
      justifyContent: "flex-end"
    },
    alignCenter: {
      alignItems: "center"
    },
    textRight: {
      textAlign: "right"
    },
    fontBold: {
      fontWeight: "bold"
    },
    fontNormal: {
      fontWeight: "normal"
    },
    textCapitalize: {
      textTransform: "capitalize"
    },
    textOrange: {
      color: "#e7a34f"
    },
    lh_5: {
      lineHeight: "5px"
    },
    marginY: {
      marginTop: 10,
      marginBottom: 10
    },
    marginX: {
      marginLeft: 10,
      marginRight: 10
    },
    paddingY: {
      paddingTop: 10,
      paddingBottom: 10
    },
    paddingX: {
      paddingLeft: 10,
      paddingRight: 10
    },
    pb_10: {
      paddingBottom: 10
    },
    px_3: {
      paddingRight: 3,
      paddingLeft: 3
    },
    mb_4: {
      marginBottom: "4px",
    },
    mb_6: {
      marginBottom: "6px",
    },
    borderTop: {
      borderTop: "1px solid #e0e0e0"
    },
    borderBottom: {
      borderBottom: "1px solid #e0e0e0"
    },
    tableHeaderRow: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
      backgroundColor: "#212529",
      justifyContent: 'between'
    },
    tableHeader: {
      color: "white",
      padding: 7,
      fontSize: 8
    },
    tableCell: {
      // padding: 7,
      borderBottom: "1px solid #e0e0e0"
    },
    heading: {
      fontSize: 20,
      fontWeight: "bold",
      color: "#212529",
      marginBottom: 10
    },
    subHeading: {
      fontSize: 18,
      fontWeight: "bold",
      color: "#666666",
      marginBottom: 10
    },
    solidyellowbg:{
      backgroundColor: "#FFA600",
    },
    address: {
      textAlign: 'center',
      justifyContent: 'center',
      width: "200px"
    },
    crewMeal: {
      marginTop: 5
    },
    textCenter: {
      textAlign: "center"
    },
    dFlex: {
      display: "flex",
    },
  
    packageContainer: {
      border: "1px solid #6c757d",
      borderRadius: "5px",
      flex: "0 0 auto",
      width: "100%",
      padding: 0,
      marginBottom: "10px"
    },
    packageInnerTop: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 10
    },
    packageInnerTopItemOne: {
      flex: "0 0 auto",
      width: "75%",
      display: "flex",
    },
    packageInnerTopItemTwo: {
        flex: "0 0 auto",
        width: "25%",
        display: "flex",
        textAlign: "right",
    },
    packageInnerBottom: {
        borderTop: "1px solid #6c757d"
    },
    pkgCol: {
      padding: "5px 7px", 
    },
    highlight: {
        backgroundColor: "#FFA600",
        // color: "#ffffff",
        padding: "0px 3px",
    },
    alignContentMiddle: {
      alignContent: "middle"
    },
    borderRadius: {
        borderRadius: "5px"
    },
    borderRadiusRight: {
        borderRadius: "0px 3px 3px 0px",
    },
    borderRadiusLeft: {
        borderRadius: "3px 0px 0px 3px",
    },
    // borderBottom: {
    //   borderBottom: "1px solid #6C757D"
    // },
  });