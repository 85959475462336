import { Action } from ".."
import { formActionType } from "./actionType"
import { FormState } from "./state"

export const FORM_INITIAL_STATE: FormState = {
    previousProjectName: ""
}

export const formReducer = (state=FORM_INITIAL_STATE, action: Action) => {
    switch(action.type) {
        case formActionType.UPDATE_PREVIOUS_PROJECT_NAME:
            return {
                ...state,
                previousProjectName: action.data.projectName
            }
    }
}