import { FormControl, TextField } from "@mui/material";

interface IBrandingFormFields {
    fonts: string;
}

interface IBrandingForm {
    index: number;
    formikProps: any;
}
export default function BrandingForm(props: IBrandingForm) {
    const { index, formikProps } = props;
    const { values, handleChange, errors, touched } = formikProps;
    return (
        <div className="row my-4 shadow-sm">
            <div className="col-12 py-2 px-3" style={{backgroundColor: "#E0FDFF"}}>
                Fonts and Branding Colors + Branding Details
            </div>
            <div className="row pt-3">
                <div className='col-6'>
                    <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                        <TextField
                            name={`post_production.edit[${index}].video.branding.fonts`}
                            variant="outlined"
                            label={`Fonts`}
                            onChange={handleChange}
                            value={values.post_production.edit[index]?.video?.branding?.fonts ?? ""}
                            margin="dense"
                            size="small"
                            helperText={(errors?.post_production?.edit[index]?.video?.branding?.fonts &&
                                touched?.post_production?.edit[index]?.video?.branding?.fonts) &&
                                errors?.post_production?.edit[index]?.video?.branding?.fonts
                            }
                        />
                    </FormControl>
                </div>

                <div className='col-6'>
                    <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                        <TextField
                            name={`post_production.edit[${index}].video.branding.colors`}
                            variant="outlined"
                            label={`Branding Colors`}
                            onChange={handleChange}
                            value={values.post_production.edit[index]?.video?.branding?.colors ?? ""}
                            margin="dense"
                            size="small"
                            helperText={(errors?.post_production?.edit[index]?.video?.branding?.colors &&
                                touched?.post_production?.edit[index]?.video?.branding?.colors) &&
                                errors?.post_production?.edit[index]?.video?.branding?.colors
                            }
                        />
                    </FormControl>
                </div>

                <div className="col-12">
                    <div className='col-sm-12'>
                        <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                            <TextField
                                name={`post_production.edit[${index}].video.branding.details`}
                                type={'text'}
                                variant="outlined"
                                label={`Branding Details`}
                                onChange={handleChange}
                                value={values.post_production.edit[index]?.video?.branding?.details ?? ""}
                                margin="dense"
                                size="small"
                                multiline
                                rows={2}
                                helperText={(errors?.post_production?.edit[index]?.video?.branding?.detail &&
                                    touched?.post_production?.edit[index]?.video?.branding?.detail) &&
                                    errors?.post_production?.edit[index]?.video?.branding?.detail
                                }
                            />
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    )
}