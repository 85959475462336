import _ from "lodash";
import { FORMAT_SLASH_MM_DD_YYYY } from "../../../utils/dateformat";

interface IPostProductionItems {
    invoiceDetails: any;
}

export default function PostProductionItems(props: IPostProductionItems) {

    const { invoiceDetails } = props;

    return (
        <div>
            {invoiceDetails &&
                invoiceDetails?.invoice &&
                Object.keys(
                  invoiceDetails?.invoice?.post_production
                    ?.line_items
                )?.map((item: any) => {
                    const lineItem = invoiceDetails?.invoice?.post_production?.line_items[item]
                    const is_raw_footage_delivery = lineItem?.is_raw_footage_delivery;
                    const filmingDays = lineItem?.video?.time_estimator?.filming_days;

                    console.log("Post Production key Shot: ", lineItem?.video?.key_shots?.items)

                    return (
                        <div className="col-12 border-radius border border-secondary rounded mb-3" key={`post_production_item_${item}`}>
                            <div className="d-flex">
                                <div className="col-7 border-end border-secondary">
                                    <div className="col border-bottom border-secondary p-3">
                                        <h5 className="fs-13 text-uppercase">Requirments</h5>
                                        <h6 className="fs-13">
                                            {invoiceDetails?.project?.name} {" "}
                                            { lineItem?.title }
                                        </h6>
                                        <p className="fs-13">
                                            {
                                              lineItem?.type == "video"? lineItem?.video?.overall_message : lineItem?.description
                                            }
                                        </p>
                                    </div>

                                    {/* Photo Data */}
                                    {lineItem?.type == "photo" && (
                                        <div className="col-12 p-3">
                                            <div className="row gx-2 gy-4">
                                                <div className="col-6">
                                                    <h6 className="bg-orange px-2 py-1 rounded fs-12">Number of photos to be edited</h6>
                                                    <span className="bg-orange px-2 py-1 rounded">{lineItem?.no_of_photos_edit}</span>
                                                </div>
                                                {/* <div className="col-6">
                                                    <h6 className="bg-orange px-2 py-1 rounded fs-12">Estimated Final Run Time</h6>
                                                    <span className="bg-orange px-2 py-1 rounded">{lineItem?.final_run_time_of_intro} Minuits</span>
                                                </div> */}
                                                <div className="col-6">
                                                    <h6 className="fs-12">Estimated Hours Needed for Initial Delivery</h6>
                                                    <span>{lineItem?.hours_needed_for_initial_delivery} Hours</span>
                                                </div>
                                                <div className="col-6">
                                                    <h6 className="bg-orange px-2 py-1 rounded fs-12">Deadline of initial delivery of photo Edit</h6>
                                                    <span className="bg-orange px-2 py-1 rounded">{FORMAT_SLASH_MM_DD_YYYY (lineItem?.deadline_of_initial_delivery)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Video Data */}
                                    {lineItem?.type == "video" && (
                                        <div className="col">
                                            {/* <h5 className="px-3 pt-3 fs-13 text-uppercase">Details</h5> */}
                                            <div className="col p-3 border-bottom border-secondary">
                                                <h6 className="fs-13">Key Shots to Display on Screen</h6>
                                                <ul>
                                                    {lineItem?.video?.key_shots?.items.map((item:any, index:any) => {
                                                        console.log("Post Production key Shot: ", item, index)
                                                        return (
                                                            <li key={`key_shot_${index}`}>{ item }</li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="col p-3 border-bottom border-secondary">
                                                <div className="row gx-3">
                                                    <div className="col">
                                                        <h6 className="fs-13">Fonts</h6>
                                                        <p>{ lineItem?.video?.branding?.fonts }</p>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="fs-13">Branding Colors</h6>
                                                        <p>{lineItem?.video?.branding?.colors}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="fs-13">Branding Details</h6>
                                                    <p>{lineItem?.video?.branding?.details}</p>
                                                </div>
                                            </div>
                                            <div className="col p-3">
                                                <div className="row">
                                                    {(lineItem?.video?.include_logo == true || lineItem?.video?.include_voiceover == true) && (
                                                        <div className="col-6">
                                                            <h6 className="fs-13">Includes:</h6>
                                                            <ul>
                                                                {lineItem?.video?.include_logo == true && (
                                                                    <li>Logo</li>
                                                                )}
                                                                {lineItem?.video?.include_voiceover == true && (
                                                                    <li>Voiceover</li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    <div className="col-6">
                                                        <h6 className="fs-13">The Length of Final Edit</h6>
                                                        <span>{lineItem?.video?.time_estimator?.final_video_length} Minutes</span>
                                                    </div>

                                                </div>
                                                <div>
                                                    <h6 className="fs-13">Details About Music in Edit</h6>
                                                    <p>{lineItem?.video?.music_details}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                                <div className="col-5">
                                    <div className="container h-100">
                                        <div className="row row-col-1 h-100">
                                            <div className="col-12 align-self-start p-3 text-end">
                                                <h6 className="fs-13 text-uppercase">Total Cost</h6>
                                                <p>${!is_raw_footage_delivery? lineItem?.total_cost : 0}</p>
                                            </div>
                                            {lineItem?.type == "video" && (
                                                <>
                                                    <div className="col-12 border-top border-secondary">
                                                        <div className="p-3">
                                                            <h6 className="fs-13 text-capitalize">Break Down Of Estimated Time</h6>
                                                            <ul>
                                                                {_.isNil (lineItem?.video?.time_estimator?.is_b_roll) && (
                                                                    <>
                                                                        <li>
                                                                            <span className="">Organizing Footage: </span>
                                                                            <span className="text-orange fw-bold">{lineItem?.estimate?.organizing_footage} Hours</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="">Trimming Footage: </span>
                                                                            <span className="text-orange fw-bold">{lineItem?.estimate?.trimming_footage} Hours</span>
                                                                        </li> 
                                                                        <li>
                                                                            <span className="">Arranging Clips into Storyline: </span>
                                                                            <span className="text-orange fw-bold">{lineItem?.estimate?.arranging_clips} Hours</span>
                                                                        </li> 
                                                                        <li>
                                                                            <span className="">Color Grading: </span>
                                                                            <span className="text-orange fw-bold">{lineItem?.estimate?.color_grading} Hours</span>
                                                                        </li> 
                                                                        <li>
                                                                            <span className="">Refining: </span>
                                                                            <span className="text-orange fw-bold">{lineItem?.estimate?.refining} Hours</span>
                                                                        </li> 
                                                                        <li>
                                                                            <span className="">Adding Music to Edit: </span>
                                                                            <span className="text-orange fw-bold">{lineItem?.estimate?.music} Hours</span>
                                                                        </li> 
                                                                        <li>
                                                                            <span className="">Adding Text to Edit: </span>
                                                                            <span className="text-orange fw-bold">{lineItem?.estimate?.text} Hours</span>
                                                                        </li> 
                                                                    </>
                                                                )}

                                                                {!_.isNil (lineItem?.video?.time_estimator?.is_b_roll) && (
                                                                    <li>
                                                                        <span className="">Gathering Asset, Transferring and Delivering Files, Ensuring Requirements are Met: </span>
                                                                        <span className="text-orange fw-bold">{lineItem?.estimate?.base_buffer} Hours</span>
                                                                    </li> 
                                                                )}
                                                                {!_.isNil(lineItem?.estimate?.revision) && (
                                                                    <li>
                                                                        <span className="">Revisions: </span>
                                                                        <span className="text-orange fw-bold">{lineItem?.estimate?.revision} Hours</span>
                                                                    </li> 
                                                                )}
                                                            </ul>
                                                            <p>This time estimate is calculated based on all the details that have been provided from the client about their project so far. We base this estimate on parameters such as the amount of shots we need to capture, the length of the edit, and variables included within the edit such as text and music.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 border-top border-secondary align-self-end">
                                                        <div className="col-12 text-end p-3">
                                                            <h6 className="fs-13 text-uppercase">Estimated Hours</h6>
                                                            <span className="bg-orange px-2 py-1 rounded">{ lineItem?.total_hours } Hours</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

        </div>
    )
}